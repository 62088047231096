import { PlacementWithLogical, Tooltip } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';

export default function TooltipWrapper({
    label,
    ariaLabel,
    placement,
    hasArrow,
    isDisabled,
    fontSize,
    children,
}: {
    label: ReactNode | string | undefined;
    ariaLabel?: string | undefined;
    placement?: PlacementWithLogical | undefined;
    hasArrow?: boolean | undefined;
    isDisabled?: boolean | undefined;
    fontSize?: string | undefined;
    children?: ReactNode | undefined;
}) {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    return (
        <Tooltip
            label={label}
            aria-label={ariaLabel ?? (typeof label === 'string' ? label : undefined)}
            hasArrow={hasArrow}
            placement={placement}
            isDisabled={isDisabled}
            fontSize={fontSize}
            isOpen={isTooltipOpen}
        >
            <div
                onMouseEnter={() => setIsTooltipOpen(!isDisabled)}
                onMouseLeave={() => setIsTooltipOpen(false)}
                onClick={() => {
                    if (!isDisabled) {
                        setIsTooltipOpen(!isTooltipOpen);
                    }
                }}
            >
                {children}
            </div>
        </Tooltip>
    );
}
