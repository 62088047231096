import { Market, MarketKind, Token, TokenMetaData } from '@derivadex/types';
import { BigNumber } from 'bignumber.js';

export const tokenAmountInUnitsToBigNumber = (amount: BigNumber, decimals: number): BigNumber => {
    const decimalsPerToken = new BigNumber(10).pow(decimals);
    return amount.div(decimalsPerToken);
};

export const getBaseTokenSymbol = (symbol: string, kind: MarketKind) => {
    switch (kind) {
        case MarketKind.SingleNamePerpetual:
            return symbol.slice(0, -1);
        case MarketKind.FixedExpiryFuture:
            return symbol.replace(/F[A-Za-z]+$/, '');
        default:
            return symbol;
    }
};

export const getFutureMarketExpiryMonth = (symbol: string) => {
    const monthString = symbol.charAt(symbol.length - 1);
    if (monthString === 'H') {
        return 'March';
    } else if (monthString === 'M') {
        return 'June';
    } else if (monthString === 'U') {
        return 'September';
    } else {
        return 'December';
    }
};

export const tokenAmountInUnits = (amount: BigNumber, decimals: number, toFixedDecimals = 2): string => {
    return tokenAmountInUnitsToBigNumber(amount, decimals).toFixed(toFixedDecimals);
};

export const unitsInTokenAmount = (units: string, decimals: number): BigNumber => {
    const decimalsPerToken = new BigNumber(10).pow(decimals);

    return new BigNumber(units).multipliedBy(decimalsPerToken);
};

export const tokenSymbolToDisplayString = (symbol: string): string => {
    return symbol.toString().toUpperCase();
};

// After old frontend removal, refactor this types to Token and this cast is no longer needed
export const mapTokensMetadataToTokens = (tokens: TokenMetaData[]): Token[] => {
    return tokens as Token[];
};

export const getTokenForSymbol = (symbol: string, tokens: Token[]): Token => {
    const token = tokens.find((it) => it.symbol.toLowerCase() === symbol);
    if (token === undefined) {
        throw new Error(`Could not find token data for symbol ${symbol}`);
    }
    return token;
};

export const getTokenTooltipText = (symbol: string, kind: MarketKind): string => {
    const baseSymbol = getBaseTokenSymbol(symbol, kind);
    switch (kind) {
        case MarketKind.SingleNamePerpetual:
            return `${baseSymbol} Perpetual`;
        case MarketKind.FixedExpiryFuture:
            return `${baseSymbol} Quarterly Future - ${getFutureMarketExpiryMonth(symbol)} Expiry`;
        case MarketKind.IndexFundPerpetual:
            return `${symbol} Index Perpetual`;
    }
};
