import { BigNumber } from '@0x/utils';
import { OrderIntent, OrderSide, PositionSide, UIMarkPrice, UIPosition, UIStrategy } from '@derivadex/types';
import { getFrontendLogger } from '@derivadex/utils';
import { openAskSize, openBidSize, StrategyFormulas } from 'utils/strategy_formulas';

type Node0HealthyStatus = {
    operatorRunningState: string;
};

export const parseNode0HealthyResponse = async (response: Response): Promise<Node0HealthyStatus> => {
    const json: any = await response.json();
    return { operatorRunningState: json.operatorRunningState };
};

export async function fetchFrontendApiStatus(restUrl: string): Promise<boolean> {
    try {
        let response: any = undefined;
        response = await fetch(`${restUrl}/status`);
        const parsedResponse = await parseNode0HealthyResponse(response!);
        if (parsedResponse.operatorRunningState !== 'Master') {
            return false;
        }
        return true;
    } catch (err) {
        getFrontendLogger().logError('System malfunction: Frontend Api Status Endpoint unreachable.');
        return false;
    }
}

export function getOmfAndImf(
    potentialOpenOrder: {
        side: OrderSide;
        size: BigNumber;
        remainingAmount: BigNumber;
        price: BigNumber;
        symbol: string;
    },
    strategy: UIStrategy,
    markPrices: UIMarkPrice[],
    openOrders: OrderIntent[],
    positions: UIPosition[],
) {
    const openOrdersIfPlaced = openOrders.concat(potentialOpenOrder as unknown as OrderIntent);
    if (strategy && positions && markPrices) {
        const strategyFormulas = new StrategyFormulas();
        strategyFormulas.updateContext(strategy.availCollateral, strategy.maxLeverage);
        const positionsIncludingNotOpened = [];
        for (const price of markPrices) {
            const position = positions.find((it) => price.symbol === it.symbol);
            if (position !== undefined) {
                positionsIncludingNotOpened.push(position);
                strategyFormulas.updateLiquidityContext(
                    price.symbol,
                    price.price,
                    openBidSize(openOrdersIfPlaced, price.symbol),
                    openAskSize(openOrdersIfPlaced, price.symbol),
                    position.side,
                    position.balance,
                    position.avgEntryPx,
                );
            } else {
                positionsIncludingNotOpened.push({
                    symbol: price.symbol,
                    balance: new BigNumber(0),
                    side: PositionSide.None,
                    avgEntryPx: new BigNumber(0),
                } as UIPosition);
                strategyFormulas.updateLiquidityContext(
                    price.symbol,
                    price.price,
                    openBidSize(openOrdersIfPlaced, price.symbol),
                    openAskSize(openOrdersIfPlaced, price.symbol),
                    PositionSide.Long,
                    new BigNumber(0),
                    new BigNumber(0),
                );
            }
        }
        const result = strategyFormulas.getOmfAndImfCalc(potentialOpenOrder.symbol, potentialOpenOrder.side);
        getFrontendLogger().log('omf/imf result: ', JSON.stringify(result));
        const checkOmfLessImf = result ? result.omf.lt(result.imf) : false;
        return checkOmfLessImf;
    }
    return false;
}
