import {
    CheckpointEvent,
    CheckpointType,
    DepositEvent,
    TransactionEventType,
    TransactionMessage,
    TxDepositType,
    TxStrategyUpdate,
    TxTraderUpdate,
    TxWithdrawType,
    WithdrawEvent,
} from '@derivadex/types';
import { DerivadexSocket, getErrorMessage, getFrontendLogger } from '@derivadex/utils';
import { eventChannel } from 'redux-saga';
import { waitFor } from 'store/saga';
import { getSocket } from 'store/socket/selectors';
import { getDepositDdxUIState, getDepositUsdcUIState } from 'store/ui/selectors';
import {
    DepositDdxUIState,
    DepositUsdcUIState,
    SET_DEPOSIT_DDX_UI_STATE,
    SET_DEPOSIT_USDC_UI_STATE,
} from 'store/ui/slice';
import { getTwentyOneByteEthAddress } from 'store/web3/selectors';
import {
    CONNECT_WEB3,
    EPOCH_UPDATE,
    TRADE_MINING_EPOCH_UPDATE,
    WITHDRAW_DDX_STATE_UPDATE_TRIGGER,
    WITHDRAW_STATE_UPDATE_TRIGGER,
} from 'store/web3/slice';
import { all, call, fork, putResolve, select, take, takeEvery } from 'typed-redux-saga/macro';
import { action } from 'typesafe-actions';

import {
    SET_LATEST_STRATEGY_DEPOSIT_TX,
    SET_LATEST_STRATEGY_WITHDRAW_TX,
    SET_LATEST_TRADER_DEPOSIT_TX,
    SET_LATEST_TRADER_WITHDRAW_TX,
} from './slice';

export function* handleCheckpointFeedUpdate(payload: CheckpointEvent) {
    try {
        for (const checkpoint of payload.c) {
            if (checkpoint.type === CheckpointType.Epoch) {
                yield* putResolve(
                    EPOCH_UPDATE({ epochId: checkpoint.value, timeValueSinceEpoch: checkpoint.timeValueSinceEpoch }),
                );
            } else if (checkpoint.type === CheckpointType.TradeMining) {
                yield* putResolve(
                    TRADE_MINING_EPOCH_UPDATE({
                        epochId: parseInt(checkpoint.value.epochId),
                        txOrdinal: parseInt(checkpoint.value.txOrdinal),
                    }),
                );
            }
        }
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in checkpoint update', action);
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

export function* handleDepositFeedUpdate(payload: DepositEvent): Generator {
    try {
        for (const deposit of payload.c) {
            if (deposit.type === TxDepositType.DEPOSIT_COLLATERAL) {
                yield* putResolve(SET_LATEST_STRATEGY_DEPOSIT_TX(deposit.value as TxStrategyUpdate));
                const depositUsdcUiState = yield* select(getDepositUsdcUIState);
                if (depositUsdcUiState !== DepositUsdcUIState.NONE) {
                    yield* putResolve(SET_DEPOSIT_USDC_UI_STATE(DepositUsdcUIState.CONFIRMED));
                }
            } else if (deposit.type === TxDepositType.DEPOSIT_DDX) {
                yield* putResolve(SET_LATEST_TRADER_DEPOSIT_TX(deposit.value as TxTraderUpdate));
                const depositDdxUiState = yield* select(getDepositDdxUIState);
                if (depositDdxUiState !== DepositDdxUIState.NONE) {
                    yield* putResolve(SET_DEPOSIT_DDX_UI_STATE(DepositDdxUIState.CONFIRMED));
                }
            }
        }
    } catch (error: any) {
        getFrontendLogger().logError('error on deposit update', action);
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

export function* handleWithdrawFeedUpdate(payload: WithdrawEvent): Generator {
    try {
        for (const withdraw of payload.c) {
            if (withdraw.type === TxWithdrawType.STRATEGY_WITHDRAW) {
                yield* putResolve(SET_LATEST_STRATEGY_WITHDRAW_TX(withdraw.value as TxStrategyUpdate));
                yield* putResolve(WITHDRAW_STATE_UPDATE_TRIGGER());
            } else if (withdraw.type === TxWithdrawType.TRADER_WITHDRAW) {
                yield* putResolve(SET_LATEST_TRADER_WITHDRAW_TX(withdraw.value as TxTraderUpdate));
                yield* putResolve(WITHDRAW_DDX_STATE_UPDATE_TRIGGER());
            }
        }
    } catch (error: any) {
        getFrontendLogger().logError('error on withdraw update', action);
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

export function* handleTransaction(socket: DerivadexSocket): Generator {
    try {
        const channel = yield* call(getTransactionFeedEventChannel, socket);
        while (true) {
            const message = yield* take(channel);
            switch (message.t) {
                case TransactionEventType.CHECKPOINT:
                    yield* call(handleCheckpointFeedUpdate, message);
                    break;
                case TransactionEventType.DEPOSIT:
                    yield* call(handleDepositFeedUpdate, message);
                    break;
                case TransactionEventType.WITHDRAW:
                    yield* call(handleWithdrawFeedUpdate, message);
                    break;
                default:
                    getFrontendLogger().logError(
                        `System malfunction: Unexpected transaction message ${JSON.stringify(message)}`,
                    );
            }
        }
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

export function* subscribeTransactionFeed() {
    try {
        const twentyOneByteEthAddress = yield* call(waitFor<string>, getTwentyOneByteEthAddress);
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        yield* call([socket, socket.sendTransactionSubscriptionMessages], twentyOneByteEthAddress);
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

export function getTransactionFeedEventChannel(socket: DerivadexSocket) {
    return eventChannel<TransactionMessage>((emitter) => {
        socket.onTransactionUpdate((value) => emitter(value));
        return () => {
            socket.close();
        };
    });
}

export function* watchEthAddress() {
    yield* takeEvery(CONNECT_WEB3, subscribeTransactionFeed);
}

/**
 * The transaction saga layer is responsable for handling side effects when the data from the market state is affected
 */
export const transactionSaga = function* root() {
    yield* all([fork(watchEthAddress)]);
};
