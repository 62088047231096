import { EnrollmentStatus, OneClickTradingStorageData, Trader } from '@derivadex/types';
import { ONE_CLICK_TRADING_DATA } from '@derivadex/utils';
import { AppState } from 'store/slices';

import { ProfileState } from './slice';

export function getEnrollmentStatus(state: AppState): EnrollmentStatus {
    return state.profile.enrollmentStatus;
}

export function getOneClickTradingStatus(state: AppState): boolean {
    const serializedOneClickTradingDataMap = window.localStorage.getItem(ONE_CLICK_TRADING_DATA);
    if (serializedOneClickTradingDataMap !== null && state.web3.ethAddress) {
        const oneClickTradingData = new Map<string, OneClickTradingStorageData>(
            Object.entries(JSON.parse(serializedOneClickTradingDataMap)),
        );
        return oneClickTradingData.get(state.web3.ethAddress)?.enabled ?? false;
    }
    return false;
}

export function getOneClickTradingOptOutLocalStorage(state: AppState): boolean {
    return state.profile.disableOneClickTradingPrompt;
}

export function getDisableOrderNotifications(state: AppState): boolean {
    return state.profile.disableOrderNotifications;
}

export function getSkipCancelClosePositionConfirmation(state: AppState): boolean {
    return state.profile.skipCancelClosePositionConfirmation;
}

export function getProfileDetails(state: AppState): ProfileState {
    return state.profile;
}
