import * as Sentry from '@sentry/react';

import { initialRuntimeConfig } from '../config/runtimeConfig';

export function shouldUseExternalAnalytics(): boolean {
    return initialRuntimeConfig.SENTRY_ENABLED === 'true';
}

async function isBrave(): Promise<boolean> {
    return (
        (await (
            navigator as {
                brave?: {
                    isBrave: () => Promise<boolean>;
                };
            }
        ).brave?.isBrave()) || false
    );
}

export function initializeSentry() {
    Sentry.init({
        dsn: 'https://568b0a26a2b84898aa321324547343ac@o469115.ingest.sentry.io/5634977',
        integrations: [Sentry.browserTracingIntegration()],
        ignoreErrors: ['MetaMask - RPC Error: header not found'],
        tracesSampleRate: 0.001,
        environment: initialRuntimeConfig.TAG,
    });

    Sentry.getCurrentScope().setExtra(
        'isBrave',
        isBrave().then((res: boolean) => console.log(res)),
    );
}
