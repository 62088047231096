import { TxStrategyUpdate, TxTraderUpdate } from '@derivadex/types';
import { AppState } from 'store/slices';

export function getLatestStrategyDeposit(state: AppState): TxStrategyUpdate | undefined {
    return state.transaction.latestStrategyDeposit;
}

export function getLatestStrategyWithdraw(state: AppState): TxStrategyUpdate | undefined {
    return state.transaction.latestStrategyWithdraw;
}

export function getLatestTraderDeposit(state: AppState): TxTraderUpdate | undefined {
    return state.transaction.latestTraderDeposit;
}

export function getLatestTraderWithdraw(state: AppState): TxTraderUpdate | undefined {
    return state.transaction.latestTraderWithdraw;
}
