import { SequencingValidation, UIToastStatus } from '@derivadex/types';

export function getRequestFailureToastStatus(sequencingValidation: string, description: string): UIToastStatus {
    if (sequencingValidation === SequencingValidation.OrderNotExecuted) {
        return UIToastStatus.OrderNotExecuted;
    } else if (description === 'OMFLessThanIMF') {
        return UIToastStatus.OMFLessThanIMF;
    } else if (description === 'MaxTakerPriceDeviationBreached') {
        return UIToastStatus.SlippageError;
    }
    return UIToastStatus.Failed;
}

export function getRequestFailureLink(sequencingValidation: string, description: string): string | undefined {
    return 'https://docs.derivadex.io/troubleshooting-and-faq/troubleshooting';
}
