import { BigNumber } from '@0x/utils';
import { getFrontendLogger } from '@derivadex/utils';
import { SortingFn } from '@tanstack/react-table';

//this function is used to sort table columns (as they currently are rendered as strings, not numbers)
export const sortNumberedStrings = (rowA: any, rowB: any, id: any) => {
    if (
        parseFloat(rowA.original[id].props.value.replace(',', '')) >
        parseFloat(rowB.original[id].props.value.replace(',', ''))
    )
        return -1;
    if (
        parseFloat(rowB.original[id].props.value.replace(',', '')) >
        parseFloat(rowA.original[id].props.value.replace(',', ''))
    )
        return 1;
    return 0;
};

export const sortAlphabetically = (rowA: any, rowB: any, id: any) => {
    if (rowA.original[id].props.children > rowB.original[id].props.children) return -1;
    if (rowB.original[id].props.children > rowA.original[id].props.children) return 1;
    return 0;
};

export const sortDates = (rowA: any, rowB: any, id: any) => {
    if (rowA.original[id] > rowB.original[id]) return -1;
    if (rowB.original[id] > rowA.original[id]) return 1;
    return 0;
};

export function sortBigNumber(rowA: any, rowB: any, columnId: string) {
    return rowA.original[columnId].comparedTo(rowB.original[columnId]);
}
