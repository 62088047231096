import { ChainConnectionStatus } from '@derivadex/types';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { getEthAddress, getWeb3Context, isWeb3Connected } from 'store/web3/selectors';
import { CONNECT_WEB3, SET_CHAIN_CONNECTION_STATUS, SET_ETH_ADDRESS } from 'store/web3/slice';
import { useAccount } from 'wagmi';

export default function useEagerConnect(): boolean {
    const { connector: activeConnector, isConnected, address, chain } = useAccount();
    const [tried, setTried] = useState(false);
    const dispatch = useDispatch();
    const context = useSelector(getWeb3Context);
    const expectedChainId = context?.chainId;
    const ethAddress = useSelector(getEthAddress);
    const isAppConnected = useSelector(isWeb3Connected);

    useEffect(() => {
        if (isConnected && address) {
            if (chain) {
                if (chain.id === expectedChainId) {
                    if (isAppConnected && ethAddress === address) {
                        dispatch(SET_CHAIN_CONNECTION_STATUS(ChainConnectionStatus.CORRECT));
                        return;
                    }
                    dispatch(SET_ETH_ADDRESS(address!));
                    dispatch(SET_CHAIN_CONNECTION_STATUS(ChainConnectionStatus.CORRECT));
                    dispatch(CONNECT_WEB3());
                    Sentry.setUser({
                        id: address,
                    });
                    ReactGA.event({
                        action: `connect_${activeConnector?.id ?? 'unknown'}`,
                        category: 'web3',
                        label: `eth_${address}`,
                    });
                    setTried(true);
                } else {
                    dispatch(SET_CHAIN_CONNECTION_STATUS(ChainConnectionStatus.INCORRECT));
                }
            } else {
                dispatch(SET_CHAIN_CONNECTION_STATUS(ChainConnectionStatus.INCORRECT));
            }
        } else {
            dispatch(SET_CHAIN_CONNECTION_STATUS(ChainConnectionStatus.UNSET));
        }
    }, [isConnected, address, chain, expectedChainId]);

    return tried;
}
