import { Button, Code, Text, VStack } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ErrorBoundary({ children }: { children: JSX.Element | JSX.Element[] }): JSX.Element {
    const { t, i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState('');

    useEffect(() => {
        setCurrentLanguage(i18n.language);
    }, [i18n]);

    function FallbackComponent({ error }: { error: Error }): JSX.Element {
        return (
            <VStack role="alert" align="flex-start" p="1rem" w="100%">
                <Text>{t('somethingWentWrong')}</Text>
                <Code colorScheme="brand.red">{error.message}</Code>

                <Button
                    color="white"
                    colorScheme="indigo"
                    onClick={() =>
                        Sentry.showReportDialog({
                            lang: currentLanguage,
                            user: {
                                name: '-',
                                email: '-',
                            },
                            eventId: Sentry.captureException(error),
                        })
                    }
                >
                    {t('reportFeedback')}
                </Button>
            </VStack>
        );
    }
    return <Sentry.ErrorBoundary fallback={FallbackComponent}>{children}</Sentry.ErrorBoundary>;
}
