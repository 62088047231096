import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { getRealizedPnlHistoryData } from 'store/portfolio/selectors';
import { RealizedPnlResponseItem } from 'store/portfolio/utils';

export default function ChartRealizedPnl() {
    const { t } = useTranslation();

    const balanceData: RealizedPnlResponseItem[] | undefined = useSelector(getRealizedPnlHistoryData);

    return (
        <Box m="0.5rem" height="90%" width="90%">
            <ResponsiveContainer>
                <LineChart data={balanceData !== undefined ? balanceData : []}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="timestamp" />
                    <YAxis
                        dataKey="amount"
                        tickFormatter={(value: number) =>
                            `$${Intl.NumberFormat('en', { notation: 'compact' }).format(value)}`
                        }
                    />
                    <Tooltip />
                    <Line type="monotone" dataKey="amount" stroke="#8884d8" strokeWidth={2} />
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
}
