import { Icon } from '@chakra-ui/react';

export default function CryptoIcon({
    currency,
    size = 6,
    ...props
}: {
    currency: string;
    size?: number;
    [x: string]: any;
}) {
    if (currency === 'ETH')
        return (
            <Icon width="1535" height="2500" viewBox="0 0 256 417" boxSize={size} {...props}>
                <path fill="#b8bac5" d="M127.961 0l-2.795 9.5v275.668l2.795 2.79 127.962-75.638z" />
                <path fill="#fff" d="M127.962 0L0 212.32l127.962 75.639V154.158z" />
                <path fill="#b8bac5" d="M127.961 312.187l-1.575 1.92v98.199l1.575 4.6L256 236.587z" />
                <path fill="#fff" d="M127.962 416.905v-104.72L0 236.585z" />
                <path fill="#6e7389" d="M127.961 287.958l127.96-75.637-127.96-58.162z" />
                <path fill="#b8bac5" d="M0 212.32l127.96 75.638v-133.8z" />
            </Icon>
        );
    else if (currency === 'USDT')
        return (
            <Icon viewBox="0 0 32 32" boxSize={size} {...props}>
                <g fill="none" fillRule="evenodd">
                    <circle cx="16" cy="16" r="16" fill="#26A17B" />
                    <path
                        fill="#FFF"
                        d="M17.922 17.383v-.002c-.11.008-.677.042-1.942.042-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658 0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061 1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658 0 .81-2.895 1.485-6.775 1.657m0-3.59v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118 0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116 0-1.043-3.301-1.914-7.694-2.117"
                    />
                </g>
            </Icon>
        );
    else if (currency === 'USDC')
        return (
            <Icon viewBox="0 0 32 32" boxSize={size} {...props}>
                <g fill="none">
                    <circle fill="#3E73C4" cx="16" cy="16" r="16" />
                    <g fill="#FFF">
                        <path d="M20.022 18.124c0-2.124-1.28-2.852-3.84-3.156-1.828-.243-2.193-.728-2.193-1.578 0-.85.61-1.396 1.828-1.396 1.097 0 1.707.364 2.011 1.275a.458.458 0 00.427.303h.975a.416.416 0 00.427-.425v-.06a3.04 3.04 0 00-2.743-2.489V9.142c0-.243-.183-.425-.487-.486h-.915c-.243 0-.426.182-.487.486v1.396c-1.829.242-2.986 1.456-2.986 2.974 0 2.002 1.218 2.791 3.778 3.095 1.707.303 2.255.668 2.255 1.639 0 .97-.853 1.638-2.011 1.638-1.585 0-2.133-.667-2.316-1.578-.06-.242-.244-.364-.427-.364h-1.036a.416.416 0 00-.426.425v.06c.243 1.518 1.219 2.61 3.23 2.914v1.457c0 .242.183.425.487.485h.915c.243 0 .426-.182.487-.485V21.34c1.829-.303 3.047-1.578 3.047-3.217z" />
                        <path d="M12.892 24.497c-4.754-1.7-7.192-6.98-5.424-11.653.914-2.55 2.925-4.491 5.424-5.402.244-.121.365-.303.365-.607v-.85c0-.242-.121-.424-.365-.485-.061 0-.183 0-.244.06a10.895 10.895 0 00-7.13 13.717c1.096 3.4 3.717 6.01 7.13 7.102.244.121.488 0 .548-.243.061-.06.061-.122.061-.243v-.85c0-.182-.182-.424-.365-.546zm6.46-18.936c-.244-.122-.488 0-.548.242-.061.061-.061.122-.061.243v.85c0 .243.182.485.365.607 4.754 1.7 7.192 6.98 5.424 11.653-.914 2.55-2.925 4.491-5.424 5.402-.244.121-.365.303-.365.607v.85c0 .242.121.424.365.485.061 0 .183 0 .244-.06a10.895 10.895 0 007.13-13.717c-1.096-3.46-3.778-6.07-7.13-7.162z" />
                    </g>
                </g>
            </Icon>
        );
    else if (currency === 'DDX')
        return (
            <Icon width="577.2" height="577.2" viewBox="0 0 577.2 577.2" boxSize={size}>
                <circle fill="#F7F7F7" cx="287.86" cy="289.34" r="287.86" />
                <g>
                    <path
                        fill="#2D1680"
                        d="M52.95,280.43c0-25.31,0-50.46,0-75.85c0.9,0,1.68,0,2.45,0c23.5,0,47,0,70.51,0c3.9,0,7.73,0.54,11.54,1.32
		c1.7,0.35,3.47,0.37,5.13,0.81c23.39,6.12,40.6,19.9,51.06,41.77c2.84,5.94,5,12.19,5.82,18.83c0.54,4.31,1.48,8.57,1.32,12.92
		C199.07,280.74,56.83,280.93,52.95,280.43z"
                    />
                    <path
                        fill="#2D1680"
                        d="M363.49,280.49c-49.17,0-98.35,0-147.65,0c0-25.26,0-50.41,0-75.74c0.61-0.06,1.22-0.17,1.83-0.17
		c23.98-0.01,47.96-0.11,71.93,0.05c7.8,0.05,15.42,1.53,22.85,4.15c20.72,7.32,35.58,21,44.68,40.89
		c2.5,5.47,4.49,11.23,5.34,17.25c0.59,4.22,0.84,8.48,1.23,12.73C363.72,279.88,363.59,280.11,363.49,280.49z"
                    />
                    <path
                        fill="#2D1680"
                        d="M53.55,296.67c0.88-0.03,1.75-0.09,2.63-0.09c47.24,0,94.47,0,141.71,0c0.87,0,1.74,0,2.88,0
		c0,1.24,0.1,2.34-0.02,3.42c-0.66,6.22-1.23,12.46-3.16,18.46c-7.24,22.59-21.68,38.72-43.42,48.24c-5.03,2.2-10.29,3.78-15.7,4.65
		c-3.91,0.63-7.89,1.09-11.84,1.1c-23.82,0.1-47.64,0.05-71.46,0.05c-0.71,0-1.41,0-2.36,0c0-1,0-1.78,0-2.57
		c0-23.74,0-47.48,0.01-71.22c0-0.55,0.11-1.1,0.17-1.65C53.18,296.94,53.37,296.8,53.55,296.67z"
                    />
                    <path
                        fill="#2D1680"
                        d="M363.59,297.1c0.11,3.75-0.05,7.47-0.9,11.15c-0.62,2.71-0.83,5.53-1.61,8.19
		c-7.42,25.13-23.25,42.52-47.91,51.71c-6.52,2.43-13.27,3.76-20.19,4.28c-0.32,0.01-0.64,0.03-0.96,0.04
		c-24.95,0.01-49.91,0.03-74.86,0.04c-0.32,0-0.63-0.06-0.95-0.1c-0.13-0.15-0.27-0.31-0.4-0.46c-0.03-0.64-0.08-1.28-0.08-1.91
		c0-23.67,0-47.33,0-71c0-0.71,0-1.42,0-2.26c0.66-0.08,1.19-0.19,1.72-0.19c48.13-0.01,96.26-0.01,144.39,0
		c0.4,0,0.79,0.06,1.19,0.09C363.22,296.81,363.4,296.96,363.59,297.1z"
                    />
                    <path
                        fill="#2E1780"
                        d="M357.68,204.65c18.11,0,35.61,0,53.37,0c37.71,55.71,75.5,111.55,113.34,167.44
		c-2.1,0.57-47.86,0.79-56.15,0.31C431.53,316.71,394.74,260.89,357.68,204.65z"
                    />
                    <path
                        fill="#EF88AA"
                        d="M354.58,372.31c16.13-23.18,31.98-45.94,47.84-68.73c1.68,1.35,44.75,66.01,45.84,68.73
		C417.18,372.31,386.15,372.31,354.58,372.31z"
                    />
                    <path
                        fill="#EF88AA"
                        d="M524.28,205.52c-3.16,4.61-6.29,9.23-9.48,13.82c-5.51,7.93-11.06,15.83-16.59,23.76
		c-6.57,9.43-13.14,18.87-19.71,28.29c-0.31,0.45-0.72,0.83-1.28,1.47c-0.52-0.59-1.03-1.05-1.41-1.6c-4.51-6.58-9-13.19-13.5-19.78
		c-10.27-15.03-20.54-30.05-30.81-45.08c-0.18-0.26-0.27-0.57-0.4-0.86c0.03-0.32,0.06-0.63,0.09-0.94
		c0.72-0.02,1.44-0.05,2.16-0.05c29.57,0,59.13,0,88.7,0c0.72,0,1.44,0.04,2.16,0.06C524.23,204.9,524.26,205.21,524.28,205.52z"
                    />
                    <path
                        fill="#A298C4"
                        d="M292.02,372.47c0.32-0.01,0.64-0.03,0.96-0.04C292.66,372.44,292.34,372.45,292.02,372.47z"
                    />
                    <path
                        fill="#F1A3BD"
                        d="M-89.31,431.92c-0.03,0.31-0.06,0.63-0.09,0.94C-89.75,432.51-90.07,432.16-89.31,431.92z"
                    />
                    <path
                        fill="#F1A3BD"
                        d="M3.78,432.84c-0.03-0.31-0.05-0.61-0.08-0.92C4.45,432.17,4.15,432.5,3.78,432.84z"
                    />
                    <path
                        fill="#392487"
                        d="M53.55,296.67c-0.19,0.13-0.37,0.27-0.56,0.4C53.18,296.94,53.37,296.8,53.55,296.67z"
                    />
                    <path
                        fill="#392487"
                        d="M363.59,297.1c-0.19-0.14-0.37-0.28-0.56-0.43C363.22,296.81,363.4,296.96,363.59,297.1z"
                    />
                    <path
                        fill="#46328F"
                        d="M215.81,371.94c0.13,0.15,0.27,0.31,0.4,0.46C216.08,372.25,215.94,372.1,215.81,371.94z"
                    />
                </g>
            </Icon>
        );
    else if (currency === 'BTC')
        return (
            <Icon viewBox="0 0 32 32" boxSize={size} {...props}>
                <g fill="none" fillRule="evenodd">
                    <circle cx="16" cy="16" r="16" fill="#F7931A" />
                    <path
                        fill="#FFF"
                        d="M23.189 14.02c.314-2.096-1.283-3.223-3.465-3.975l.708-2.84-1.728-.43-.69 2.765c-.454-.114-.92-.22-1.385-.326l.695-2.783L15.596 6l-.708 2.839c-.376-.086-.746-.17-1.104-.26l.002-.009-2.384-.595-.46 1.846s1.283.294 1.256.312c.7.175.826.638.805 1.006l-.806 3.235c.048.012.11.03.18.057l-.183-.045-1.13 4.532c-.086.212-.303.531-.793.41.018.025-1.256-.313-1.256-.313l-.858 1.978 2.25.561c.418.105.828.215 1.231.318l-.715 2.872 1.727.43.708-2.84c.472.127.93.245 1.378.357l-.706 2.828 1.728.43.715-2.866c2.948.558 5.164.333 6.097-2.333.752-2.146-.037-3.385-1.588-4.192 1.13-.26 1.98-1.003 2.207-2.538zm-3.95 5.538c-.533 2.147-4.148.986-5.32.695l.95-3.805c1.172.293 4.929.872 4.37 3.11zm.535-5.569c-.487 1.953-3.495.96-4.47.717l.86-3.45c.975.243 4.118.696 3.61 2.733z"
                    />
                </g>
            </Icon>
        );
    else
        return (
            <Icon width="1535" height="2500" viewBox="0 0 256 417" boxSize={size}>
                <path fill="#b8bac5" d="M127.961 0l-2.795 9.5v275.668l2.795 2.79 127.962-75.638z" />
                <path fill="#fff" d="M127.962 0L0 212.32l127.962 75.639V154.158z" />
                <path fill="#b8bac5" d="M127.961 312.187l-1.575 1.92v98.199l1.575 4.6L256 236.587z" />
                <path fill="#fff" d="M127.962 416.905v-104.72L0 236.585z" />
                <path fill="#6e7389" d="M127.961 287.958l127.96-75.637-127.96-58.162z" />
                <path fill="#b8bac5" d="M0 212.32l127.96 75.638v-133.8z" />
            </Icon>
        );
}
