import { BigNumber } from 'bignumber.js';
import { ethers } from 'ethers';

//TODO(Brennan): Remove unused variables

export const BOOK_BEST_PRICES_EACH_SIDE = 50;
export const QUOTE_CURRENCY_SYMBOL = 'usd';

export const TX_DEFAULTS = {
    gasLimit: 1000000,
};
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const ZERO = new BigNumber(0);

export const ORDER_ZERO_PADDING = '00000000000000';

export const ERC20_DECIMALS = 18;

export const UI_DECIMALS_DISPLAYED_SPREAD_PERCENT = 1;
export const UI_DECIMALS_DISPLAYED_DEFAULT_PRECISION = 2;
export const UI_DECIMALS_DISPLAYED_PRICE_ETH = 7;

export const ONE_MINUTE_MS = 1000 * 60;

export const UNLIMITED_ALLOWANCE = new BigNumber(2).pow(256).minus(1);

export const DEPOSIT_DIALOG_BALANCE_UPDATE_INTERVAL = 10000;
export const FRONTEND_API_STATUS_UPDATE_INTERVAL = 20000;
export const DEFAULT_STRATEGY = 'main';
export const WITHDRAW_DIALOG_UPDATE_INTERVAL = 30000;
// Interval in the miliseconds
export const MARK_PRICE_UPDATE_INTERVAL = 5000;
// Percentage deviation
export const MARK_PRICE_UPDATE_DELTA_DEVIATION = 0.05;

export enum ConnectorNames {
    Injected = 'Injected',
    WalletConnect = 'WalletConnect',
    WalletLink = 'WalletLink',
}

export const chartTimeZones = [
    { zone: 'America/New_York', gmt: '-0400' },
    { zone: 'America/Los_Angeles', gmt: '-0700' },
    { zone: 'America/Chicago', gmt: '-0500' },
    { zone: 'America/Phoenix', gmt: '-0700' },
    { zone: 'America/Toronto', gmt: '-0400' },
    { zone: 'America/Vancouver', gmt: '-0700' },
    { zone: 'America/Argentina/Buenos_Aires', gmt: '-0300' },
    { zone: 'America/Sao_Paulo', gmt: '-0300' },
    { zone: 'America/Bogota', gmt: '-0500' },
    { zone: 'America/Caracas', gmt: '-0400' },
    { zone: 'America/Mexico_City', gmt: '-0500' },
    { zone: 'Europe/Moscow', gmt: '+0300' },
    { zone: 'Europe/Athens', gmt: '+0300' },
    { zone: 'Europe/Berlin', gmt: '+0200' },
    { zone: 'Europe/London', gmt: '+0100' },
    { zone: 'Europe/Madrid', gmt: '+0200' },
    { zone: 'Europe/Paris', gmt: '+0200' },
    { zone: 'Europe/Warsaw', gmt: '+0200' },
    { zone: 'Europe/Istanbul', gmt: '+0300' },
    { zone: 'Europe/Zurich', gmt: '+0200' },
    { zone: 'Australia/Sydney', gmt: '+1000' },
    { zone: 'Australia/Brisbane', gmt: '+1000' },
    { zone: 'Australia/Adelaide', gmt: '+0930' },
    { zone: 'Asia/Almaty', gmt: '+0600' },
    { zone: 'Asia/Ashkhabad', gmt: '+0500' },
    { zone: 'Asia/Tokyo', gmt: '+0900' },
    { zone: 'Asia/Taipei', gmt: '+0800' },
    { zone: 'Asia/Singapore', gmt: '+0800' },
    { zone: 'Asia/Shanghai', gmt: '+0800' },
    { zone: 'Asia/Seoul', gmt: '+0900' },
    { zone: 'Asia/Tehran', gmt: '+0430' },
    { zone: 'Asia/Dubai', gmt: '+0400' },
    { zone: 'Asia/Kolkata', gmt: '+0530' },
    { zone: 'Asia/Hong_Kong', gmt: '+0800' },
    { zone: 'Asia/Bangkok', gmt: '+0700' },
    { zone: 'Pacific/Chatham', gmt: '+0545' },
    { zone: 'Pacific/Honolulu', gmt: '-1000' },
];

export const MetaMaskErrors = {
    userRejectsTxSignature: 'MetaMask Tx Signature: User denied transaction signature.',
    userRejectsMessageSignature: 'MetaMask Message Signature: User denied message signature.',
    nonce: "The tx doesn't have the correct nonce.",
    staleCheckpoint: 'VM Exception while processing transaction: revert Trader: Did not recover state root.',
};

export const TOAST_DISMISSAL_MS = 5000;

export const FRONTEND_MAX_LEVERAGE_PERCENTAGE = 0.99;

export const OPERATOR_DECIMALS = 6;

export const OPERATOR_DECIMAL_MULTIPLIER = new BigNumber(10).exponentiatedBy(OPERATOR_DECIMALS);

export const DEFAULT_ACTIVE_COLLATERAL = 'usdc';

export const DDX_APPLICATION_ID = ethers.utils.formatBytes32String('exchange-operator');

export const KYC_APPLICATION_ID = ethers.utils.formatBytes32String('kyc-operator');
