import { TabList, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { CustomTab, CustomTabPanel } from 'components/Tabs/Tabs';
import TooltipWrapper from 'components/Tooltip/TooltipWrapper';
import { useScreenSize } from 'hooks/useScreenSize';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';

import ChartBalance from './ChartBalance';
import ChartRealizedPnl from './ChartRealizedPnl';

export enum ChartType {
    StrategyBalance,
    RealizedPnl,
}

export default function ChartsTabs() {
    const { t } = useTranslation();
    const isDesktopView = useScreenSize();
    const { isConnected } = useAccount();
    const [tabIndex, setTabIndex] = useState(0);

    return (
        <Tabs
            isLazy
            variant="enclosed"
            isFitted={!isDesktopView}
            h="100%"
            whiteSpace="nowrap"
            sx={{
                '::-webkit-scrollbar': {
                    display: 'none',
                },
                scrollbarWidth: 'none',
            }}
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
            colorScheme="white"
        >
            <TabList mb="0.1rem" bgColor="rgba(255,255,255,0.04)" borderColor="rgba(255,255,255,.06)" color="text.100">
                <TooltipWrapper label={t('chartPnlTooltip')} aria-label={t('chartPnlTooltip')}>
                    <CustomTab
                        className="tab-realized-pnl"
                        selected={tabIndex === ChartType.RealizedPnl}
                        text={'Realized Pnl'}
                    />
                </TooltipWrapper>
            </TabList>

            {!isConnected ? (
                <Text p="0.5rem"> {t('web3Error')}</Text>
            ) : (
                <TabPanels
                    overflowY="auto"
                    h="100%"
                    sx={{
                        '::-webkit-scrollbar': {
                            display: 'none',
                        },
                        scrollbarWidth: 'none',
                    }}
                >
                    <CustomTabPanel height="90%" width="90%">
                        <ChartRealizedPnl />
                    </CustomTabPanel>
                </TabPanels>
            )}
        </Tabs>
    );
}
