import { Box } from '@chakra-ui/react';
import { FeeResponseItem, StatsAPISuccessTimeseriesResponse, StatsAPITimeseriesResponse } from '@derivadex/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFeesHistoryCurrentPage, getFeesHistoryData } from 'store/portfolio/selectors';
import { FETCH_FEES_HISTORY_DATA } from 'store/portfolio/slice';
import { FetchDataState } from 'store/portfolio/utils';
import { getSelectedStrategy } from 'store/strategy/selectors';
import { getEthAddress } from 'store/web3/selectors';

import FeesTableHeader from '../Header/FeesTableHeader';
import FeesTable from './FeesTable';

export default function FeesHistory({ isDesktopView }: { isDesktopView: boolean }) {
    const dispatch = useDispatch();
    const currentStrategy = useSelector(getSelectedStrategy);
    const traderAddress = useSelector(getEthAddress);
    const data: StatsAPISuccessTimeseriesResponse<FeeResponseItem> | undefined = useSelector(getFeesHistoryData);
    const currentPage: number = useSelector(getFeesHistoryCurrentPage);

    useEffect(() => {
        if (!currentStrategy || !traderAddress || data !== undefined) {
            return;
        }
        dispatch(FETCH_FEES_HISTORY_DATA(FetchDataState.NextPage));
    }, [currentStrategy, traderAddress]);

    const nextPage = () => {
        dispatch(FETCH_FEES_HISTORY_DATA(FetchDataState.NextPage));
    };

    const prevPage = () => {
        dispatch(FETCH_FEES_HISTORY_DATA(FetchDataState.PrevPage));
    };

    return (
        <Box p="0.5rem">
            <FeesTableHeader />
            <FeesTable
                isDesktopView={true}
                data={data && data.success ? data.value : []}
                paginationData={{ isEnabled: true, currentPage, nextPage, prevPage }}
            ></FeesTable>
        </Box>
    );
}
