import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { configSlice } from './config/slice';
import { marketSlice } from './market/slice';
import { portfolioSlice } from './portfolio/slice';
import { profileSlice } from './profile/slice';
import { rootSaga, sagaMiddleware } from './saga';
import { socketSlice } from './socket/slice';
import { strategySlice } from './strategy/slice';
import { transactionSlice } from './transactions/slice';
import { uiSlice } from './ui/slice';
import web3 from './web3/slice';

export const rootReducer = combineReducers({
    config: configSlice.reducer,
    web3,
    socket: socketSlice.reducer,
    market: marketSlice.reducer,
    strategy: strategySlice.reducer,
    transaction: transactionSlice.reducer,
    profile: profileSlice.reducer,
    ui: uiSlice.reducer,
    portfolio: portfolioSlice.reducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
});

if (window.Cypress) {
    //@ts-ignore
    window.__store__ = store;
}

sagaMiddleware.run(rootSaga);
