import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react';
import useLocalStorage from 'hooks/useLocalStorage';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getOneClickTradingOptOutLocalStorage } from 'store/profile/selectors';
import { SET_DISABLE_ONE_CLICK_TRADING_PROMPT, UPDATE_ONE_CLICK_TRADING_INTENT } from 'store/profile/slice';
import { PLACE_ORDER_INTENT } from 'store/strategy/slice';
import { getOneClickTradingOptInUIData } from 'store/ui/selectors';
import { OneClickTradingIntentUIState, SET_ONE_CLICK_TRADING_INTENT_UI_STATE } from 'store/ui/slice';

export default function EnableOneClickTradingDialog() {
    const dialogRef = useRef<HTMLButtonElement | null>(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const oneClickTradingOptInUIData = useSelector(getOneClickTradingOptInUIData);
    const disableOneClickTradingPrompt = useSelector(getOneClickTradingOptOutLocalStorage);
    const [_, setDisableOneClickTradingPrompt] = useLocalStorage<{
        disable: string;
    }>('disableOneClickTradingPrompt', {
        disable: 'false',
    });

    function confirmOptIn() {
        dispatch(
            SET_ONE_CLICK_TRADING_INTENT_UI_STATE({
                uiState: OneClickTradingIntentUIState.NONE,
                orderIntent: undefined,
            }),
        );
        dispatch(
            UPDATE_ONE_CLICK_TRADING_INTENT.request({
                enabled: true,
                orderIntent: oneClickTradingOptInUIData.orderIntent,
            }),
        );
    }

    return (
        <>
            <AlertDialog
                motionPreset="slideInBottom"
                isOpen={
                    oneClickTradingOptInUIData &&
                    !disableOneClickTradingPrompt &&
                    oneClickTradingOptInUIData.uiState === OneClickTradingIntentUIState.PENDING_WALLET_CONFIRMATION
                }
                leastDestructiveRef={dialogRef}
                onClose={() => {
                    dispatch(
                        SET_ONE_CLICK_TRADING_INTENT_UI_STATE({
                            uiState: OneClickTradingIntentUIState.NONE,
                            orderIntent: undefined,
                        }),
                    );
                }}
                isCentered
            >
                <AlertDialogOverlay>
                    <AlertDialogContent bgColor="card.100">
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            {t('oneClickTradingOptInLabel')}
                        </AlertDialogHeader>
                        <AlertDialogCloseButton
                            ref={dialogRef}
                            id="enable-1tc-close-button"
                            data-cy="enable-1tc-close-button"
                            onClick={() => {
                                dispatch(
                                    SET_ONE_CLICK_TRADING_INTENT_UI_STATE({
                                        uiState: OneClickTradingIntentUIState.NONE,
                                        orderIntent: undefined,
                                    }),
                                );
                                setDisableOneClickTradingPrompt({ disable: 'true' });
                                dispatch(SET_DISABLE_ONE_CLICK_TRADING_PROMPT(true));
                                if (oneClickTradingOptInUIData.orderIntent) {
                                    dispatch(PLACE_ORDER_INTENT.request(oneClickTradingOptInUIData.orderIntent));
                                }
                            }}
                        />

                        <AlertDialogBody>{t('oneClickTradingTooltip')}</AlertDialogBody>

                        <AlertDialogFooter alignSelf={'center'} w={'100%'}>
                            <Button
                                w={'100%'}
                                colorScheme="brand.green"
                                color="white"
                                onClick={confirmOptIn}
                                id="enable-1tc-confirmation-button"
                                data-cy="enable-1tc-confirmation-button"
                            >
                                {t('signTransactionAndEnable')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}
