import { BigNumber } from '@0x/utils';
import { TxStrategyUpdate, TxTraderUpdate } from '@derivadex/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TransactionState = {
    latestStrategyDeposit: TxStrategyUpdate | undefined;
    latestStrategyWithdraw: TxStrategyUpdate | undefined;
    latestTraderDeposit: TxTraderUpdate | undefined;
    latestTraderWithdraw: TxTraderUpdate | undefined;
};

export const initialTransactionState: TransactionState = {
    latestStrategyDeposit: undefined,
    latestStrategyWithdraw: {
        amount: new BigNumber(-1),
        trader: '',
        strategyIdHash: '',
        txHash: '',
        collateralAddress: '',
        updateType: '',
        epoch: -1,
        blockNumber: new BigNumber(-1),
    },
    latestTraderDeposit: undefined,
    latestTraderWithdraw: {
        amount: new BigNumber(-1),
        trader: '',
        txHash: '',
        updateType: '',
        epoch: -1,
        blockNumber: new BigNumber(-1),
    },
};

export const transactionSlice = createSlice({
    name: 'transaction',
    initialState: initialTransactionState,
    reducers: {
        SET_LATEST_STRATEGY_DEPOSIT_TX: (state, action: PayloadAction<TxStrategyUpdate>) => {
            state.latestStrategyDeposit = action.payload;
        },
        SET_LATEST_STRATEGY_WITHDRAW_TX: (state, action: PayloadAction<TxStrategyUpdate>) => {
            state.latestStrategyWithdraw = action.payload;
        },
        SET_LATEST_TRADER_DEPOSIT_TX: (state, action: PayloadAction<TxTraderUpdate>) => {
            state.latestTraderDeposit = action.payload;
        },
        SET_LATEST_TRADER_WITHDRAW_TX: (state, action: PayloadAction<TxTraderUpdate>) => {
            state.latestTraderWithdraw = action.payload;
        },
    },
});

export default transactionSlice.reducer;

export const {
    SET_LATEST_STRATEGY_DEPOSIT_TX,
    SET_LATEST_STRATEGY_WITHDRAW_TX,
    SET_LATEST_TRADER_DEPOSIT_TX,
    SET_LATEST_TRADER_WITHDRAW_TX,
} = transactionSlice.actions;
