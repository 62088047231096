import { EstimateFundingRate, Fill, Market, MarkPrice, OpenInterest, Ticker } from '@derivadex/types';
import { getFrontendLogger } from '@derivadex/utils';
import { createSelector } from '@reduxjs/toolkit';
import { BigNumber } from 'bignumber.js';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { AppState } from 'store/slices';

function selectedMarket(state: AppState): Market | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    return state.market.markets[state.market.selectedMarket];
}
export const getSelectedMarket = createSelector([selectedMarket], (selectedMarket) => {
    return selectedMarket;
});

export function getSelectedMarketTickSize(state: AppState): BigNumber | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    const market = state.market.markets[state.market.selectedMarket];
    if (market === undefined) return undefined;
    return market.tickSize;
}

export function getRecentFills(state: AppState): Fill[] {
    return state.market.recentFills;
}

export function getMarkets(state: AppState): { [id: string]: Market } {
    return state.market.markets;
}

const selectMarkets = (state: AppState) => state.market.markets;
export const getMarketsAsList = createSelector([selectMarkets], (selectMarkets) => {
    return Object.values(selectMarkets);
});

export function getMarket(state: AppState, symbol: string): Market | undefined {
    return state.market.markets[symbol];
}

const selectBook = (state: AppState) => state.market.book;
export const getOrderBookSnapshot = createSelector([selectBook], (selectBook) => {
    const book = selectBook;
    return book;
});
const selectBookSpread = (state: AppState) => {
    if (state.market.book === undefined) return undefined;
    return { absoluteSpread: state.market.book?.absoluteSpread, percentageSpread: state.market.book?.percentageSpread };
};
export const createSpreadEqualSelector = createSelectorCreator(defaultMemoize, (prev: any, next: any) => {
    if (!prev || !next) return false;
    if (prev.absoluteSpread === next.absoluteSpread && prev.percentageSpread === next.percentageSpread) return true;
    return false;
});
export const getBookSpread = createSpreadEqualSelector([selectBookSpread], (selectBookSpread) => {
    return selectBookSpread;
});
const getIndexParam = (state: AppState, index: number) => index;
export const createPriceEqualSelector = createSelectorCreator(defaultMemoize, (prev: any, next: any) => {
    if (!prev || !next) return false;
    if (prev.price.toString() === next.price.toString() && prev.priceFormatted === next.priceFormatted) return true;
    return false;
});
export const createSizeEqualSelector = createSelectorCreator(defaultMemoize, (prev: any, next: any) => {
    if (!prev || !next) return false;
    if (prev.size.toString() === next.size.toString() && prev.sizeFormatted === next.sizeFormatted) return true;
    return false;
});
export function getBookBidPriceItem(
    state: AppState,
    index: number,
): { price: BigNumber; priceFormatted: string } | undefined {
    if (
        state.market.book === undefined ||
        state.market.book.bidsFormatted === undefined ||
        state.market.book.bids === undefined ||
        state.market.book.bids.length < index + 1
    )
        return undefined;
    return { price: state.market.book.bids[index][0], priceFormatted: state.market.book.bidsFormatted[index][0] };
}
export function getBookBidSizeItem(
    state: AppState,
    index: number,
): { size: BigNumber; sizeFormatted: string } | undefined {
    if (
        state.market.book === undefined ||
        state.market.book.bidsFormatted === undefined ||
        state.market.book.bids === undefined ||
        state.market.book.bids.length < index + 1
    )
        return undefined;
    return { size: state.market.book.bids[index][1], sizeFormatted: state.market.book.bidsFormatted[index][1] };
}
export function getBookAskPriceItem(
    state: AppState,
    index: number,
): { price: BigNumber; priceFormatted: string } | undefined {
    if (
        state.market.book === undefined ||
        state.market.book.asksFormatted === undefined ||
        state.market.book.asks === undefined ||
        state.market.book.asks.length < index + 1
    )
        return undefined;
    return { price: state.market.book.asks[index][0], priceFormatted: state.market.book.asksFormatted[index][0] };
}
export function getBookAskSizeItem(
    state: AppState,
    index: number,
): { size: BigNumber; sizeFormatted: string } | undefined {
    if (
        state.market.book === undefined ||
        state.market.book.asksFormatted === undefined ||
        state.market.book.asks === undefined ||
        state.market.book.asks.length < index + 1
    )
        return undefined;
    return { size: state.market.book.asks[index][1], sizeFormatted: state.market.book.asksFormatted[index][1] };
}
export const createStringEqualSelector = createSelectorCreator(defaultMemoize, (prev: any, next: any) => {
    if (!prev || !next) return false;
    if (prev === next) return true;
    return false;
});
export const getOrderBookAskNotionalByIndex = createStringEqualSelector(
    [(state: AppState) => state.market.book?.culmulativeNotionalAskSize || undefined, getIndexParam],
    (bookCumulativeSide, index) => {
        return bookCumulativeSide && bookCumulativeSide[index] && bookCumulativeSide[index].toFormat(0);
    },
);
export const getOrderBookBidNotionalByIndex = createStringEqualSelector(
    [(state: AppState) => state.market.book?.culmulativeNotionalBidSize || undefined, getIndexParam],
    (bookCumulativeSide, index) => {
        return bookCumulativeSide && bookCumulativeSide[index] && bookCumulativeSide[index].toFormat(0);
    },
);
const getOrderSizeDecimals = (state: AppState, index: number, orderSizeDecimals: number) => orderSizeDecimals;
export const getOrderBookAskSumByIndex = createStringEqualSelector(
    [(state: AppState) => state.market.book?.asksSum || undefined, getIndexParam, getOrderSizeDecimals],
    (bookSumSide, index, orderSizeDecimals) => {
        return bookSumSide && bookSumSide[index] && bookSumSide[index]?.toFixed(orderSizeDecimals);
    },
);
export const getOrderBookBidSumByIndex = createStringEqualSelector(
    [(state: AppState) => state.market.book?.bidsSum || undefined, getIndexParam, getOrderSizeDecimals],
    (bookSumSide, index, orderSizeDecimals) => {
        return bookSumSide && bookSumSide[index] && bookSumSide[index]?.toFixed(orderSizeDecimals);
    },
);

const selectBookBidsTotal = (state: AppState) => state.market.book?.bidsSum[state.market.book?.bidsSum.length - 1];
export const getOrderBookBidsTotal = createStringEqualSelector([selectBookBidsTotal], (selectBookBidsSum) => {
    const bidsSum = selectBookBidsSum?.toString();
    return bidsSum;
});
const selectBookAsksTotal = (state: AppState) => state.market.book?.asksSum[state.market.book?.asksSum.length - 1];
export const getOrderBookAsksTotal = createStringEqualSelector([selectBookAsksTotal], (selectBookAsksSum) => {
    const asksSum = selectBookAsksSum?.toString();
    return asksSum;
});

export function getTickerForMarket(state: AppState, symbol: string): Ticker | undefined {
    return state.market.tickers[symbol];
}

export function getTickers(state: AppState) {
    return state.market.tickers;
}

export function getOpenInterest(state: AppState, symbol: string): OpenInterest | undefined {
    return state.market.openInterestData[symbol];
}

export function getOpenInterests(state: AppState) {
    return state.market.openInterestData;
}

export function getSelectedMarketPrice(state: AppState, symbol: string): MarkPrice | undefined {
    return state.market.markPrices[symbol];
}

export function getMarkPrices(state: AppState) {
    return state.market.markPrices;
}

const selectMarkPrices = (state: AppState) => state.market.markPrices;
export const getMarkPricesAsList = createSelector([selectMarkPrices], (selectMarkPrices) => {
    return Object.values(selectMarkPrices);
});

export function getFundingRateForMarket(state: AppState, symbol: string): EstimateFundingRate | undefined {
    return state.market.fundingRates[symbol];
}

export function getSelectedMarketMarkPrice(state: AppState): MarkPrice | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    return state.market.markPrices[state.market.selectedMarket];
}

export function getSelectedMarketFundingRate(state: AppState): EstimateFundingRate | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    return state.market.fundingRates[state.market.selectedMarket];
}

export function getFundingRates(state: AppState): { [symbol: string]: EstimateFundingRate } {
    return state.market.fundingRates;
}

export function getPreviousClose(state: AppState): BigNumber | undefined {
    if (state.market.selectedMarket === undefined) {
        return undefined;
    }
    return state.market.previousClose[state.market.selectedMarket];
}

export function getSelectedMarketTicker(state: AppState): Ticker | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    return state.market.tickers[state.market.selectedMarket];
}

export function getSelectedMarketTickerClose(state: AppState): BigNumber | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    if (state.market.tickers[state.market.selectedMarket] === undefined) return undefined;
    return state.market.tickers[state.market.selectedMarket].close;
}

export function getSelectedMarketTickerOpen(state: AppState): BigNumber | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    if (state.market.tickers[state.market.selectedMarket] === undefined) return undefined;
    return state.market.tickers[state.market.selectedMarket].open;
}

export function getSelectedMarketTickerVolume(state: AppState): BigNumber | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    if (state.market.tickers[state.market.selectedMarket] === undefined) return undefined;
    return state.market.tickers[state.market.selectedMarket].volume;
}

export function getSelectedMarketTickerLow(state: AppState): BigNumber | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    if (state.market.tickers[state.market.selectedMarket] === undefined) return undefined;
    return state.market.tickers[state.market.selectedMarket].low;
}

export function getSelectedMarketTickerHigh(state: AppState): BigNumber | undefined {
    if (state.market.selectedMarket === undefined) return undefined;
    if (state.market.tickers[state.market.selectedMarket] === undefined) return undefined;
    return state.market.tickers[state.market.selectedMarket].high;
}

const selectPrices = (state: AppState) => state.market.markPrices;
export const getPricesAsList = createSelector([selectPrices], (selectPrices) => {
    return Object.values(selectPrices);
});

export function getPrices(state: AppState): { [symbol: string]: MarkPrice } {
    return state.market.markPrices;
}
