import { Button, ButtonGroup, useTheme } from '@chakra-ui/react';
import { useScreenSize } from 'hooks/useScreenSize';
import { useEffect, useState } from 'react';

export default function ButtonToggleGroup({
    buttons,
    onChange,
    size = 'xs',
}: {
    buttons: string[];
    onChange: (selected: string) => void;
    size?: string | string[];
}) {
    const [selected, setSelected] = useState<string>(buttons[0]);

    useEffect(() => {
        onChange(selected);
    }, [selected]);

    return (
        <ButtonGroup size={'xs'} spacing="0.2rem">
            {buttons.map((button) => (
                <Button
                    id={button + '-button'}
                    key={button + '-button'}
                    colorScheme="rhino"
                    color={selected === button ? 'white' : 'text.100'}
                    backgroundColor={selected !== button ? 'card.100' : 'gray.700'}
                    _hover={{
                        bgColor: 'gray.700',
                        color: 'white',
                    }}
                    _focus={{
                        outline: 'none',
                    }}
                    border="none"
                    onClick={() => setSelected(button)}
                >
                    {button}
                </Button>
            ))}
        </ButtonGroup>
    );
}
