import { BigNumber } from '@0x/utils';
import {
    ActionType,
    CancelOrderIntent,
    OrderIntent,
    PaymentsHistory,
    PaymentsHistoryRequest,
    RequestFailure,
    RequestSequenced,
    RequestTransacted,
    UIPosition,
    UIStrategy,
    UITrade,
    UserTradesRequest,
    WithdrawIntent,
} from '@derivadex/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAction, createAsyncAction } from 'typesafe-actions';

export type StrategyState = {
    openOrders: OrderIntent[];
    activeStrategy: string | undefined;
    strategies: { [key: string]: UIStrategy };
    positions: { [symbol: string]: UIPosition };
    tradesHistory: UITrade[];
    isDirtyFlagTradesHistory: boolean;
    paymentsHistory: PaymentsHistory[];
    isDirtyFlagPaymentsHistory: boolean;
    isDirtyFlagStrategyCalculations: boolean;
};

export const initialStrategyState: StrategyState = {
    openOrders: [],
    activeStrategy: undefined,
    strategies: {},
    positions: {},
    tradesHistory: [],
    isDirtyFlagTradesHistory: true,
    paymentsHistory: [],
    isDirtyFlagPaymentsHistory: true,
    isDirtyFlagStrategyCalculations: true,
};

export const strategySlice = createSlice({
    name: 'strategy',
    initialState: initialStrategyState,
    reducers: {
        SET_OPEN_ORDERS: (state, action: PayloadAction<OrderIntent[]>) => {
            state.openOrders = action.payload;
        },
        SET_ACTIVE_STRATEGY: (
            state,
            action: PayloadAction<{ strategyId: string; trader: string; isInitialLoad: boolean }>,
        ) => {
            if (!action.payload.isInitialLoad) {
                state.activeStrategy = action.payload.strategyId;
                window.localStorage.setItem('activeStrategy', `${action.payload.trader}-${action.payload.strategyId}`);
            } else {
                const cached = window.localStorage.getItem('activeStrategy');
                const split = cached?.split('-');
                const cachedTrader = split && split.length === 2 ? split[0] : undefined;
                const cachedActiveStrategy = split && split.length === 2 ? split[1] : undefined;
                if (action.payload.trader === cachedTrader) {
                    state.activeStrategy =
                        cachedActiveStrategy !== undefined && state.strategies[cachedActiveStrategy] !== undefined
                            ? cachedActiveStrategy
                            : action.payload.strategyId;
                } else {
                    state.activeStrategy = action.payload.strategyId;
                }
            }
            state.isDirtyFlagTradesHistory = true;
            state.isDirtyFlagPaymentsHistory = true;
        },
        UPDATE_STRATEGIES: (state, action: PayloadAction<UIStrategy[]>) => {
            state.strategies = action.payload.reduce((prev, curr) => ({ ...prev, [curr.strategy]: curr }), {
                ...state.strategies,
            } as { [key: string]: UIStrategy });
            state.isDirtyFlagStrategyCalculations = false;
        },
        SET_POSITIONS: (state, action: PayloadAction<UIPosition[]>) => {
            state.positions = action.payload.reduce(
                (prev, curr) => ({ ...prev, [curr.symbol]: curr }),
                {} as { [symbol: string]: UIPosition },
            );
        },
        UPDATE_POSITIONS: (state, action: PayloadAction<UIPosition[]>) => {
            // Get the symbols of positions with 0 balance
            const positionsToClose = action.payload
                .filter((position) => position.balance.isZero())
                .map((it) => it.symbol);
            // Merge incoming positions with current state
            const mergedPositionStates = action.payload.reduce((prev, curr) => ({ ...prev, [curr.symbol]: curr }), {
                ...state.positions,
            } as { [symbol: string]: UIPosition });
            // Destructuring the object to remove empty positions
            let finalPositionsState = mergedPositionStates;
            for (const key of positionsToClose) {
                const { [key]: removedProperty, ...newPositionsState } = finalPositionsState;
                finalPositionsState = newPositionsState;
            }
            state.positions = finalPositionsState;
        },
        SET_PAYMENTS_HISTORY: (state, action: PayloadAction<PaymentsHistory[]>) => {
            state.paymentsHistory = action.payload;
            state.isDirtyFlagPaymentsHistory = false;
        },
        ACTIVATE_PAYMENTS_HISTORY_DIRTY_FLAG: (state) => {
            state.isDirtyFlagPaymentsHistory = true;
        },
        SET_TRADES_HISTORY: (state, action: PayloadAction<UITrade[]>) => {
            state.tradesHistory = action.payload;
            state.isDirtyFlagTradesHistory = false;
        },
        ACTIVATE_TRADES_HISTORY_DIRTY_FLAG: (state) => {
            state.isDirtyFlagTradesHistory = true;
        },
        ACTIVATE_STRATEGY_CALCULATIONS_DIRTY_FLAG: (state) => {
            state.isDirtyFlagStrategyCalculations = true;
        },
        CREATE_STRATEGY: (state, action: PayloadAction<{ traderAddress: string; strategyId: string }>) => {
            state.strategies = {
                ...state.strategies,
                [action.payload.strategyId]: {
                    trader: action.payload.traderAddress,
                    strategy: action.payload.strategyId,
                    availCollateral: new BigNumber(0),
                    lockedCollateral: new BigNumber(0),
                    frozen: false,
                    maxLeverage: 3,
                    isNew: true,
                    mf: new BigNumber(0),
                    mmr: new BigNumber(0),
                    leverage: new BigNumber(0),
                    frontendMargin: new BigNumber(0),
                    strategyValue: new BigNumber(0),
                },
            };
        },
        RESET_ACTIVE_STRATEGY: (state, action: PayloadAction<void>) => {
            state.activeStrategy = undefined;
        },
    },
});

export const {
    SET_OPEN_ORDERS,
    SET_ACTIVE_STRATEGY,
    UPDATE_STRATEGIES,
    SET_POSITIONS,
    UPDATE_POSITIONS,
    SET_PAYMENTS_HISTORY,
    SET_TRADES_HISTORY,
    ACTIVATE_TRADES_HISTORY_DIRTY_FLAG,
    ACTIVATE_PAYMENTS_HISTORY_DIRTY_FLAG,
    ACTIVATE_STRATEGY_CALCULATIONS_DIRTY_FLAG,
    CREATE_STRATEGY,
    RESET_ACTIVE_STRATEGY,
} = strategySlice.actions;

export const PLACE_ORDER_INTENT = createAsyncAction(
    ActionType.SUBMIT_ORDER_REQUEST,
    ActionType.SUBMIT_ORDER_SUCCESS,
    ActionType.SUBMIT_ORDER_FAILURE,
)<OrderIntent, RequestTransacted, RequestFailure>();

export const CLOSE_POSITION = createAsyncAction(
    ActionType.CLOSE_POSITION_REQUEST,
    ActionType.CLOSE_POSITION_SUCCESS,
    ActionType.CLOSE_POSITION_FAILURE,
)<UIPosition, RequestTransacted, RequestFailure>();

export const CANCEL_ORDER_INTENT = createAsyncAction(
    ActionType.CANCEL_ORDER_REQUEST,
    ActionType.CANCEL_ORDER_SUCCESS,
    ActionType.CANCEL_ORDER_FAILURE,
)<Omit<CancelOrderIntent, 'sessionKeySignature'>, RequestTransacted, RequestFailure>();

export const WITHDRAW_STRATEGY_INTENT = createAsyncAction(
    ActionType.WITHDRAW_COLLATERAL_REQUEST,
    ActionType.WITHDRAW_COLLATERAL_SUCCESS,
    ActionType.WITHDRAW_COLLATERAL_FAILURE,
)<WithdrawIntent, RequestSequenced, RequestFailure>();

export const TRADES_HISTORY_REQUEST = createAction(ActionType.TRADES_HISTORY_REQUEST)<UserTradesRequest>();

export const PAYMENTS_HISTORY_REQUEST = createAction(ActionType.PAYMENTS_HISTORY_REQUEST)<PaymentsHistoryRequest>();
