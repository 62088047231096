import {
    ChainConnectionStatus,
    CheckpointedStrategy,
    CheckpointedTrader,
    Context,
    GuardedDepositInfo,
    TokenBalance,
    WithdrawDDXState,
    WithdrawState,
} from '@derivadex/types';
import { addDiscriminant } from '@derivadex/utils';
import { AppState } from 'store/slices';

export function getWeb3Context(state: AppState): Context | undefined {
    const context = state.web3.context;
    return context;
}

export function getEthAddress(state: AppState): string | undefined {
    const ethAddress = state.web3.ethAddress;
    return ethAddress;
}

export function isEthAddressConnected(state: AppState): boolean {
    const ethAddress = state.web3.ethAddress;
    return ethAddress !== undefined;
}

export function getTwentyOneByteEthAddress(state: AppState): string | undefined {
    const ethAddress = state.web3.ethAddress;
    if (ethAddress === undefined) {
        return undefined;
    }
    const twentyOneEthAddress = addDiscriminant(ethAddress);
    return twentyOneEthAddress;
}

export function isWeb3Connected(state: AppState): boolean {
    return state.web3.isConnected;
}

export function getUSDCCollateral(state: AppState): TokenBalance | undefined {
    const token = state.web3.usdcCollateral;
    return token;
}

export function getDDXCollateral(state: AppState): TokenBalance | undefined {
    const token = state.web3.ddxCollateral;
    return token;
}

export function getLatestContractCheckpoint(state: AppState): number | undefined {
    const checkpoint = state.web3.latestContractCheckpoint;
    return checkpoint;
}

export function getEpoch(state: AppState): number | undefined {
    return state.web3.epoch;
}

export function getTimeValueSinceEpoch(state: AppState): number | undefined {
    return state.web3.timeValueSinceEpoch;
}

export function getIsFundingCountdownLocked(state: AppState): boolean {
    return state.web3.isFundingCountdownLocked;
}

export function getTradeMining(state: AppState): { epochId: number; txOrdinal: number } | undefined {
    return state.web3.tradeMining;
}

export function getNetworkStatus(state: AppState): ChainConnectionStatus {
    return state.web3.networkStatus;
}

export function getGuardedDepositInfo(state: AppState): GuardedDepositInfo | undefined {
    return state.web3.guardedDepositInfo;
}

export function getCheckpointedStrategy(state: AppState): CheckpointedStrategy | undefined {
    return state.web3.checkpointedStrategy;
}

export function getCheckpointedTrader(state: AppState): CheckpointedTrader | undefined {
    return state.web3.checkpointedTrader;
}

export function getWithdrawState(state: AppState): WithdrawState {
    return state.web3.withdrawState;
}

export function getWithdrawDDXState(state: AppState): WithdrawDDXState {
    return state.web3.withdrawDDXState;
}

export function getStrategyProof(state: AppState): string | undefined {
    return state.web3.strategyProof;
}

export function getTraderProof(state: AppState): string | undefined {
    return state.web3.traderProof;
}

export function getCurrentBlockNumber(state: AppState): number {
    return state.web3.blockNumber;
}
