import { extendTheme } from '@chakra-ui/react';

export default extendTheme({
    config: {
        initialColorMode: 'dark',
    },

    //generated with https://maketintsandshades.com/
    colors: {
        brand: {
            red: {
                50: '#db466a',
                100: '#db466a',
                200: '#db466a',
                300: '#D73159',
                400: '#f04351',
            },
            green: {
                50: '#6bc0ae',
                100: '#6bc0ae',
                200: '#6bc0ae',
                300: '#5AB9A5',
                400: '#70c6a3',
            },
            pink: {
                50: '#f194b3',
                100: '#f194b3',
                200: '#f194b3',
                300: '#ef88aa',
                400: '#d77a99',
            },
        },
        lavender: {
            50: '#b1a1ef',
            100: '#b1a1ef',
            200: '#b1a1ef',
            300: '#a896ed',
            400: '#9787d5',
        },
        indigo: {
            50: '#422d8d',
            100: '#422d8d',
            200: '#422d8d',
            300: '#2d1680',
            400: '#291473',
        },
        rhino: {
            50: '#46506e',
            100: '#46506e',
            200: '#46506e',
            300: '#313C5E',
            400: '#2c3655',
        },
        //misc colors
        discord: {
            50: '#9cace5',
            100: '#9cace5',
            200: '#9cace5',
            300: '#9cace5',
            400: '#6d78a0',
            500: '#6d78a0',
            600: '#6d78a0',
            700: '#6d78a0',
            800: '#6d78a0',
        },
        card: {
            100: '#0d1126',
            200: '#25293c',
        },
        walletItems: {
            100: '#1e2745',
            200: 'rgba(30, 39, 69,.5)',
        },
        background: {
            100: '#060a24',
        },
        text: {
            100: '#a1a8c0',
            200: '#C1BCD3',
        },
        notifications: {
            100: '#11162e',
            200: '#182026',
        },
        border: {
            100: '#212631',
        },
        slider: {
            100: '#26283a',
            200: '#212436',
            300: 'rgba(255, 255, 255, 0.08)',
        },
    },
    components: {
        Input: {
            variants: {
                filled: {
                    field: {
                        _focus: {
                            borderColor: 'lavender.300',
                            boxShadow: 'lavender.300 0 0 0 1px',
                        },
                    },
                },
            },
        },
        NumberInput: {
            variants: {
                filled: {
                    field: {
                        _focus: {
                            borderColor: 'lavender.300',
                            boxShadow: 'lavender.300 0 0 0 1px',
                        },
                    },
                },
            },
        },
        Select: {
            variants: {
                filled: {
                    field: {
                        _focus: {
                            borderColor: 'lavender.300',
                            boxShadow: 'lavender.300 0 0 0 1px',
                        },
                    },
                },
            },
        },
        Button: {
            baseStyle: {
                _focus: {
                    boxShadow: 'rgba(255, 255, 255, 0.3) 0 0 0 3px',
                },
            },
        },
        Avatar: {
            baseStyle: {
                _focus: {
                    boxShadow: 'rgba(255, 255, 255, 0.3) 0 0 0 3px',
                },
            },
        },
        Tabs: {
            baseStyle: {
                tab: {
                    _focus: {
                        boxShadow: 'rgba(255, 255, 255, 0.3) 0 0 0 3px',
                    },
                },
            },
            variants: {
                line: {
                    tab: {
                        _focus: {
                            boxShadow: 'rgba(255, 255, 255, 0.3) 0px 3px 0px',
                        },
                    },
                },
            },
        },
        Checkbox: {
            baseStyle: {
                control: {
                    _focus: {
                        boxShadow: 'rgba(255, 255, 255, 0.4) 0 0 0 3px',
                    },
                },
            },
        },
        Link: {
            baseStyle: {
                _focus: {
                    boxShadow: 'rgba(255, 255, 255, 0.4) 0 0 0 3px',
                },
            },
        },
        CloseButton: {
            baseStyle: {
                _focus: {
                    boxShadow: 'rgba(255, 255, 255, 0.4) 0 0 0 3px',
                },
            },
        },
        Tag: {
            variants: {
                solid: {
                    container: {
                        _focus: {
                            borderColor: 'lavender.300',
                            boxShadow: 'rgba(255, 255, 255, 0.4) 0 0 0 3px',
                            transition: 'all 250ms',
                        },
                    },
                },
            },
        },
        Popover: {
            parts: [],
            baseStyle: {
                content: {
                    _focus: {
                        outline: 'none',
                        boxShadow: 'rgba(255, 255, 255, 0.4) 0 0 0 3px',
                        transition: 'all 250ms',
                    },
                },
            },
        },
    },
    styles: {
        global: {
            '*': {
                boxSizing: 'border-box',
                '::-webkit-scrollbar': {
                    display: 'none',
                },
                scrollbarWidth: 'none',
            },
            html: {
                overflow: 'hidden',
                scrollbarWidth: 'none',
                '::-webkit-scrollbar': {
                    display: 'none',
                },
            },
            body: {
                '*::selection': {
                    background: 'rgba(49, 60, 94, 1)',
                },
                '*::-moz-selection': {
                    background: 'rgba(49, 60, 94, 1)',
                },
                margin: 0,
                padding: 0,
                minHeight: '100%',
                fontFamily: "'SF-Pro', 'HelveticaNeue', 'TFN-Light', Helvetica, Arial, sans-serif",
                minWidth: '300px',
                scrollBehavior: 'smooth',
                textRendering: 'optimizeSpeed',
                backgroundRepeat: 'no-repeat',
                backgroundColor: '#060a24',
            },
        },
    },
});
