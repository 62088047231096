import { BigNumber } from '@0x/utils';
import {
    ActionType,
    ChainConnectionStatus,
    CheckpointedStrategy,
    CheckpointedTrader,
    Context,
    DepositUserAction,
    GuardedDepositInfo,
    Jsonify,
    MerkleProof,
    MerkleProofAPIRequest,
    MerkleProofAPIResponse,
    SubmitCheckpointUserAction,
    ToggleTokenUserAction,
    TokenBalance,
    WithdrawDDXState,
    WithdrawState,
} from '@derivadex/types';
import { getFrontendLogger } from '@derivadex/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAction, createAsyncAction } from 'typesafe-actions';

export interface Web3State {
    context: Context | undefined;
    isConnected: boolean;
    networkStatus: ChainConnectionStatus;
    usdcCollateral: TokenBalance | undefined;
    ddxCollateral: TokenBalance | undefined;
    ethAddress: string | undefined;
    guardedDepositInfo: GuardedDepositInfo | undefined;
    checkpointedStrategy: CheckpointedStrategy | undefined;
    checkpointedTrader: CheckpointedTrader | undefined;
    epoch: number | undefined;
    timeValueSinceEpoch: number | undefined;
    latestContractCheckpoint: number | undefined;
    tradeMining: { epochId: number; txOrdinal: number } | undefined;
    strategyProof: string | undefined;
    traderProof: string | undefined;
    withdrawState: WithdrawState;
    withdrawDDXState: WithdrawDDXState;
    isFundingCountdownLocked: boolean;
    blockNumber: number;
}

export const initialWeb3State: Web3State = {
    context: undefined,
    isConnected: false,
    usdcCollateral: undefined,
    ddxCollateral: undefined,
    ethAddress: undefined,
    guardedDepositInfo: undefined,
    checkpointedStrategy: undefined,
    checkpointedTrader: undefined,
    latestContractCheckpoint: undefined,
    epoch: undefined,
    timeValueSinceEpoch: undefined,
    tradeMining: undefined,
    strategyProof: undefined,
    traderProof: undefined,
    withdrawState: {
        pendingAmount: new BigNumber(0),
        allowedAmount: new BigNumber(0),
    } as WithdrawState,
    withdrawDDXState: {
        pendingAmount: new BigNumber(0),
        allowedAmount: new BigNumber(0),
    } as WithdrawDDXState,
    networkStatus: ChainConnectionStatus.UNSET,
    isFundingCountdownLocked: false,
    blockNumber: 0,
};

export const web3Slice = createSlice({
    name: 'web3',
    initialState: initialWeb3State,
    reducers: {
        SET_CONTEXT: (state, action: PayloadAction<Context>) => {
            state.context = action.payload;
        },
        CONNECT_WEB3: (state) => {
            state.isConnected = true;
        },
        DISCONNECT_WEB3: (state) => {
            state.isConnected = false;
        },
        SET_USDC_COLLATERAL: (state, action: PayloadAction<TokenBalance>) => {
            state.usdcCollateral = action.payload;
        },
        SET_DDX_COLLATERAL: (state, action: PayloadAction<TokenBalance>) => {
            state.ddxCollateral = action.payload;
        },
        SET_ETH_ADDRESS: (state, action: PayloadAction<string>) => {
            state.ethAddress = action.payload;
        },
        SET_GUARDED_DEPOSIT_INFO: (state, action: PayloadAction<GuardedDepositInfo>) => {
            state.guardedDepositInfo = action.payload;
        },
        SET_CHECKPOINTED_STRATEGY: (state, action: PayloadAction<CheckpointedStrategy>) => {
            state.checkpointedStrategy = action.payload;
        },
        SET_CHECKPOINTED_TRADER: (state, action: PayloadAction<CheckpointedTrader>) => {
            state.checkpointedTrader = action.payload;
        },
        SET_LATEST_CONTRACT_CHECKPOINT: (state, action: PayloadAction<number>) => {
            state.latestContractCheckpoint = action.payload;
        },
        EPOCH_UPDATE: (state, action: PayloadAction<{ epochId: number; timeValueSinceEpoch: number | undefined }>) => {
            state.epoch = action.payload.epochId;
            state.timeValueSinceEpoch = action.payload.timeValueSinceEpoch;
        },
        TRADE_MINING_EPOCH_UPDATE: (state, action: PayloadAction<{ epochId: number; txOrdinal: number }>) => {
            getFrontendLogger().log(`Trade Mining ${JSON.stringify(action.payload)}`);
            state.tradeMining = action.payload;
        },
        SET_PROOF: (state, action: PayloadAction<MerkleProof>) => {
            state.strategyProof = action.payload.strategyProof;
            state.traderProof = action.payload.traderProof;
        },
        SET_WITHDRAW_STATE: (state, action: PayloadAction<WithdrawState>) => {
            state.withdrawState.allowedAmount = action.payload.allowedAmount;
            state.withdrawState.pendingAmount = action.payload.pendingAmount;
        },
        SET_WITHDRAW_DDX_STATE: (state, action: PayloadAction<WithdrawDDXState>) => {
            state.withdrawDDXState.allowedAmount = action.payload.allowedAmount;
            state.withdrawDDXState.pendingAmount = action.payload.pendingAmount;
        },
        SET_CHAIN_CONNECTION_STATUS: (state, action: PayloadAction<ChainConnectionStatus>) => {
            state.networkStatus = action.payload;
        },
        SET_IS_FUNDING_COUNTDOWN_FROZEN: (state, action: PayloadAction<boolean>) => {
            state.isFundingCountdownLocked = action.payload;
        },
        SET_BLOCK_NUMBER: (state, action: PayloadAction<number>) => {
            state.blockNumber = action.payload;
        },
    },
});

export const {
    SET_CONTEXT,
    CONNECT_WEB3,
    DISCONNECT_WEB3,
    SET_USDC_COLLATERAL,
    SET_DDX_COLLATERAL,
    SET_ETH_ADDRESS,
    SET_GUARDED_DEPOSIT_INFO,
    SET_CHECKPOINTED_STRATEGY,
    SET_CHECKPOINTED_TRADER,
    SET_LATEST_CONTRACT_CHECKPOINT,
    EPOCH_UPDATE,
    TRADE_MINING_EPOCH_UPDATE,
    SET_CHAIN_CONNECTION_STATUS,
    SET_WITHDRAW_STATE,
    SET_WITHDRAW_DDX_STATE,
    SET_PROOF,
    SET_IS_FUNDING_COUNTDOWN_FROZEN,
    SET_BLOCK_NUMBER,
} = web3Slice.actions;

export const MERKLE_PROOF_API = createAsyncAction(
    ActionType.MERKLE_PROOF_API_REQUEST,
    ActionType.MERKLE_PROOF_API_SUCCESS,
    ActionType.MERKLE_PROOF_API_FAILURE,
)<MerkleProofAPIRequest, Jsonify<MerkleProofAPIResponse>, Error>();
export const SUBMIT_DEPOSIT = createAction(ActionType.SUBMIT_DEPOSIT)<DepositUserAction>();
export const APPROVE_TOKEN = createAction(ActionType.APPROVE_TOKEN)<{
    toggleTokenAction: ToggleTokenUserAction;
}>();
export const WITHDRAW_STATE_UPDATE_TRIGGER = createAction(ActionType.UPDATE_WITHDRAW_STATE)();
export const WITHDRAW_DDX_STATE_UPDATE_TRIGGER = createAction(ActionType.UPDATE_WITHDRAW_DDX_STATE)();
export const COMPLETE_WITHDRAW = createAction(ActionType.COMPLETE_WITHDRAW)();
export const COMPLETE_DDX_WITHDRAW = createAction(ActionType.COMPLETE_DDX_WITHDRAW)();
export const SUBMIT_CHECKPOINT = createAction(ActionType.SUBMIT_CHECKPOINT)<SubmitCheckpointUserAction>();

export default web3Slice.reducer;
