import { Button, ButtonGroup, Flex, HStack, Text } from '@chakra-ui/react';
import ButtonToggleGroup from 'components/Button/ButtonToggleGroup';
import { useScreenSize } from 'hooks/useScreenSize';
import { useTranslation } from 'react-i18next';
import { FiArrowDownLeft, FiArrowUpRight, FiChevronDown } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_LOOKBACK_PERIOD_FILTER } from 'store/portfolio/slice';
import { PortfolioPeriodFilter } from 'store/portfolio/utils';
import { getSelectedStrategy } from 'store/strategy/selectors';
import {
    TOGGLE_DEPOSIT_COLLATERALS_DIALOG,
    TOGGLE_STRATEGIES_DIALOG,
    TOGGLE_WITHDRAW_DDX_DIALOG,
    TOGGLE_WITHDRAW_TOKENS_DIALOG,
} from 'store/ui/slice';

export default function PortfolioHeader() {
    const isDesktopView = useScreenSize();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentStrategy = useSelector(getSelectedStrategy);

    const onChange = (selected: string) => {
        dispatch(UPDATE_LOOKBACK_PERIOD_FILTER(selected as PortfolioPeriodFilter));
    };

    return isDesktopView ? (
        <Flex alignItems="center" justifyContent="space-between">
            <ButtonGroup>
                <Button
                    id="strategies-button"
                    leftIcon={<FiChevronDown />}
                    colorScheme="rhino"
                    color="text.100"
                    size={isDesktopView ? 'md' : 'sm'}
                    backgroundColor={'card.100'}
                    _hover={{
                        bgColor: 'card.100',
                        color: 'white',
                    }}
                    border="none"
                    _focus={{
                        outline: 'none',
                    }}
                    onClick={() => dispatch(TOGGLE_STRATEGIES_DIALOG())}
                >
                    <Text alignItems={'left'} fontWeight="600">
                        {(currentStrategy && currentStrategy.strategy) || 'main'}
                    </Text>
                </Button>
                <Button
                    id="deposit-button"
                    leftIcon={<FiArrowDownLeft />}
                    colorScheme="rhino"
                    color="text.100"
                    size={isDesktopView ? 'md' : 'sm'}
                    backgroundColor={'card.100'}
                    _hover={{
                        bgColor: 'card.100',
                        color: 'white',
                    }}
                    border="none"
                    _focus={{
                        outline: 'none',
                    }}
                    onClick={() => dispatch(TOGGLE_DEPOSIT_COLLATERALS_DIALOG())}
                >
                    {t('deposit')}
                </Button>
                <Button
                    id="withdraw-button"
                    leftIcon={<FiArrowUpRight />}
                    colorScheme="rhino"
                    color="text.100"
                    size={isDesktopView ? 'md' : 'sm'}
                    backgroundColor={'card.100'}
                    _hover={{
                        bgColor: 'card.100',
                        color: 'white',
                    }}
                    border="none"
                    _focus={{
                        outline: 'none',
                    }}
                    onClick={() => dispatch(TOGGLE_WITHDRAW_TOKENS_DIALOG())}
                >
                    {t('withdraw')}
                </Button>
                <Button
                    id="withdraw-button-ddx"
                    colorScheme="rhino"
                    color="text.100"
                    size={isDesktopView ? 'md' : 'sm'}
                    backgroundColor={'card.100'}
                    _hover={{
                        bgColor: 'card.100',
                        color: 'white',
                    }}
                    border="none"
                    _focus={{
                        outline: 'none',
                    }}
                    onClick={() => dispatch(TOGGLE_WITHDRAW_DDX_DIALOG())}
                >
                    {t('withdrawDDX')}
                </Button>
            </ButtonGroup>
            <HStack spacing={6}>
                <Flex width="100%" justify="flex-end">
                    <ButtonToggleGroup buttons={['1h', '1D', '1W', '1M']} onChange={onChange} />
                </Flex>
            </HStack>
        </Flex>
    ) : (
        <Flex width="100%" justify="flex-end">
            <ButtonToggleGroup buttons={['1h', '1D', '1W', '1M']} onChange={onChange} />
        </Flex>
    );
}
