import { DerivadexSocket } from '@derivadex/utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SocketState = {
    socket: DerivadexSocket | undefined;
};

export const initialSocketState: SocketState = {
    socket: undefined,
};

export const socketSlice = createSlice({
    name: 'socket',
    initialState: initialSocketState,
    reducers: {
        SET_SOCKET: (state, action: PayloadAction<DerivadexSocket>) => {
            state.socket = action.payload;
        },
    },
});

export const { SET_SOCKET } = socketSlice.actions;
