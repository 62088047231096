import { TabList, TabPanels, Tabs, Text, useTheme } from '@chakra-ui/react';
import { CustomTab, CustomTabPanel } from 'components/Tabs/Tabs';
import { useScreenSize } from 'hooks/useScreenSize';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PortfolioTableType } from 'store/portfolio/utils';
import { useAccount } from 'wagmi';

import AdlHistory from './AdlHistory';
import DDXRewardsHistory from './DdxRewardsHistory';
import DepositsAndWithdrawals from './DepositsAndWithdrawals';
import FeesHistory from './FeesHistory';
import FundingHistory from './FundingHistory';
import OrderIntentsTable from './OrderIntents';
import PositionsTable from './Positions';

export default function PortfolioTables() {
    const { t } = useTranslation();
    const isDesktopView = useScreenSize();
    const { isConnected } = useAccount();
    const theme = useTheme();
    const [tabIndex, setTabIndex] = useState(0);

    return (
        <Tabs
            isLazy
            isFitted
            variant="enclosed"
            h="100%"
            whiteSpace="nowrap"
            sx={{
                '::-webkit-scrollbar': {
                    display: 'none',
                },
                scrollbarWidth: 'none',
            }}
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
            colorScheme="white"
        >
            <TabList
                overflowX="scroll"
                mb="0.1rem"
                bgColor="rgba(255,255,255,0.04)"
                borderColor="rgba(255,255,255,.06)"
                color="text.100"
            >
                <CustomTab
                    className="tab-positions"
                    selected={tabIndex === PortfolioTableType.Positions}
                    text={'Positions'}
                />

                <CustomTab
                    className="tab-order-intents"
                    selected={tabIndex === PortfolioTableType.OrderIntents}
                    text={'Order Intents'}
                />

                <CustomTab
                    className="tab-deposits-withdrawals"
                    selected={tabIndex === PortfolioTableType.DepositsAndWithdrawals}
                    text={'Deposits And Withdrawals'}
                />

                <CustomTab
                    className="tab-trademining-rewards"
                    ml="0.25rem"
                    selected={tabIndex === PortfolioTableType.TradeMiningRewards}
                    text={'Trade Mining Rewards'}
                />

                <CustomTab
                    className="tab-funding-history"
                    selected={tabIndex === PortfolioTableType.FundingHistory}
                    text={'Funding History'}
                />

                <CustomTab
                    className="tab-adl-history"
                    selected={tabIndex === PortfolioTableType.AdlHistory}
                    text={'ADL History'}
                />

                <CustomTab
                    className="tab-fees-history"
                    selected={tabIndex === PortfolioTableType.FeeHistory}
                    text={'Fees History'}
                />
            </TabList>

            {!isConnected ? (
                <Text p="0.5rem"> {t('web3Error')}</Text>
            ) : (
                <TabPanels
                    overflowY="auto"
                    h="85%"
                    sx={{
                        '::-webkit-scrollbar': {
                            display: 'none',
                        },
                        scrollbarWidth: 'none',
                    }}
                >
                    <CustomTabPanel>
                        <PositionsTable isDesktopView={isDesktopView} />
                    </CustomTabPanel>
                    <CustomTabPanel>
                        <OrderIntentsTable isDesktopView={isDesktopView} />
                    </CustomTabPanel>
                    <CustomTabPanel>
                        <DepositsAndWithdrawals isDesktopView={isDesktopView} />
                    </CustomTabPanel>
                    <CustomTabPanel>
                        <DDXRewardsHistory isDesktopView={isDesktopView} />
                    </CustomTabPanel>
                    <CustomTabPanel>
                        <FundingHistory isDesktopView={isDesktopView} />
                    </CustomTabPanel>
                    <CustomTabPanel>
                        <AdlHistory isDesktopView={isDesktopView} />
                    </CustomTabPanel>
                    <CustomTabPanel>
                        <FeesHistory isDesktopView={isDesktopView} />
                    </CustomTabPanel>
                </TabPanels>
            )}
        </Tabs>
    );
}
