import { ChakraProvider } from '@chakra-ui/react';
import { getFrontendLogger } from '@derivadex/utils';
import { PrivyClientConfig, PrivyProvider } from '@privy-io/react-auth';
import { WagmiProvider as PrivyWagmiProvider } from '@privy-io/wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getLoggingDestinations, isConsoleLoggingEnabled } from 'logging/utils';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { Fonts } from 'theme/fonts';
import { WagmiProvider } from 'wagmi';

import App from './App';
import { config, getLoginMethods } from './config';
import { initialRuntimeConfig } from './config/runtimeConfig';
import { store } from './store';
import theme from './theme';

getFrontendLogger().initialize(isConsoleLoggingEnabled(), getLoggingDestinations());

const privyConfig: PrivyClientConfig = {
    // Display email and wallet as login methods
    loginMethods: getLoginMethods(),
    appearance: {
        theme: 'dark',
        accentColor: '#676FFF',
        logo: '/icons/ddx_logo_darkmode.jpg',
        showWalletLoginFirst: true,
    },
    // Create embedded wallets for users who don't have a wallet
    embeddedWallets: {
        createOnLogin: 'users-without-wallets',
    },
};

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        {!window.Cypress ? (
            <PrivyProvider appId={initialRuntimeConfig.PRIVY_APP_ID} config={privyConfig}>
                <BrowserRouter>
                    <IntercomProvider appId={initialRuntimeConfig.INTERCOM_ID}>
                        <Provider store={store}>
                            <QueryClientProvider client={queryClient}>
                                <PrivyWagmiProvider config={config} reconnectOnMount={false}>
                                    <ChakraProvider theme={theme}>
                                        <Fonts />
                                        <App />
                                    </ChakraProvider>
                                </PrivyWagmiProvider>
                            </QueryClientProvider>
                        </Provider>
                    </IntercomProvider>
                </BrowserRouter>
            </PrivyProvider>
        ) : (
            <BrowserRouter>
                <IntercomProvider appId={initialRuntimeConfig.INTERCOM_ID}>
                    <Provider store={store}>
                        <QueryClientProvider client={queryClient}>
                            <WagmiProvider config={config} reconnectOnMount={false}>
                                <ChakraProvider theme={theme}>
                                    <Fonts />
                                    <App />
                                </ChakraProvider>
                            </WagmiProvider>
                        </QueryClientProvider>
                    </Provider>
                </IntercomProvider>
            </BrowserRouter>
        )}
    </React.StrictMode>,
);
