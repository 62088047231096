import {
    ConfigEventType,
    ConfigMessage,
    ContentConfigEvent,
    Context,
    KycConfigEvent,
    OperatorConfigEvent,
    RuntimeConfigEvent,
    UIDowntimeCode,
    UIDowntimeState,
} from '@derivadex/types';
import { DerivadexSocket, getErrorMessage, getFrontendLogger } from '@derivadex/utils';
import { readContract } from '@wagmi/core';
import { config } from 'config';
import { CustodianContractAbi } from 'contract-artifacts/abis';
import { utils } from 'ethers';
import i18n from 'i18next';
import { eventChannel } from 'redux-saga';
import { SET_MARKETS } from 'store/market/slice';
import { waitFor, waitUntilTrue } from 'store/saga';
import { handleIsNode0Healthy } from 'store/ui/saga';
import { SET_DOWNTIME } from 'store/ui/slice';
import { getWeb3Context, isEthAddressConnected } from 'store/web3/selectors';
import { call, fork, put, putResolve, take } from 'typed-redux-saga/macro';
import { DDX_APPLICATION_ID, KYC_APPLICATION_ID } from 'utils/constants';
import { getAddress } from 'utils/web3_api';

import i18nInitializer from '../../config/i18n';
import { configSlices } from './slice';

export function* operatorConfigUpdateHandler(payload: OperatorConfigEvent) {
    try {
        const context = yield* call(waitFor<Context>, getWeb3Context);
        yield* call(waitUntilTrue, isEthAddressConnected);
        const isEncryptionKeyValid = yield* call(
            isOperatorEncryptionKeyValid,
            DDX_APPLICATION_ID,
            payload.c.encryptionKey,
            context,
        );
        if (!isEncryptionKeyValid) {
            yield* putResolve(
                SET_DOWNTIME({ message: UIDowntimeState.PROBLEM_OCCURRED, code: UIDowntimeCode.OPERATOR_KEY_INVALID }),
            );
        }
        yield* put(configSlices.UPDATE_OPERATOR_CONFIG(payload.c));
        yield* fork(handleIsNode0Healthy);
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

export function* kycConfigUpdateHandler(payload: KycConfigEvent) {
    try {
        const context = yield* call(waitFor<Context>, getWeb3Context);
        yield* call(waitUntilTrue, isEthAddressConnected);
        const isEncryptionKeyValid = yield* call(
            isOperatorEncryptionKeyValid,
            KYC_APPLICATION_ID,
            payload.c.encryptionKey,
            context,
        );
        if (!isEncryptionKeyValid) {
            yield* putResolve(
                SET_DOWNTIME({ message: UIDowntimeState.PROBLEM_OCCURRED, code: UIDowntimeCode.KYC_KEY_INVALID }),
            );
        }
        yield* put(configSlices.UPDATE_KYC_CONFIG(payload.c));
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

export function* runtimeConfigurationUpdateHandler(payload: RuntimeConfigEvent) {
    yield* put(
        configSlices.UPDATE_RUNTIME_CONFIG({
            isServerLoadComplete: true,
            runtimeConfig: payload.c,
        }),
    );
}

async function isOperatorEncryptionKeyValid(
    applicationId: string,
    encryptionKey: string,
    context: Context,
): Promise<boolean> {
    try {
        const encryptionKeyToAddress = utils.computeAddress(encryptionKey);
        const [signerStates]: any = await readContract(config, {
            address: getAddress(context.contractAddresses.derivaDEXAddress),
            abi: CustodianContractAbi,
            functionName: 'getSignerStates',
            args: [applicationId, [encryptionKeyToAddress]],
        });
        if (signerStates === undefined || signerStates.custodian === undefined) {
            return false;
        }

        return true;
    } catch (error: any) {
        throw new Error(`System malfunction: Could not validate operator encryption key. ${getErrorMessage(error)}`);
    }
}

export async function i18nInitializerComplete() {
    await i18nInitializer;
}

export function* runtimeContentUpdateHandler(payload: ContentConfigEvent) {
    yield* call(i18nInitializerComplete);
    yield* call([i18n, i18n.addResourceBundle], 'en', 'translation', payload.c, undefined, true);
}

export function* handleConfig(socket: DerivadexSocket) {
    try {
        const channel = yield* call(getConfigFeedEventChannel, socket);
        while (true) {
            const message = yield* take(channel);
            if (message.t === ConfigEventType.MARKETS) {
                if (message.c === undefined || message.c.length === 0) {
                    throw new Error(`System malfunction: No markets available. ${JSON.stringify(message.c)}`);
                }
                yield* putResolve(SET_MARKETS(message.c));
            } else if (message.t === ConfigEventType.OPERATOR) {
                yield* fork(operatorConfigUpdateHandler, message);
            } else if (message.t === ConfigEventType.KYC) {
                yield* fork(kycConfigUpdateHandler, message);
            } else if (message.t === ConfigEventType.RUNTIME) {
                yield* call(runtimeConfigurationUpdateHandler, message);
            } else if (message.t === ConfigEventType.CONTENT) {
                yield* call(runtimeContentUpdateHandler, message);
            }
        }
    } catch (error) {
        getFrontendLogger().logError(error);
        yield* putResolve(
            SET_DOWNTIME({ message: UIDowntimeState.PROBLEM_OCCURRED, code: UIDowntimeCode.CONFIG_LOAD_FAILED }),
        );
    }
}

export function getConfigFeedEventChannel(socket: DerivadexSocket) {
    return eventChannel<ConfigMessage>((emitter) => {
        socket.onConfigUpdate((value) => emitter(value));
        return () => {
            socket.close();
        };
    });
}
