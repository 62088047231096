import { LoggingDestination } from '@derivadex/types';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';

import { initialRuntimeConfig } from '../config/runtimeConfig';
import { initializeSentry, shouldUseExternalAnalytics } from './sentry';

export function isConsoleLoggingEnabled() {
    const params = new URLSearchParams(window ? window.location.search : {});
    return params.get('console') === '1';
}

export function getLoggingDestinations(): LoggingDestination[] {
    const loggingDestinations: LoggingDestination[] = [];
    if (shouldUseExternalAnalytics()) {
        ReactGA.initialize(initialRuntimeConfig.GOOGLE_ANALYTICS_KEY);
        ReactGA.send('pageview');
        initializeSentry();
        loggingDestinations.push({
            name: 'sentry',
            logFunction: (...messages: any[]) => {
                // Don't send generic logs to sentry for now
                return;
            },
            logErrorFunction: (...messages: any[]) => {
                if (messages.length === 1) {
                    Sentry.captureException(messages[0]);
                } else {
                    Sentry.captureException(messages.map((m) => JSON.stringify(m)).join(' '));
                }
            },
        });
    }
    return loggingDestinations;
}
