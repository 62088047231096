import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export default i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: false,
        supportedLngs: ['en', 'zh', 'fr'],
        interpolation: {
            escapeValue: false,
        },
        react: {
            bindI18nStore: 'added',
        },
    });
