import { DepositDdxUIState, DepositUsdcUIState } from 'store/ui/slice';

export function areDepositsInProgress(isDepositUSDCState: DepositUsdcUIState, isDepositDDXState: DepositDdxUIState) {
    if (
        ![DepositUsdcUIState.CONFIRMED, DepositUsdcUIState.NONE].includes(isDepositUSDCState) ||
        ![DepositDdxUIState.CONFIRMED, DepositDdxUIState.NONE].includes(isDepositDDXState)
    ) {
        return true;
    }
    return false;
}
