import { css, Global } from '@emotion/react';

const GlobalFonts = css`
    @font-face {
        font-family: 'TFN-Light';
        src: url('TFN-Light.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'TFN-DemiBold';
        src: url('TFN-DemiBold.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'TFN-Medium';
        src: url('TFN-Medium.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'HelveticaNeue';
        src: url('HelveticaNeue.woff2') format('woff2');
        font-weight: normal;
        font-style: normal;
        font-display: block;
    }

    @font-face {
        font-family: 'SF-Pro';
        font-weight: normal;
        font-style: normal;
        src: local('SF Pro Display Regular'),
            url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff2')
                format('woff2');
        font-display: swap;
    }

    @font-face {
        font-family: 'SF-Pro';
        font-weight: 500;
        src: local('SF Pro Display Medium'),
            url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff2')
                format('woff2');
        font-display: swap;
    }

    @font-face {
        font-family: 'SF-Pro';
        font-weight: 600;
        src: local('SF Pro Display Semibold'),
            url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-semibold-webfont.woff2')
                format('woff2');
        font-display: swap;
    }

    @font-face {
        font-family: 'SF-Pro';
        font-weight: bold;
        src: local('SF Pro Display Bold'),
            url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff2')
                format('woff2');
        font-display: swap;
    }

    @font-face {
        font-family: 'SF-Pro';
        font-weight: 800;
        src: local('SF Pro Display Heavy'),
            url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-heavy-webfont.woff2')
                format('woff2');
        font-display: swap;
    }

    @font-face {
        font-family: 'SF-Pro-Text';
        font-style: normal;
        font-weight: normal;
        src: local('SF Pro Text Regular'),
            url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscotext-regular-webfont.woff2')
                format('woff2');
        font-display: swap;
    }

    @font-face {
        font-family: 'SF-Pro-Text';
        font-style: normal;
        font-weight: 500;
        src: local('SF Pro Text Medium'),
            url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscotext-medium-webfont.woff2')
                format('woff2');
        font-display: swap;
    }

    @font-face {
        font-family: 'SF-Pro-Text';
        font-style: normal;
        font-weight: 600;
        src: local('SF Pro Text Semibold'),
            url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscotext-semibold-webfont.woff2')
                format('woff2');
        font-display: swap;
    }

    @font-face {
        font-family: 'SF-Pro-Text';
        font-style: normal;
        font-weight: bold;
        src: local('SF Pro Text Bold')
            url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscotext-bold-webfont.woff2')
            format('woff2');
        font-display: swap;
    }

    @font-face {
        font-family: 'SF-Pro-Text';
        font-style: normal;
        font-weight: 800;
        src: local('SF Pro Text Heavy')
            url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscotext-heavy-webfont.woff2')
            format('woff2');
        font-display: swap;
    }
`;

export const Fonts = () => (
    <>
        <link rel="preload" href="/HelveticaNeue.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
        <Global styles={GlobalFonts} />
    </>
);
