import { IconButton, PlacementWithLogical, Tooltip } from '@chakra-ui/react';
import { getExplorerLink } from '@derivadex/utils';
import { useScreenSize } from 'hooks/useScreenSize';
import { useTranslation } from 'react-i18next';
import { FiExternalLink } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { getRuntimeConfig } from 'store/config/selectors';

export default function ExplorerLink({
    label,
    url,
    placement,
    isDisabled,
}: {
    label: string;
    url: string;
    placement?: PlacementWithLogical;
    isDisabled?: boolean;
}) {
    const { t } = useTranslation();
    const isDesktop = useScreenSize();
    const runtimeConfig = useSelector(getRuntimeConfig);
    const explorerLink = getExplorerLink(runtimeConfig.EXPLORER_FALLBACK_HOST);
    return (
        <a href={`${explorerLink}/${url}`} target="_blank" style={{ pointerEvents: isDisabled ? 'none' : 'inherit' }}>
            <Tooltip label={label} fontSize="1.5vh" placement={placement ?? 'left'}>
                <IconButton
                    size={isDesktop ? 'xs' : 'sm'}
                    verticalAlign="middle"
                    aria-label={t('positionExplorerButtonDescription')}
                    icon={<FiExternalLink />}
                    isRound
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        event.stopPropagation();
                    }}
                    isDisabled={isDisabled}
                />
            </Tooltip>
        </a>
    );
}
