import { Abi } from 'viem';

import * as Checkpoint from '../contract-artifacts/Checkpoint.json';
import * as Collateral from '../contract-artifacts/Collateral.json';
import * as CollateralKYC from '../contract-artifacts/CollateralKYC.json';
import * as Custodian from '../contract-artifacts/Custodian.json';
import * as SafeERC20Wrapper from '../contract-artifacts/SafeERC20Wrapper.json';
import * as Stake from '../contract-artifacts/Stake.json';
import * as StakeKYC from '../contract-artifacts/StakeKYC.json';

export const StakeContractAbi = Stake.compilerOutput.abi as Abi;
export const StakeKYCContractAbi = StakeKYC.compilerOutput.abi as Abi;
export const CheckpointContractAbi = Checkpoint.compilerOutput.abi as Abi;
export const ERC20ContractAbi = SafeERC20Wrapper.compilerOutput.abi as Abi;
export const CollateralContractAbi = Collateral.compilerOutput.abi as Abi;
export const CollateralKYCContractAbi = CollateralKYC.compilerOutput.abi as Abi;
export const CustodianContractAbi = Custodian.compilerOutput.abi as Abi;
