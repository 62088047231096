import { Tab, TabPanel, useTheme } from '@chakra-ui/react';

export function CustomTab({ selected, text, ...props }: { selected: boolean; text: string; [x: string]: any }) {
    const theme = useTheme();
    const tab_color = theme.colors.card[100];
    return (
        <Tab
            mt="0.25rem"
            fontSize="1rem"
            color={selected ? 'white' : 'inherit'}
            border={selected ? '1px solid rgba(255,255,255,.06)' : '1px solid transparent'}
            backgroundColor={selected ? tab_color : 'none'}
            _hover={{
                color: 'white',
                backgroundColor: tab_color,
                border: '1px solid rgba(255, 255, 255, 0.06)',
            }}
            {...props}
        >
            {text}
        </Tab>
    );
}

export function CustomTabPanel({ children, ...props }: { children: JSX.Element; [x: string]: any }) {
    return (
        <TabPanel
            p="0"
            overflowY="auto"
            maxH="100%"
            position="sticky"
            top={0}
            sx={{
                '::-webkit-scrollbar': {
                    display: 'none',
                },
                scrollbarWidth: 'none',
            }}
            {...props}
        >
            {children}
        </TabPanel>
    );
}
