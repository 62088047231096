import { BigNumber } from '@0x/utils';
import { CheckpointedStrategy, UIStrategy, WithdrawDDXIntent, WithdrawIntent, WithdrawState } from '@derivadex/types';
import { as_nonce } from '@derivadex/utils';

import { OPERATOR_DECIMALS } from './constants';
import { tokenAmountInUnitsToBigNumber, unitsInTokenAmount } from './tokens';

export const withdrawIntentBuilder = (strategyId: string, currency: string, amount: string): WithdrawIntent => {
    const withdrawIntent: WithdrawIntent = {
        strategyId,
        currency,
        amount: new BigNumber(amount),
        nonce: as_nonce(Date.now()),
        signature: '',
    } as WithdrawIntent;
    return withdrawIntent;
};

export const withdrawDDXIntentBuilder = (amount: string): WithdrawDDXIntent => {
    const WithdrawDDXIntent: WithdrawDDXIntent = {
        amount: new BigNumber(amount),
        nonce: as_nonce(Date.now()),
        signature: '',
    } as WithdrawDDXIntent;
    return WithdrawDDXIntent;
};

export const buildWithdrawalData = (
    collateralAddress: string,
    decimals: number,
    allowedAmount: BigNumber,
): {
    tokens: string[];
    amounts: string[];
} => {
    const baseAmount = unitsInTokenAmount(allowedAmount.toString(), decimals).decimalPlaces(0, BigNumber.ROUND_DOWN);
    return { tokens: [collateralAddress], amounts: [baseAmount.toString()] };
};

export const buildStrategyData = (
    collateralAddress: string,
    strategyId: string,
    currentStrategy: UIStrategy,
    checkpointedStrategy: CheckpointedStrategy,
): {
    strategyId: string;
    maxLeverage: string;
    frozen: boolean;
    availCollateral: {
        tokens: string[];
        amounts: string[];
    };
    lockedCollateral: {
        tokens: string[];
        amounts: string[];
    };
} => {
    const strategyLeaf: any = {
        strategyId: strategyId,
        maxLeverage: new BigNumber(currentStrategy.maxLeverage).toString(),
        frozen: false,
    };
    const baseFree = unitsInTokenAmount('' + checkpointedStrategy.availCollateral, OPERATOR_DECIMALS);
    if (baseFree.eq(new BigNumber(0))) {
        strategyLeaf.availCollateral = { tokens: [], amounts: [] };
    } else {
        strategyLeaf.availCollateral = {
            tokens: [collateralAddress],
            amounts: [baseFree.toString()],
        };
    }
    const baseLocked = unitsInTokenAmount('' + checkpointedStrategy.lockedCollateral, OPERATOR_DECIMALS);
    if (baseLocked.eq(new BigNumber(0))) {
        strategyLeaf.lockedCollateral = { tokens: [], amounts: [] };
    } else {
        strategyLeaf.lockedCollateral = {
            tokens: [collateralAddress],
            amounts: [baseLocked.toString()],
        };
    }
    return strategyLeaf;
};

export const buildTraderData = (
    ddxAddress: string,
    availDDX: BigNumber,
    lockedDDX: BigNumber,
    payFeesInDDX: boolean,
): {
    availDDXBalance: string;
    lockedDDXBalance: string;
    referralAddress: string;
    payFeesInDDX: boolean;
} => {
    const baseFree = unitsInTokenAmount(availDDX.toString(), OPERATOR_DECIMALS);
    const baseLocked = unitsInTokenAmount(lockedDDX.toString(), OPERATOR_DECIMALS);
    return {
        availDDXBalance: baseFree.toString(),
        lockedDDXBalance: baseLocked.toString(),
        referralAddress: ddxAddress,
        payFeesInDDX: payFeesInDDX,
    };
};

export const buildWithdrawDDXAmount = (amount: BigNumber): BigNumber => {
    const baseAmount = unitsInTokenAmount(amount.toString(), 18);
    return baseAmount;
};

export const calculateWithdrawState = (
    currentLocked: BigNumber,
    checkpointedLocked: BigNumber,
    unprocessedWithdrawals: BigNumber,
    processedWithdrawals: BigNumber,
    maximumWithdrawalAmount: BigNumber,
): WithdrawState => {
    const processedWithdrawalsAmount = tokenAmountInUnitsToBigNumber(processedWithdrawals, OPERATOR_DECIMALS);
    const unprocessedWithdrawalsAmount = tokenAmountInUnitsToBigNumber(unprocessedWithdrawals, OPERATOR_DECIMALS);

    // If the allowed amount is rate limited (by maximumWithdrawalAmount), we calculate the difference
    // between the value before rate limiting and after so it can be added to pending
    const allowedAmountBeforeRateLimits = checkpointedLocked.minus(unprocessedWithdrawalsAmount);
    let pendingAmountDueToRateLimit = new BigNumber(0);
    if (allowedAmountBeforeRateLimits.gt(maximumWithdrawalAmount)) {
        pendingAmountDueToRateLimit = allowedAmountBeforeRateLimits.minus(maximumWithdrawalAmount);
    }

    return {
        allowedAmount: BigNumber.min(allowedAmountBeforeRateLimits, maximumWithdrawalAmount),
        pendingAmount: currentLocked
            .plus(processedWithdrawalsAmount)
            .minus(checkpointedLocked)
            .plus(pendingAmountDueToRateLimit),
    };
};
