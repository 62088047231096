import { chakra, Icon } from '@chakra-ui/react';
import styled from '@emotion/styled';

export default function getIcons(name: string, boxSize = 6): JSX.Element | undefined {
    const DDX = ({ boxSize }: { boxSize: number }) => (
        <Icon viewBox="0 0 577.2 577.2" boxSize={boxSize}>
            <circle fill="#F7F7F7" cx="290" cy="290" r="290" />
            <g>
                <path
                    fill="#2D1680"
                    d="M52.95,280.43c0-25.31,0-50.46,0-75.85c0.9,0,1.68,0,2.45,0c23.5,0,47,0,70.51,0c3.9,0,7.73,0.54,11.54,1.32
		c1.7,0.35,3.47,0.37,5.13,0.81c23.39,6.12,40.6,19.9,51.06,41.77c2.84,5.94,5,12.19,5.82,18.83c0.54,4.31,1.48,8.57,1.32,12.92
		C199.07,280.74,56.83,280.93,52.95,280.43z"
                />

                <path
                    fill="#2D1680"
                    d="M363.49,280.49c-49.17,0-98.35,0-147.65,0c0-25.26,0-50.41,0-75.74c0.61-0.06,1.22-0.17,1.83-0.17
		c23.98-0.01,47.96-0.11,71.93,0.05c7.8,0.05,15.42,1.53,22.85,4.15c20.72,7.32,35.58,21,44.68,40.89
		c2.5,5.47,4.49,11.23,5.34,17.25c0.59,4.22,0.84,8.48,1.23,12.73C363.72,279.88,363.59,280.11,363.49,280.49z"
                />

                <path
                    fill="#2D1680"
                    d="M53.55,296.67c0.88-0.03,1.75-0.09,2.63-0.09c47.24,0,94.47,0,141.71,0c0.87,0,1.74,0,2.88,0
		c0,1.24,0.1,2.34-0.02,3.42c-0.66,6.22-1.23,12.46-3.16,18.46c-7.24,22.59-21.68,38.72-43.42,48.24c-5.03,2.2-10.29,3.78-15.7,4.65
		c-3.91,0.63-7.89,1.09-11.84,1.1c-23.82,0.1-47.64,0.05-71.46,0.05c-0.71,0-1.41,0-2.36,0c0-1,0-1.78,0-2.57
		c0-23.74,0-47.48,0.01-71.22c0-0.55,0.11-1.1,0.17-1.65C53.18,296.94,53.37,296.8,53.55,296.67z"
                />

                <path
                    fill="#2D1680"
                    d="M363.59,297.1c0.11,3.75-0.05,7.47-0.9,11.15c-0.62,2.71-0.83,5.53-1.61,8.19
		c-7.42,25.13-23.25,42.52-47.91,51.71c-6.52,2.43-13.27,3.76-20.19,4.28c-0.32,0.01-0.64,0.03-0.96,0.04
		c-24.95,0.01-49.91,0.03-74.86,0.04c-0.32,0-0.63-0.06-0.95-0.1c-0.13-0.15-0.27-0.31-0.4-0.46c-0.03-0.64-0.08-1.28-0.08-1.91
		c0-23.67,0-47.33,0-71c0-0.71,0-1.42,0-2.26c0.66-0.08,1.19-0.19,1.72-0.19c48.13-0.01,96.26-0.01,144.39,0
		c0.4,0,0.79,0.06,1.19,0.09C363.22,296.81,363.4,296.96,363.59,297.1z"
                />

                <path
                    fill="#2E1780"
                    d="M357.68,204.65c18.11,0,35.61,0,53.37,0c37.71,55.71,75.5,111.55,113.34,167.44
		c-2.1,0.57-47.86,0.79-56.15,0.31C431.53,316.71,394.74,260.89,357.68,204.65z"
                />

                <path
                    fill="#EF88AA"
                    d="M354.58,372.31c16.13-23.18,31.98-45.94,47.84-68.73c1.68,1.35,44.75,66.01,45.84,68.73
		C417.18,372.31,386.15,372.31,354.58,372.31z"
                />
                <path
                    fill="#EF88AA"
                    d="M524.28,205.52c-3.16,4.61-6.29,9.23-9.48,13.82c-5.51,7.93-11.06,15.83-16.59,23.76
		c-6.57,9.43-13.14,18.87-19.71,28.29c-0.31,0.45-0.72,0.83-1.28,1.47c-0.52-0.59-1.03-1.05-1.41-1.6c-4.51-6.58-9-13.19-13.5-19.78
		c-10.27-15.03-20.54-30.05-30.81-45.08c-0.18-0.26-0.27-0.57-0.4-0.86c0.03-0.32,0.06-0.63,0.09-0.94
		c0.72-0.02,1.44-0.05,2.16-0.05c29.57,0,59.13,0,88.7,0c0.72,0,1.44,0.04,2.16,0.06C524.23,204.9,524.26,205.21,524.28,205.52z"
                />

                <path
                    fill="#A298C4"
                    d="M292.02,372.47c0.32-0.01,0.64-0.03,0.96-0.04C292.66,372.44,292.34,372.45,292.02,372.47z"
                />
                <path
                    fill="#F1A3BD"
                    d="M-89.31,431.92c-0.03,0.31-0.06,0.63-0.09,0.94C-89.75,432.51-90.07,432.16-89.31,431.92z"
                />
                <path
                    fill="#F1A3BD"
                    d="M3.78,432.84c-0.03-0.31-0.05-0.61-0.08-0.92C4.45,432.17,4.15,432.5,3.78,432.84z"
                />
                <path
                    fill="#392487"
                    d="M53.55,296.67c-0.19,0.13-0.37,0.27-0.56,0.4C53.18,296.94,53.37,296.8,53.55,296.67z"
                />
                <path
                    fill="#392487"
                    d="M363.59,297.1c-0.19-0.14-0.37-0.28-0.56-0.43C363.22,296.81,363.4,296.96,363.59,297.1z"
                />
                <path
                    fill="#46328F"
                    d="M215.81,371.94c0.13,0.15,0.27,0.31,0.4,0.46C216.08,372.25,215.94,372.1,215.81,371.94z"
                />
            </g>
        </Icon>
    );
    const MetaMask = ({ boxSize }: { boxSize: number }) => (
        <Icon x="0" y="0" viewBox="30 25 260 260" boxSize={boxSize}>
            <path
                fill="#e2761b"
                stroke="#e2761b"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M274.1 35.5l-99.5 73.9L193 65.8z"
            />
            <path
                className="st1"
                fill="#e4761b"
                stroke="#e4761b"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M44.4 35.5l98.7 74.6-17.5-44.3zM238.3 206.8l-26.5 40.6 56.7 15.6 16.3-55.3zM33.9 207.7L50.1 263l56.7-15.6-26.5-40.6z"
            />
            <path
                className="st1"
                fill="#e4761b"
                stroke="#e4761b"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M103.6 138.2l-15.8 23.9 56.3 2.5-2-60.5zM214.9 138.2l-39-34.8-1.3 61.2 56.2-2.5zM106.8 247.4l33.8-16.5-29.2-22.8zM177.9 230.9l33.9 16.5-4.7-39.3z"
            />
            <path
                className="st2"
                fill=" #d7c1b3"
                stroke=" #d7c1b3"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M211.8 247.4l-33.9-16.5 2.7 22.1-.3 9.3zM106.8 247.4l31.5 14.9-.2-9.3 2.5-22.1z"
            />
            <path
                className="st3"
                fill=" #233447"
                stroke=" #233447"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M138.8 193.5l-28.2-8.3 19.9-9.1zM179.7 193.5l8.3-17.4 20 9.1z"
            />
            <path
                className="st4"
                fill=" #cd6116"
                stroke=" #cd6116"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M106.8 247.4l4.8-40.6-31.3.9zM207 206.8l4.8 40.6 26.5-39.7zM230.8 162.1l-56.2 2.5 5.2 28.9 8.3-17.4 20 9.1zM110.6 185.2l20-9.1 8.2 17.4 5.3-28.9-56.3-2.5z"
            />
            <g>
                <path
                    className="st5"
                    fill=" #e4751f"
                    stroke=" #e4751f"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M87.8 162.1l23.6 46-.8-22.9zM208.1 185.2l-1 22.9 23.7-46zM144.1 164.6l-5.3 28.9 6.6 34.1 1.5-44.9zM174.6 164.6l-2.7 18 1.2 45 6.7-34.1z"
                />
            </g>
            <path
                className="st6"
                fill=" #f6851b"
                stroke=" #f6851b"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M179.8 193.5l-6.7 34.1 4.8 3.3 29.2-22.8 1-22.9zM110.6 185.2l.8 22.9 29.2 22.8 4.8-3.3-6.6-34.1z"
            />
            <path
                fill="#c0ad9e"
                stroke="#c0ad9e"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M180.3 262.3l.3-9.3-2.5-2.2h-37.7l-2.3 2.2.2 9.3-31.5-14.9 11 9 22.3 15.5h38.3l22.4-15.5 11-9z"
            />
            <path
                fill="#161616"
                stroke="#161616"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M177.9 230.9l-4.8-3.3h-27.7l-4.8 3.3-2.5 22.1 2.3-2.2h37.7l2.5 2.2z"
            />
            <g>
                <path
                    className="st9"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill=" #763d16"
                    stroke=" #763d16"
                    d="M278.3 114.2l8.5-40.8-12.7-37.9-96.2 71.4 37 31.3 52.3 15.3 11.6-13.5-5-3.6 8-7.3-6.2-4.8 8-6.1zM31.8 73.4l8.5 40.8-5.4 4 8 6.1-6.1 4.8 8 7.3-5 3.6 11.5 13.5 52.3-15.3 37-31.3-96.2-71.4z"
                />
            </g>
            <path
                className="st6"
                fill=" #f6851b"
                stroke=" #f6851b"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M267.2 153.5l-52.3-15.3 15.9 23.9-23.7 46 31.2-.4h46.5zM103.6 138.2l-52.3 15.3-17.4 54.2h46.4l31.1.4-23.6-46zM174.6 164.6l3.3-57.7 15.2-41.1h-67.5l15 41.1 3.5 57.7 1.2 18.2.1 44.8h27.7l.2-44.8z"
            />
        </Icon>
    );
    const WalletConnect = ({ boxSize }: { boxSize: number }) => (
        <Icon viewBox="0 0 300 185" boxSize={boxSize}>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="walletconnect-logo-alt" fill="#3B99FC" fillRule="nonzero">
                    <path
                        d="M61.4385429,36.2562612 C110.349767,-11.6319051 189.65053,-11.6319051 238.561752,36.2562612 L244.448297,42.0196786 C246.893858,44.4140867 246.893858,48.2961898 244.448297,50.690599 L224.311602,70.406102 C223.088821,71.6033071 221.106302,71.6033071 219.883521,70.406102 L211.782937,62.4749541 C177.661245,29.0669724 122.339051,29.0669724 88.2173582,62.4749541 L79.542302,70.9685592 C78.3195204,72.1657633 76.337001,72.1657633 75.1142214,70.9685592 L54.9775265,51.2530561 C52.5319653,48.8586469 52.5319653,44.9765439 54.9775265,42.5821357 L61.4385429,36.2562612 Z M280.206339,77.0300061 L298.128036,94.5769031 C300.573585,96.9713 300.573599,100.85338 298.128067,103.247793 L217.317896,182.368927 C214.872352,184.763353 210.907314,184.76338 208.461736,182.368989 C208.461726,182.368979 208.461714,182.368967 208.461704,182.368957 L151.107561,126.214385 C150.496171,125.615783 149.504911,125.615783 148.893521,126.214385 C148.893517,126.214389 148.893514,126.214393 148.89351,126.214396 L91.5405888,182.368927 C89.095052,184.763359 85.1300133,184.763399 82.6844276,182.369014 C82.6844133,182.369 82.684398,182.368986 82.6843827,182.36897 L1.87196327,103.246785 C-0.573596939,100.852377 -0.573596939,96.9702735 1.87196327,94.5758653 L19.7936929,77.028998 C22.2392531,74.6345898 26.2042918,74.6345898 28.6498531,77.028998 L86.0048306,133.184355 C86.6162214,133.782957 87.6074796,133.782957 88.2188704,133.184355 C88.2188796,133.184346 88.2188878,133.184338 88.2188969,133.184331 L145.571,77.028998 C148.016505,74.6345347 151.981544,74.6344449 154.427161,77.028798 C154.427195,77.0288316 154.427229,77.0288653 154.427262,77.028899 L211.782164,133.184331 C212.393554,133.782932 213.384814,133.782932 213.996204,133.184331 L271.350179,77.0300061 C273.79574,74.6355969 277.760778,74.6355969 280.206339,77.0300061 Z"
                        id="WalletConnect"
                    ></path>
                </g>
            </g>
        </Icon>
    );
    const Etherscan = () => (
        <Icon viewBox="0 0 293 293" color="#fff">
            <g transform="translate(-219.378 -213.33)">
                <path
                    fill="#fff"
                    d="M280.433,353.152A12.45,12.45,0,0,1,292.941,340.7l20.737.068a12.467,12.467,0,0,1,12.467,12.467v78.414c2.336-.692,5.332-1.43,8.614-2.2a10.389,10.389,0,0,0,8.009-10.11V322.073a12.469,12.469,0,0,1,12.468-12.47h20.778a12.469,12.469,0,0,1,12.467,12.467v90.279s5.2-2.106,10.269-4.245a10.408,10.408,0,0,0,6.353-9.577V290.9a12.466,12.466,0,0,1,12.466-12.467h20.778A12.468,12.468,0,0,1,450.815,290.9v88.625c18.014-13.055,36.271-28.758,50.759-47.639a20.926,20.926,0,0,0,3.185-19.537,146.6,146.6,0,0,0-136.644-99.006c-81.439-1.094-148.744,65.385-148.736,146.834a146.371,146.371,0,0,0,19.5,73.45,18.56,18.56,0,0,0,17.707,9.173c3.931-.346,8.825-.835,14.643-1.518a10.383,10.383,0,0,0,9.209-10.306V353.152"
                />
                <path
                    fill="white"
                    d="M244.417,398.641A146.808,146.808,0,0,0,477.589,279.9c0-3.381-.157-6.724-.383-10.049-53.642,80-152.686,117.4-232.79,128.793"
                    transform="translate(35.564 80.269)"
                />
            </g>
        </Icon>
    );
    const CoinbaseWallet = ({ boxSize }: { boxSize: number }) => (
        <Icon viewBox="0 0 180 180" boxSize={boxSize}>
            <g>
                <ellipse
                    ry="53.999997"
                    rx="53.5"
                    id="svg_4"
                    cy="90.453126"
                    cx="90.999997"
                    strokeWidth="1.5"
                    fill="#fff"
                />
                <g
                    id="svg_1"
                    fill="#245cef"
                    transform="translate(0,180) scale(0.10000000149011612,-0.10000000149011612) "
                >
                    <path
                        id="svg_2"
                        d="m785,1789c-140,-18 -307,-85 -415,-164c-84,-61 -202,-196 -252,-286c-308,-557 36,-1242 667,-1330c417,-58 837,207 969,613c77,237 45,516 -83,738c-58,100 -220,261 -314,312c-187,102 -385,142 -572,117zm205,-369c190,-31 358,-181 418,-374c22,-73 21,-226 -2,-296c-59,-174 -177,-295 -344,-351c-87,-30 -231,-32 -312,-5c-174,59 -295,177 -351,344c-18,51 -23,89 -23,162c1,155 45,263 154,372c126,126 282,176 460,148z"
                    />
                    <path
                        id="svg_3"
                        d="m746,1054c-23,-23 -23,-285 0,-308c23,-23 285,-23 308,0c23,23 23,285 0,308c-23,23 -285,23 -308,0z"
                    />
                </g>
            </g>
        </Icon>
    );
    const WalletIcon = ({ boxSize }: { boxSize: number }) => (
        <Icon viewBox="0 0 890 890" boxSize={boxSize}>
            <path
                fill="currentColor"
                d="M208.1 180.56l355-96.9-18.8-38c-12.3-24.7-42.3-34.9-67-22.6l-317.8 157.5h48.6zM673.3 86.46c-4.399 0-8.8.6-13.2 1.8l-83.399 22.8L322 180.56h415.1l-15.6-57.2c-6-22.3-26.2-36.9-48.2-36.9zM789.2 215.56H50.1c-15.8 0-29.9 7.3-39.1 18.8-4.2 5.3-7.4 11.4-9.2 18.1-1.1 4.2-1.8 8.6-1.8 13.1v557.1c0 27.601 22.4 50 50 50h739.1c27.601 0 50-22.399 50-50v-139.5H542.4c-46.9 0-85-38.1-85-85V486.96c0-23 9.199-43.899 24.1-59.199 13.2-13.601 30.9-22.801 50.7-25.101 3.3-.399 6.7-.6 10.1-.6h296.8v-136.5c.1-27.6-22.3-50-49.9-50z"
            />
            <path
                fill="currentColor"
                d="M874.2 449.86c-5-4.6-10.9-8.1-17.5-10.4-5.101-1.699-10.5-2.699-16.2-2.699H542.4c-27.601 0-50 22.399-50 50V598.06c0 27.6 22.399 50 50 50h298.1c5.7 0 11.1-1 16.2-2.7 6.6-2.2 12.5-5.8 17.5-10.4 10-9.1 16.3-22.3 16.3-36.899v-111.3c0-14.601-6.3-27.802-16.3-36.901zm-227.4 102.5c0 13.8-11.2 25-25 25h-16.6c-13.8 0-25-11.2-25-25v-16.6c0-8 3.7-15.101 9.6-19.601 4.3-3.3 9.601-5.399 15.4-5.399h16.6c13.8 0 25 11.199 25 25v16.6z"
            />
        </Icon>
    );
    const Avatar = ({ boxSize }: { boxSize: number }) => (
        <chakra.svg viewBox="0 0 128 128" color="#fff" boxSize={boxSize}>
            <path
                fill="currentColor"
                d="M103,102.1388 C93.094,111.92 79.3504,118 64.1638,118 C48.8056,118 34.9294,111.768 25,101.7892 L25,95.2 C25,86.8096 31.981,80 40.6,80 L87.4,80 C96.019,80 103,86.8096 103,95.2 L103,102.1388 Z"
            />
            <path
                fill="currentColor"
                d="M63.9961647,24 C51.2938136,24 41,34.2938136 41,46.9961647 C41,59.7061864 51.2938136,70 63.9961647,70 C76.6985159,70 87,59.7061864 87,46.9961647 C87,34.2938136 76.6985159,24 63.9961647,24"
            />
        </chakra.svg>
    );
    const Settings = ({ boxSize }: { boxSize: number }) => (
        <chakra.svg viewBox="0 0 24 24" fill="none" boxSize={boxSize} xmlns="http://www.w3.org/2000/svg">
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                {' '}
                <path
                    fill="none"
                    d="M11 3H13C13.5523 3 14 3.44772 14 4V4.56879C14 4.99659 14.2871 5.36825 14.6822 5.53228C15.0775 5.69638 15.5377 5.63384 15.8403 5.33123L16.2426 4.92891C16.6331 4.53838 17.2663 4.53838 17.6568 4.92891L19.071 6.34312C19.4616 6.73365 19.4615 7.36681 19.071 7.75734L18.6688 8.1596C18.3661 8.46223 18.3036 8.92247 18.4677 9.31774C18.6317 9.71287 19.0034 10 19.4313 10L20 10C20.5523 10 21 10.4477 21 11V13C21 13.5523 20.5523 14 20 14H19.4312C19.0034 14 18.6318 14.2871 18.4677 14.6822C18.3036 15.0775 18.3661 15.5377 18.6688 15.8403L19.071 16.2426C19.4616 16.6331 19.4616 17.2663 19.071 17.6568L17.6568 19.071C17.2663 19.4616 16.6331 19.4616 16.2426 19.071L15.8403 18.6688C15.5377 18.3661 15.0775 18.3036 14.6822 18.4677C14.2871 18.6318 14 19.0034 14 19.4312V20C14 20.5523 13.5523 21 13 21H11C10.4477 21 10 20.5523 10 20V19.4313C10 19.0034 9.71287 18.6317 9.31774 18.4677C8.92247 18.3036 8.46223 18.3661 8.1596 18.6688L7.75732 19.071C7.36679 19.4616 6.73363 19.4616 6.34311 19.071L4.92889 17.6568C4.53837 17.2663 4.53837 16.6331 4.92889 16.2426L5.33123 15.8403C5.63384 15.5377 5.69638 15.0775 5.53228 14.6822C5.36825 14.2871 4.99659 14 4.56879 14H4C3.44772 14 3 13.5523 3 13V11C3 10.4477 3.44772 10 4 10L4.56877 10C4.99658 10 5.36825 9.71288 5.53229 9.31776C5.6964 8.9225 5.63386 8.46229 5.33123 8.15966L4.92891 7.75734C4.53838 7.36681 4.53838 6.73365 4.92891 6.34313L6.34312 4.92891C6.73365 4.53839 7.36681 4.53839 7.75734 4.92891L8.15966 5.33123C8.46228 5.63386 8.9225 5.6964 9.31776 5.53229C9.71288 5.36825 10 4.99658 10 4.56876V4C10 3.44772 10.4477 3 11 3Z"
                    stroke="white"
                    strokeWidth="1.5"
                ></path>{' '}
                <path
                    fill="none"
                    d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12Z"
                    stroke="white"
                    strokeWidth="1.5"
                ></path>{' '}
            </g>
        </chakra.svg>
    );
    switch (name) {
        case 'MetaMask':
            return <MetaMask boxSize={boxSize} />;

        case 'WalletConnect':
            return <WalletConnect boxSize={boxSize} />;

        case 'Coinbase Wallet':
            return <CoinbaseWallet boxSize={boxSize} />;

        case 'Injected':
            return <WalletIcon boxSize={boxSize} />;

        case 'DDX':
            return <DDX boxSize={boxSize} />;

        case 'Etherscan':
            return <Etherscan />;

        case 'Avatar':
            return <Avatar />;

        case 'Settings':
            return <Settings boxSize={boxSize} />;

        default:
            break;
    }
}

const StyledPath = styled.path<{ color?: string }>`
    stroke: none;
    fill-rule: nonzero;
    fill-opacity: 1;
    fill: ${({ color }) => color};
`;

export const DDXIcon = (props: any) => (
    <Icon viewBox="0 0 25 25" {...props}>
        <g>
            <StyledPath
                color="currentColor"
                d="M 24.9375 12.53125 C 24.9375 19.417969 19.355469 25 12.46875 25 C 5.582031 25 0 19.417969 0 12.53125 C 0 5.644531 5.582031 0.0625 12.46875 0.0625 C 19.355469 0.0625 24.9375 5.644531 24.9375 12.53125 Z M 24.9375 12.53125 "
            />
            <StyledPath
                color="rgb(17.647059%,8.627451%,50.196078%)"
                d="M 2.292969 12.144531 C 2.292969 11.050781 2.292969 9.960938 2.292969 8.859375 C 2.332031 8.859375 2.367188 8.859375 2.398438 8.859375 C 3.417969 8.859375 4.433594 8.859375 5.453125 8.859375 C 5.621094 8.859375 5.789062 8.882812 5.953125 8.917969 C 6.027344 8.933594 6.105469 8.933594 6.175781 8.953125 C 7.1875 9.21875 7.933594 9.816406 8.386719 10.761719 C 8.511719 11.019531 8.605469 11.289062 8.640625 11.578125 C 8.664062 11.765625 8.703125 11.949219 8.695312 12.136719 C 8.621094 12.160156 2.460938 12.167969 2.292969 12.144531 Z M 2.292969 12.144531 "
            />

            <StyledPath
                color="rgb(17.647059%,8.627451%,50.196078%)"
                d="M 15.742188 12.148438 C 13.613281 12.148438 11.484375 12.148438 9.347656 12.148438 C 9.347656 11.054688 9.347656 9.964844 9.347656 8.867188 C 9.375 8.867188 9.402344 8.859375 9.429688 8.859375 C 10.464844 8.859375 11.503906 8.855469 12.542969 8.863281 C 12.882812 8.863281 13.210938 8.929688 13.53125 9.042969 C 14.429688 9.359375 15.074219 9.953125 15.46875 10.8125 C 15.578125 11.050781 15.664062 11.300781 15.699219 11.5625 C 15.726562 11.742188 15.734375 11.929688 15.753906 12.113281 C 15.753906 12.121094 15.746094 12.132812 15.742188 12.148438 Z M 15.742188 12.148438 "
            />
            <StyledPath
                color="rgb(17.647059%,8.627451%,50.196078%)"
                d="M 2.320312 12.847656 C 2.359375 12.847656 2.394531 12.84375 2.433594 12.84375 C 4.480469 12.84375 6.523438 12.84375 8.570312 12.84375 C 8.609375 12.84375 8.644531 12.84375 8.695312 12.84375 C 8.695312 12.898438 8.699219 12.945312 8.695312 12.992188 C 8.667969 13.261719 8.640625 13.535156 8.558594 13.792969 C 8.246094 14.773438 7.617188 15.46875 6.675781 15.882812 C 6.460938 15.976562 6.230469 16.046875 5.996094 16.085938 C 5.828125 16.113281 5.65625 16.132812 5.484375 16.132812 C 4.453125 16.136719 3.421875 16.132812 2.390625 16.132812 C 2.359375 16.132812 2.328125 16.132812 2.289062 16.132812 C 2.289062 16.089844 2.289062 16.058594 2.289062 16.023438 C 2.289062 14.996094 2.289062 13.964844 2.289062 12.9375 C 2.289062 12.914062 2.292969 12.890625 2.296875 12.867188 C 2.304688 12.859375 2.3125 12.855469 2.320312 12.847656 Z M 2.320312 12.847656 "
            />
            <StyledPath
                color="rgb(17.647059%,8.627451%,50.196078%)"
                d="M 15.746094 12.867188 C 15.753906 13.03125 15.746094 13.191406 15.710938 13.351562 C 15.683594 13.46875 15.671875 13.589844 15.640625 13.707031 C 15.316406 14.792969 14.632812 15.546875 13.5625 15.945312 C 13.28125 16.050781 12.988281 16.109375 12.691406 16.132812 C 12.675781 16.132812 12.660156 16.132812 12.648438 16.132812 C 11.566406 16.132812 10.488281 16.132812 9.40625 16.132812 C 9.390625 16.132812 9.378906 16.132812 9.363281 16.128906 C 9.359375 16.125 9.351562 16.117188 9.347656 16.109375 C 9.347656 16.082031 9.34375 16.054688 9.34375 16.027344 C 9.34375 15.003906 9.34375 13.976562 9.34375 12.953125 C 9.34375 12.921875 9.34375 12.890625 9.34375 12.855469 C 9.371094 12.851562 9.394531 12.847656 9.417969 12.847656 C 11.503906 12.84375 13.585938 12.84375 15.671875 12.847656 C 15.691406 12.847656 15.707031 12.847656 15.722656 12.851562 C 15.730469 12.855469 15.738281 12.863281 15.746094 12.867188 Z M 15.746094 12.867188 "
            />
            <StyledPath
                color="rgb(18.039216%,9.019608%,50.196078%)"
                d="M 15.492188 8.863281 C 16.277344 8.863281 17.035156 8.863281 17.804688 8.863281 C 19.4375 11.277344 21.074219 13.695312 22.710938 16.117188 C 22.621094 16.140625 20.640625 16.148438 20.28125 16.128906 C 18.691406 13.71875 17.097656 11.300781 15.492188 8.863281 Z M 15.492188 8.863281 "
            />
            <StyledPath
                color="rgb(93.72549%,53.333333%,66.666667%)"
                d="M 15.359375 16.125 C 16.054688 15.121094 16.742188 14.136719 17.429688 13.148438 C 17.503906 13.207031 19.367188 16.007812 19.414062 16.125 C 18.070312 16.125 16.726562 16.125 15.359375 16.125 Z M 15.359375 16.125 "
            />
            <StyledPath
                color="rgb(93.72549%,53.333333%,66.666667%)"
                d="M 22.707031 8.902344 C 22.570312 9.101562 22.433594 9.300781 22.296875 9.5 C 22.058594 9.84375 21.816406 10.1875 21.578125 10.527344 C 21.292969 10.9375 21.007812 11.347656 20.726562 11.753906 C 20.710938 11.773438 20.695312 11.789062 20.667969 11.816406 C 20.648438 11.792969 20.625 11.773438 20.609375 11.75 C 20.414062 11.464844 20.21875 11.175781 20.023438 10.890625 C 19.578125 10.242188 19.132812 9.589844 18.6875 8.941406 C 18.679688 8.929688 18.675781 8.914062 18.671875 8.902344 C 18.671875 8.886719 18.675781 8.875 18.675781 8.863281 C 18.707031 8.859375 18.738281 8.859375 18.769531 8.859375 C 20.050781 8.859375 21.332031 8.859375 22.609375 8.859375 C 22.640625 8.859375 22.671875 8.859375 22.703125 8.863281 C 22.707031 8.875 22.707031 8.886719 22.707031 8.902344 Z M 22.707031 8.902344 "
            />
            <StyledPath
                color="rgb(94.509804%,63.921569%,74.117647%)"
                d="M 0.164062 18.746094 C 0.164062 18.734375 0.160156 18.722656 0.160156 18.707031 C 0.191406 18.71875 0.179688 18.734375 0.164062 18.746094 Z M 0.164062 18.746094 "
            />
            <StyledPath
                color="rgb(22.352941%,14.117647%,52.941176%)"
                d="M 2.320312 12.847656 C 2.3125 12.855469 2.304688 12.859375 2.296875 12.867188 C 2.304688 12.859375 2.3125 12.855469 2.320312 12.847656 Z M 2.320312 12.847656 "
            />
            <StyledPath
                color="rgb(22.352941%,14.117647%,52.941176%)"
                d="M 15.746094 12.867188 C 15.738281 12.863281 15.730469 12.855469 15.722656 12.847656 C 15.730469 12.855469 15.738281 12.863281 15.746094 12.867188 Z M 15.746094 12.867188 "
            />
            <StyledPath
                color="rgb(27.45098%,19.607843%,56.078431%)"
                d="M 9.347656 16.109375 C 9.351562 16.117188 9.359375 16.125 9.363281 16.128906 C 9.359375 16.125 9.351562 16.117188 9.347656 16.109375 Z M 9.347656 16.109375 "
            />
        </g>
    </Icon>
);
