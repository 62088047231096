import { BigNumber } from '@0x/utils';
import {
    AccountContext,
    Decimal,
    Liquidity,
    LiquidityContext,
    OrderSide as OrderSideWasm,
    PositionSide as PositionSideWasm,
    Symbol,
} from '@derivadex/ddx-wasm';
import { OrderIntent, OrderSide, PositionSide, UIPosition } from '@derivadex/types';
import { getFrontendLogger } from '@derivadex/utils';

// Check for node vs browser context
const getLogger = () => {
    return typeof window === 'undefined'
        ? {
              log: console.log.bind(console),
              logError: console.error.bind(console),
          }
        : getFrontendLogger();
};

const BID = 'Bid';
const ASK = 'Ask';

export class StrategyFormulas {
    private context: any;
    private liquiditySymbols: string[] = [];
    private canExecuteOmfAndImfCalc = false;

    public updateContext(margin: BigNumber, maxLeverage: number): void {
        // If one of this changes, create new instance for account context
        // giving the properties are readonly
        this.context = new AccountContext(new Decimal(margin.toString()), maxLeverage);
    }

    public updateLiquidityContext(
        symbol: string,
        markPrice: BigNumber,
        bidSize: BigNumber,
        askSize: BigNumber,
        positionSide: PositionSide,
        balance: BigNumber,
        avgEntryPrice: BigNumber,
    ): void {
        if (isNaN(bidSize.toNumber()) || isNaN(askSize.toNumber())) {
            return;
        }
        // Update fail check
        if (bidSize.gt(0) || askSize.gt(0) || balance.gt(0)) {
            this.canExecuteOmfAndImfCalc = true;
        }
        // Calculate Position unrealized pnl
        const unrealizedPnl = this.unrealizedPnlForPositionCalc(
            { balance, avgEntryPx: avgEntryPrice, side: positionSide } as UIPosition,
            markPrice,
        );
        // Create new Liquidity context
        const newLiq = new LiquidityContext(
            new Symbol(symbol),
            new Decimal(markPrice.toString()),
            new Liquidity(
                new Decimal(bidSize.toString()),
                new Decimal(askSize.toString()),
                new PositionSideWasm(positionSide.toString()),
                new Decimal(balance.toString()),
                new Decimal(unrealizedPnl.toString()),
            ),
        );
        // If already contains liquidities
        if (this.context.countLiquidity() > 0 && this.liquiditySymbols.includes(symbol)) {
            let currentLiquidities = [];
            // Pop liquidities from context object to a local array
            let l = this.context.popLiquidity();
            while (l !== undefined) {
                currentLiquidities.push(l);
                l = this.context.popLiquidity();
            }
            // Check if context already contains liquidity for same symbol
            let replace = undefined;
            for (const liq of currentLiquidities) {
                if (liq.symbol.toString() === newLiq.symbol.toString()) {
                    replace = liq;
                }
            }
            // If so, replace it with new one
            if (replace !== undefined) {
                const symbolToFilterOut = replace.symbol.toString();
                currentLiquidities = currentLiquidities.filter((it) => it.symbol.toString() !== symbolToFilterOut);
            }
            currentLiquidities.push(newLiq);
            for (const liq of currentLiquidities) {
                this.context.pushLiquidity(liq);
            }
        } else {
            this.context.pushLiquidity(newLiq);
            this.liquiditySymbols.push(symbol);
        }
    }

    public unrealizedPnlForPositionCalc(position: UIPosition, markPrice: BigNumber): BigNumber {
        if (markPrice == undefined) {
            return new BigNumber(0);
        }
        const position_balance = new Decimal(position.balance.toString());
        const position_avg_entry_price = new Decimal(position.avgEntryPx.toString());
        const position_side =
            position.side === PositionSide.Long ? new PositionSideWasm('Long') : new PositionSideWasm('Short');
        const mark_price = new Decimal(markPrice.toString());
        const value = position_side.unrealizedPnl(position_avg_entry_price, mark_price, position_balance);
        return new BigNumber(value.toString());
    }

    public getOmfAndImfCalc(symbol: string, side: OrderSide): { omf: BigNumber; imf: BigNumber } | null {
        if (!this.canExecuteOmfAndImfCalc) {
            return null;
        }
        const results = this.context.getOmfAndImf(new Symbol(symbol), new OrderSideWasm(side.toString()));
        return { omf: new BigNumber(results.omf.toString()), imf: new BigNumber(results.imf.toString()) };
    }

    public maintenanceMarginRequirementsCalc(): BigNumber {
        const value = this.context.maintenanceMarginRequirements();
        return new BigNumber(value.toString());
    }

    public marginFractionCalc(): BigNumber {
        const value = this.context.marginFraction();
        return new BigNumber(value.toString());
    }

    public getWeightedPositionImfCalc(): BigNumber {
        const value = this.context.getWeightedPositionImf();
        return new BigNumber(value.toString());
    }

    public notionalValueCalc(): BigNumber {
        const value = this.context.notionalValue();
        return new BigNumber(value.toString());
    }

    public unrealizedPnlCalc(): BigNumber {
        const value = this.context.unrealizedPnl();
        return new BigNumber(value.toString());
    }

    /**
     *
     * margin = strategy_collateral + min(unrealized_pnl, 0) - weighted_position_imf
     **/
    public strategyMarginCalc(): BigNumber {
        const margin = new BigNumber(this.context.margin.toString());
        const weightedAllPositionsImf: BigNumber = this.getWeightedPositionImfCalc();
        let unrealizedTotal: BigNumber = this.unrealizedPnlCalc();
        if (unrealizedTotal.gt(0)) {
            unrealizedTotal = new BigNumber(0);
        }
        const value = margin.plus(unrealizedTotal).minus(weightedAllPositionsImf);
        if (value.lt(0)) return new BigNumber(0);
        return value;
    }

    /**
     * strategy_collateral + sum(unrealized_pnl)
     */
    public strategyValueCalc(): BigNumber {
        const value = this.context.totalValue();
        return new BigNumber(value.toString());
    }

    /**
     * sum of Position_notional / strategy_value
     */
    public strategyLeverageCalc(): BigNumber {
        const strategyValue = this.strategyValueCalc();
        const notionalTotal: BigNumber = this.notionalValueCalc();
        if (strategyValue.isZero() || notionalTotal.isZero()) {
            return new BigNumber(0);
        }
        const value = notionalTotal.dividedBy(strategyValue);
        return value;
    }
}

/**
 * Get my total open remaining bid quantity and ask quantity that is in the order book
 * [bid_size, ask_size] = [sum([order[0] for order in sided_order_book]) for sided_order_book in [orders_in_book['bids'], orders_in_book['asks']]]
 */
export function openBidSize(openOrders: OrderIntent[], symbol?: string): BigNumber {
    if (openOrders === undefined || openOrders.length === 0) {
        return new BigNumber(0);
    }
    const filtered = openOrders.filter((it) => (symbol ? it.symbol === symbol : true));
    const value = filtered.reduce((sumSize, order) => {
        // Only OPEN orders should be taken into account
        if (order.side === OrderSide.Bid) {
            return sumSize.plus(order.remainingAmount);
        }
        return sumSize;
    }, new BigNumber(0));
    return value;
}

export function openAskSize(openOrders: OrderIntent[], symbol?: string): BigNumber {
    if (openOrders === undefined || openOrders.length === 0) {
        return new BigNumber(0);
    }
    const filtered = openOrders.filter((it) => (symbol ? it.symbol === symbol : true));
    const value = filtered.reduce((sumSize, order) => {
        // Only OPEN orders should be taken into account
        if (order.side === OrderSide.Ask) {
            return sumSize.plus(order.remainingAmount);
        }
        return sumSize;
    }, new BigNumber(0));
    return value;
}
