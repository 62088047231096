import {
    ActionType,
    buildOrderbookSnapshot,
    buildOrderbookSnapshotFromUpdate,
    FeedEventType,
    Market,
    MarketEventType,
    MarketMessage,
    Orderbook,
    OrderBookSnapshot,
    UIDowntimeCode,
    UIDowntimeState,
} from '@derivadex/types';
import { DerivadexSocket, getErrorMessage, getFrontendLogger } from '@derivadex/utils';
import { eventChannel } from 'redux-saga';
import { waitFor } from 'store/saga';
import { getSocket } from 'store/socket/selectors';
import { ACTIVATE_STRATEGY_CALCULATIONS_DIRTY_FLAG, ACTIVATE_TRADES_HISTORY_DIRTY_FLAG } from 'store/strategy/slice';
import { SET_DOWNTIME } from 'store/ui/slice';
import { all, call, fork, putResolve, select, take, takeEvery, takeLatest } from 'typed-redux-saga/macro';
import { BOOK_BEST_PRICES_EACH_SIDE } from 'utils/constants';

import { getMarketsAsList, getOrderBookSnapshot, getSelectedMarket } from './selectors';
import {
    BOOK_UPDATE,
    CHANGE_BOOK_SUBSCRIPTION,
    CHANGE_RECENT_FILLS_SUBSCRIPTION,
    FUNDING_RATE_UPDATE,
    MARK_PRICE_UPDATE,
    OPEN_INTEREST_UPDATE,
    RECENT_FILLS_SUBSCRIBE,
    RECENT_FILLS_UPDATE,
    SET_SELECTED_MARKET,
    TICKER_UPDATE,
} from './slice';

export function* handleBookFeedUpdate(payload: { e: FeedEventType; c: Orderbook }) {
    try {
        const bookFromEvent: Orderbook = payload.c;
        const market = yield* call(waitFor<Market>, getSelectedMarket);
        if (payload.e === FeedEventType.PARTIAL) {
            const update = yield* call(buildOrderbookSnapshot, bookFromEvent, market);
            yield* putResolve(BOOK_UPDATE(update));
        } else {
            if (market && market.symbol === bookFromEvent.symbol) {
                // This is an update, so the partial already happened
                // hence the book snapshot cannot be undefined
                const currentSnapshot: OrderBookSnapshot = yield* call(
                    waitFor<OrderBookSnapshot>,
                    getOrderBookSnapshot,
                );
                const bookMerged = yield* call(
                    buildOrderbookSnapshotFromUpdate,
                    bookFromEvent,
                    currentSnapshot,
                    BOOK_BEST_PRICES_EACH_SIDE,
                    market,
                );
                yield* putResolve(BOOK_UPDATE(bookMerged));
            }
        }
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

export function* handleMarket(socket: DerivadexSocket) {
    try {
        const channel = yield* call(getMarketFeedEventChannel, socket);
        while (true) {
            const message = yield* take(channel);
            switch (message.t) {
                case MarketEventType.MARK_PRICE:
                    yield* putResolve(MARK_PRICE_UPDATE(message.c));
                    yield* putResolve(ACTIVATE_STRATEGY_CALCULATIONS_DIRTY_FLAG());
                    break;
                case MarketEventType.TICKER:
                    yield* putResolve(TICKER_UPDATE(message.c));
                    break;
                case MarketEventType.OPEN_INTEREST:
                    yield* putResolve(OPEN_INTEREST_UPDATE(message.c));
                    break;
                case MarketEventType.FUNDING:
                    yield* putResolve(FUNDING_RATE_UPDATE(message.c));
                    break;
                case MarketEventType.BOOK:
                    yield* call(handleBookFeedUpdate, { e: message.e, c: message.c });
                    break;
                case MarketEventType.FILL:
                    yield* putResolve(RECENT_FILLS_UPDATE(message.c));
                    break;
                default:
                    getFrontendLogger().logError(
                        `System malfunction: Message not supported ${JSON.stringify(message)}`,
                    );
                    break;
            }
        }
    } catch (error) {
        getFrontendLogger().logError(error);
        yield* putResolve(
            SET_DOWNTIME({ message: UIDowntimeState.PROBLEM_OCCURRED, code: UIDowntimeCode.MARKET_LOAD_FAILED }),
        );
    }
}

function* handleChangeRecentFillsSubscription(action: ReturnType<typeof CHANGE_RECENT_FILLS_SUBSCRIPTION>) {
    try {
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        yield* call(
            [socket, socket.sendMarketRecentFillsSubscriptionMessages],
            { symbol: action.payload.symbol } as Market,
            action.payload.subscribe,
        );
        yield* putResolve(RECENT_FILLS_SUBSCRIBE(action.payload.subscribe));
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

function* handleChangeBookSubscription(action: ReturnType<typeof CHANGE_BOOK_SUBSCRIPTION>) {
    try {
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        yield* call(
            [socket, socket.sendMarketBookSubscriptionMessages],
            { symbol: action.payload.symbol } as Market,
            action.payload.aggrLevel,
        );
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

export function getMarketFeedEventChannel(socket: DerivadexSocket) {
    return eventChannel<MarketMessage>((emitter) => {
        socket.onMarketUpdate((value) => emitter(value));
        return () => {
            socket.close();
        };
    });
}

export function* subscribeMarketFeed() {
    try {
        const selectedMarket = yield* call(waitFor<Market>, getSelectedMarket);
        const markets = yield* select(getMarketsAsList);
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        yield* call([socket, socket.sendMarketSubscriptionMessages], selectedMarket, markets);
        yield* call([socket, socket.sendCheckpointTransactionSubscriptionMessages]);
        // Dirty Flag reset, as trade history request is done per market symbol
        yield* putResolve(ACTIVATE_TRADES_HISTORY_DIRTY_FLAG());
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

export function* watchMarketSelected() {
    yield* takeEvery(SET_SELECTED_MARKET, subscribeMarketFeed);
}

function* watchChangeBookSubscription(): Generator {
    yield* takeLatest(ActionType.CHANGE_BOOK_SUBSCRIPTION, handleChangeBookSubscription);
}

function* watchChangeRecentFillsSubscription(): Generator {
    yield* takeLatest(ActionType.CHANGE_RECENT_FILLS_SUBSCRIPTION, handleChangeRecentFillsSubscription);
}

/**
 * The market saga layer is responsable for handling side effects when the data from the market state is affected
 */
export const marketSaga = function* root() {
    yield* all([
        fork(watchMarketSelected),
        fork(watchChangeBookSubscription),
        fork(watchChangeRecentFillsSubscription),
    ]);
};
