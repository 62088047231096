import { Box, Flex, Text, useTheme } from '@chakra-ui/react';
import { PositionsResponseItem, StatsAPIListResponse } from '@derivadex/types';
import { getFrontendLogger } from '@derivadex/utils';
import { createColumnHelper } from '@tanstack/react-table';
import CryptoIcon from 'components/CryptoIcon/CryptoIcon';
import ExplorerButton from 'components/Marketplace/OrderTables/ExplorerButton';
import Table from 'components/Table/Table';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { getMarkPrices } from 'store/market/selectors';
import { getPositionsHistoryData } from 'store/portfolio/selectors';
import { FETCH_POSITIONS_HISTORY_DATA } from 'store/portfolio/slice';
import { getSelectedStrategy } from 'store/strategy/selectors';
import { getEthAddress } from 'store/web3/selectors';
import { sortBigNumber } from 'utils/sortType';

export default function PositionsTable({ isDesktopView }: { isDesktopView: boolean }) {
    const dispatch = useDispatch();
    const currentStrategy = useSelector(getSelectedStrategy);
    const traderAddress = useSelector(getEthAddress);
    const data: StatsAPIListResponse<PositionsResponseItem> | undefined = useSelector(getPositionsHistoryData);
    const { t } = useTranslation();
    const { colors } = useTheme();
    const markPrices = useSelector(getMarkPrices);

    useEffect(() => {
        if (!currentStrategy || !traderAddress || data !== undefined) {
            return;
        }
        dispatch(FETCH_POSITIONS_HISTORY_DATA());
    }, [currentStrategy, traderAddress]);

    const columnHelper = createColumnHelper<PositionsResponseItem>();
    const columns = [
        columnHelper.accessor('symbol', {
            cell: (info) => (
                <Flex align="center">
                    <CryptoIcon currency={getCryptoIcon(info.getValue())} size={4} />
                    <Text ml="0.5rem" fontWeight="600">
                        {`${info.getValue()}`}
                    </Text>
                </Flex>
            ),
            header: t('symbol'),
            meta: {
                style: !isDesktopView
                    ? {
                          position: 'sticky',
                          left: 0,
                          zIndex: 10,
                          backgroundColor: `${colors.card[100]}`,
                      }
                    : null,
            },
        }),
        columnHelper.accessor('side', {
            cell: (info) => (
                <Text color={info.getValue() === 2 ? 'brand.red.300' : 'brand.green.300'}>
                    {info.getValue() === 2 ? 'Short' : 'Long'}
                </Text>
            ),
            header: t('side'),
        }),
        columnHelper.accessor((row) => ({ value: row.balance, symbol: row.symbol }), {
            cell: (info) => (
                <NumericFormat
                    value={info.getValue().value.toNumber()}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale
                />
            ),
            header: t('balance'),
            meta: {
                isNumeric: true,
            },
            sortingFn: sortBigNumber,
        }),
        columnHelper.accessor('avgEntryPrice', {
            cell: (info) => (
                <NumericFormat
                    value={info.getValue().toNumber()}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale
                    decimalScale={2}
                />
            ),
            header: t('avgEntryPrice'),
            meta: {
                isNumeric: true,
            },
            sortingFn: sortBigNumber,
        }),
        columnHelper.accessor((row) => markPrices[row.symbol].price, {
            cell: (info) => (
                <NumericFormat
                    value={info.getValue().toNumber()}
                    displayType={'text'}
                    thousandSeparator={true}
                    fixedDecimalScale
                    decimalScale={2}
                />
            ),
            header: t('markPrice'),
            meta: {
                isNumeric: true,
            },
            sortingFn: sortBigNumber,
        }),
        columnHelper.accessor((row) => row, {
            cell: (info) => (
                <Flex justifyContent="space-evenly">
                    <ExplorerButton
                        label={t('positionExplorerButtonDescription')}
                        url={`account/${traderAddress}/strategy/${currentStrategy?.strategy}/position/${info.row.original.symbol}`}
                    />
                </Flex>
            ),
            header: ' ',
        }),
    ];

    function getCryptoIcon(symbol: string | undefined) {
        if (symbol === 'BTCP') return 'BTC';
        else return 'ETH';
    }

    return (
        <Box
            p="0.5rem"
            className={data && data.success && data.value.length > 0 ? 'table-positions' : 'table-positions-empty'}
        >
            <Table
                isDesktopView={isDesktopView}
                columns={columns}
                data={data && data.success && data.value.length > 0 ? data.value : []}
                emptyText={t('noData')}
                sortBy={[{ id: 'symbol', desc: true }]}
            />
        </Box>
    );
}
