import { useTheme } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

// https://github.com/chakra-ui/chakra-ui/issues/3580
function useBetterMediaQuery(mediaQueryString: string) {
    const mediaQueryList = matchMedia(mediaQueryString);
    const [matches, setMatches] = useState<boolean>(mediaQueryList.matches);

    useEffect(() => {
        const listener = () => setMatches(!!mediaQueryList.matches);
        listener();
        mediaQueryList.addEventListener('change', listener);
        return () => mediaQueryList.removeEventListener('change', listener);
    }, [mediaQueryString, mediaQueryList]);
    return matches;
}

export function useScreenSize(): boolean {
    const theme = useTheme();
    const isDesktop = useBetterMediaQuery(`(min-width: ${theme.breakpoints.md})`);
    return !!isDesktop;
}
