import { BigNumber } from '@0x/utils';
import { PositionEntity, PositionSide, UIPosition } from '@derivadex/types';

export function getUIPositions(rawPositions: PositionEntity[]): UIPosition[] {
    return rawPositions.map((rawPosition) => getUIPosition(rawPosition));
}

export function getUIPosition(rawPosition: PositionEntity): UIPosition {
    return {
        symbol: rawPosition.symbol,
        side: rawPosition.side as PositionSide,
        balance: rawPosition.balance,
        avgEntryPx: rawPosition.avgPrice,
        liquidationPrice: new BigNumber(0),
        unrealizedPnl: new BigNumber(0),
    };
}
