import { BigNumber } from '@0x/utils';
import {
    ActionType,
    CheckpointedStrategy,
    CheckpointedTrader,
    CheckpointSubmission,
    Context,
    EnrollmentStatus,
    MerkleProofAPIResponse,
    OperatorConfig,
    RequestType,
    ResponseStatus,
    TokenBalance,
    TxStrategyUpdate,
    TxTraderUpdate,
    UIDowntimeCode,
    UIDowntimeState,
    UIDowntimeSubcode,
    UIStrategy,
    UIToastActionID,
    UIToastDuration,
    UIToastStatus,
    UIToastTitle,
} from '@derivadex/types';
import { DerivadexSocket, encodeStringIntoBytes32, getErrorMessage, getFrontendLogger } from '@derivadex/utils';
import { initialRuntimeConfig } from 'config/runtimeConfig';
import {
    CheckpointContractAbi,
    CollateralContractAbi,
    CollateralKYCContractAbi,
    CustodianContractAbi,
    ERC20ContractAbi,
    StakeContractAbi,
    StakeKYCContractAbi,
} from 'contract-artifacts/abis';
import i18n from 'i18next';
import { END, EventChannel, eventChannel } from 'redux-saga';
import { getKycConfig } from 'store/config/selectors';
import { getEnrollmentStatus, getProfileDetails } from 'store/profile/selectors';
import { waitFor, waitUntilTrue } from 'store/saga';
import { getSocket } from 'store/socket/selectors';
import { getSelectedStrategy } from 'store/strategy/selectors';
import {
    getLatestStrategyDeposit,
    getLatestStrategyWithdraw,
    getLatestTraderDeposit,
    getLatestTraderWithdraw,
} from 'store/transactions/selectors';
import { getDepositDdxUIState, getDepositUsdcUIState } from 'store/ui/selectors';
import {
    ADD_TOAST_MESSAGE,
    DepositDdxUIState,
    DepositUsdcUIState,
    SET_DEPOSIT_DDX_UI_STATE,
    SET_DEPOSIT_USDC_UI_STATE,
    SET_DOWNTIME,
    SET_SUBMIT_CHECKPOINT_UI_STATE,
    SET_TOGGLE_DDX_UI_STATE,
    SET_TOGGLE_USDC_UI_STATE,
    SET_WITHDRAW_DDX_UI_STATE,
    SET_WITHDRAW_USDC_UI_STATE,
    SubmitCheckpointUIState,
    TOGGLE_DEPOSIT_COLLATERALS_DIALOG,
    ToggleDdxUIState,
    ToggleUsdcUIState,
    WithdrawDdxUIState,
    WithdrawUsdcUIState,
} from 'store/ui/slice';
import { all, call, delay, fork, putResolve, select, take, takeLatest } from 'typed-redux-saga/macro';
import { buildCheckpointSubmission } from 'utils/checkpoint';
import { DEPOSIT_DIALOG_BALANCE_UPDATE_INTERVAL, ZERO_ADDRESS } from 'utils/constants';
import { tokenAmountInUnitsToBigNumber } from 'utils/tokens';
import { calculateWithdrawState } from 'utils/web3';
import { v4 as uuidv4 } from 'uuid';

import {
    callApproveToken,
    deposit,
    depositDDX,
    fetchBlockNumber,
    getAddress,
    getCheckpointInfo,
    getCollateralTokens,
    getGuardedDepositInfo,
    getLatestCheckpointFromContract,
    getMaximumWithdrawal,
    getProcessedDDXWithdrawals,
    getProcessedWithdrawals,
    getUnprocessedDDXWithdrawals,
    getUnprocessedWithdrawals,
    getValidSignersCount,
    readTokenInfo,
    submitMajorityCheckpoint,
    subscribeContractCheckpoints,
    updateTokenBalance,
    withdraw,
    withdrawDDX,
} from '../../utils/web3_api';
import {
    getCheckpointedStrategy,
    getCheckpointedTrader,
    getCurrentBlockNumber,
    getDDXCollateral,
    getEthAddress,
    getLatestContractCheckpoint,
    getStrategyProof,
    getTraderProof,
    getUSDCCollateral,
    getWeb3Context,
    getWithdrawDDXState,
    getWithdrawState,
    isEthAddressConnected,
    isWeb3Connected,
} from './selectors';
import {
    APPROVE_TOKEN,
    COMPLETE_DDX_WITHDRAW,
    COMPLETE_WITHDRAW,
    CONNECT_WEB3,
    DISCONNECT_WEB3,
    MERKLE_PROOF_API,
    SET_BLOCK_NUMBER,
    SET_CHECKPOINTED_STRATEGY,
    SET_CHECKPOINTED_TRADER,
    SET_DDX_COLLATERAL,
    SET_GUARDED_DEPOSIT_INFO,
    SET_LATEST_CONTRACT_CHECKPOINT,
    SET_PROOF,
    SET_USDC_COLLATERAL,
    SET_WITHDRAW_DDX_STATE,
    SET_WITHDRAW_STATE,
    SUBMIT_CHECKPOINT,
    SUBMIT_DEPOSIT,
    WITHDRAW_DDX_STATE_UPDATE_TRIGGER,
    WITHDRAW_STATE_UPDATE_TRIGGER,
} from './slice';
import { areDepositsInProgress } from './utils';

export function* fetchTokenApproval(action: ReturnType<typeof APPROVE_TOKEN>) {
    const { toggleTokenAction } = action.payload;
    yield* putResolve(
        toggleTokenAction.isDDX
            ? SET_TOGGLE_DDX_UI_STATE(ToggleDdxUIState.PENDING_WALLET_CONFIRMATION)
            : SET_TOGGLE_USDC_UI_STATE(ToggleUsdcUIState.PENDING_WALLET_CONFIRMATION),
    );
    try {
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const token = toggleTokenAction.isDDX
            ? yield* call(waitFor<TokenBalance>, getDDXCollateral)
            : yield* call(waitFor<TokenBalance>, getUSDCCollateral);
        const isUnlocked = yield* call(
            callApproveToken,
            getAddress(token.token.address),
            toggleTokenAction.approveAmount,
            token.token.decimals,
            ERC20ContractAbi,
            context.contractAddresses.derivaDEXAddress,
        );
        if (toggleTokenAction.isDDX) {
            // yield* putResolve(SET_DDX_COLLATERAL({ ...token, isUnlocked }));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    status: UIToastStatus.Successful,
                    title: i18n.t('ddxTokenApproved'),
                    description: i18n.t('youMayNowPlaceADeposit'),
                    duration: UIToastDuration.Default,
                    actionID: UIToastActionID.ApproveDDX,
                    id: uuidv4(),
                    data: undefined,
                }),
            );
        } else {
            // yield* putResolve(SET_USDC_COLLATERAL({ ...token, isUnlocked }));
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    status: UIToastStatus.Successful,
                    title: i18n.t('tokenApproved'),
                    description: i18n.t('youMayNowPlaceADeposit'),
                    duration: UIToastDuration.Default,
                    actionID: UIToastActionID.ApproveToken,
                    id: uuidv4(),
                    data: undefined,
                }),
            );
        }
        yield* call(fetchCollateralTokens);
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
        if (toggleTokenAction.isDDX) {
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    status: UIToastStatus.Error,
                    title: i18n.t('ddxTokenApprovalUnsuccessful'),
                    description: error.message,
                    duration: UIToastDuration.Default,
                    actionID: UIToastActionID.ApproveDDX,
                    id: uuidv4(),
                    data: undefined,
                }),
            );
        } else {
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    status: UIToastStatus.Error,
                    title: i18n.t('tokenApprovalUnsuccessful'),
                    description: error.message,
                    duration: UIToastDuration.Default,
                    actionID: UIToastActionID.ApproveToken,
                    id: uuidv4(),
                    data: undefined,
                }),
            );
        }
    } finally {
        yield* putResolve(
            toggleTokenAction.isDDX
                ? SET_TOGGLE_DDX_UI_STATE(ToggleDdxUIState.NONE)
                : SET_TOGGLE_USDC_UI_STATE(ToggleUsdcUIState.NONE),
        );
    }
}

export function* watchForApproveTokenAction() {
    yield* takeLatest(APPROVE_TOKEN, fetchTokenApproval);
}

export function* fetchCollateralTokens() {
    try {
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const ethAddress = yield* call(waitFor<string>, getEthAddress);
        const usdcAddress = getAddress(context.contractAddresses.usdcAddress);
        const ddxAddress = getAddress(context.contractAddresses.ddxAddress);
        const derivadexAddress = getAddress(context.contractAddresses.derivaDEXAddress);
        const tokensMeta = context.tokens;
        const collaterals = yield* call(
            getCollateralTokens,
            usdcAddress,
            ddxAddress,
            derivadexAddress,
            getAddress(ethAddress),
            tokensMeta,
        );
        yield* putResolve(SET_USDC_COLLATERAL(collaterals.usdcToken));
        yield* putResolve(SET_DDX_COLLATERAL(collaterals.ddxToken));
    } catch (error) {
        getFrontendLogger().logError(error);
        yield* putResolve(
            SET_DOWNTIME({
                message: UIDowntimeState.PROBLEM_OCCURRED,
                code: UIDowntimeCode.WEB3_COLLATERALS_FAILED,
                subcode: UIDowntimeSubcode.FETCH_COLLATERAL_TOKENS,
            }),
        );
    }
}

// Reset checkpointed strategy & trader each time the wallet connects
export function* resetCheckpointedData() {
    try {
        yield* putResolve(
            SET_CHECKPOINTED_STRATEGY({
                availCollateral: new BigNumber(0),
                lockedCollateral: new BigNumber(0),
            }),
        );
        yield* putResolve(
            SET_CHECKPOINTED_TRADER({
                availDDX: new BigNumber(0),
                lockedDDX: new BigNumber(0),
                payFeesInDDX: false,
            }),
        );
    } catch (error) {
        getFrontendLogger().logError(error);
        yield* putResolve(
            SET_DOWNTIME({ message: UIDowntimeState.PROBLEM_OCCURRED, code: UIDowntimeCode.SET_CHECKPOINTED_DATA }),
        );
    }
}

/**
 * Called each time the user connects to a wallet
 */
export function* fetchInitialWeb3Data() {
    try {
        const context = yield* call(waitFor<Context>, getWeb3Context);
        yield* call(waitUntilTrue, isEthAddressConnected);
        const guardedDepositInfo = yield* call(
            getGuardedDepositInfo,
            getAddress(context.contractAddresses.derivaDEXAddress),
            CollateralContractAbi,
        );
        yield* putResolve(SET_GUARDED_DEPOSIT_INFO(guardedDepositInfo));
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
        yield* putResolve(
            SET_DOWNTIME({ message: UIDowntimeState.PROBLEM_OCCURRED, code: UIDowntimeCode.INIT_WEB3_FAILED }),
        );
    }
}

export function* connectWeb3() {
    yield* fork(resetCheckpointedData);
    yield* fork(fetchCollateralTokens);
    yield* fork(subscribeContractCheckpointsOnceWeb3Connected);
    yield* fork(watchBlockNumber);
}

export function* handleDeposit(action: ReturnType<typeof SUBMIT_DEPOSIT>) {
    const enrollmentStatus = yield* select(getEnrollmentStatus);
    if (initialRuntimeConfig.ENABLE_KYC_ENROLLMENT === 'true' && enrollmentStatus !== EnrollmentStatus.APPROVED) {
        getFrontendLogger().logError(`handleDeposit found Enrollment Status: ${enrollmentStatus} instead of APPROVED`);
        return;
    }
    const data = action.payload;
    yield* putResolve(
        data.isDDX
            ? SET_DEPOSIT_DDX_UI_STATE(DepositDdxUIState.PENDING_WALLET_CONFIRMATION)
            : SET_DEPOSIT_USDC_UI_STATE(DepositUsdcUIState.PENDING_WALLET_CONFIRMATION),
    );
    const toastActionID = action.payload.isDDX ? UIToastActionID.DepositDDX : UIToastActionID.DepositCollateral;
    try {
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                status: UIToastStatus.Pending,
                title: i18n.t('confirmTransaction'),
                description: i18n.t('waitingForWalletConfirmation'),
                actionID: toastActionID,
            }),
        );
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const ethAddress = yield* call(waitFor<string>, getEthAddress);
        const kycConfig = yield* call(waitFor<OperatorConfig>, getKycConfig);
        let depositResult: { txHash: string; blockNumber: number };
        if (data.isDDX) {
            const ddxCollateral = yield* call(waitFor<TokenBalance>, getDDXCollateral);
            const ddxTokenInfo = yield* call(
                readTokenInfo,
                getAddress(ethAddress),
                getAddress(context.contractAddresses.ddxAddress),
                ERC20ContractAbi,
                getAddress(context.contractAddresses.derivaDEXAddress),
                ddxCollateral.token,
            );
            if (ddxTokenInfo.isUnlocked === false || ddxTokenInfo.allowanceAmount.isLessThan(data.amount)) {
                yield* call(
                    fetchTokenApproval,
                    APPROVE_TOKEN({
                        toggleTokenAction: {
                            token: ddxTokenInfo.token,
                            isDDX: true,
                            isUnlocked: false,
                            approveAmount: data.amount,
                        },
                    }),
                );
            }
            depositResult = yield* call(
                depositDDX,
                getAddress(context.contractAddresses.ddxAddress),
                ERC20ContractAbi,
                getAddress(context.contractAddresses.derivaDEXAddress),
                StakeKYCContractAbi,
                getAddress(ethAddress),
                data.amount.toString(),
                kycConfig,
                context.chainId,
                context.deployment,
                context.contractAddresses.derivaDEXAddress,
            );
            yield* putResolve(SET_DEPOSIT_DDX_UI_STATE(DepositDdxUIState.PENDING_TRANSACTION_CONFIRMATIONS));
            yield* putResolve(TOGGLE_DEPOSIT_COLLATERALS_DIALOG());
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    status: UIToastStatus.Deposit,
                    title: i18n.t('transactionSuccessful'),
                    description: i18n.t('waitingForConfirmation'),
                    duration: UIToastDuration.Indefinite,
                    actionID: UIToastActionID.DepositDDX,
                    txHash: depositResult.txHash,
                    chainID: context.chainId,
                }),
            );
        } else {
            const usdcCollateral = yield* call(waitFor<TokenBalance>, getUSDCCollateral);
            const usdcTokenInfo = yield* call(
                readTokenInfo,
                getAddress(ethAddress),
                getAddress(context.contractAddresses.usdcAddress),
                ERC20ContractAbi,
                getAddress(context.contractAddresses.derivaDEXAddress),
                usdcCollateral.token,
            );
            if (usdcTokenInfo.isUnlocked === false || usdcTokenInfo.allowanceAmount.isLessThan(data.amount)) {
                yield* call(
                    fetchTokenApproval,
                    APPROVE_TOKEN({
                        toggleTokenAction: {
                            token: usdcCollateral.token,
                            isDDX: false,
                            isUnlocked: false,
                            approveAmount: data.amount,
                        },
                    }),
                );
            }
            depositResult = yield* call(
                deposit,
                getAddress(context.contractAddresses.usdcAddress),
                ERC20ContractAbi,
                getAddress(context.contractAddresses.derivaDEXAddress),
                CollateralKYCContractAbi,
                getAddress(ethAddress),
                data.strategy,
                data.amount.toString(),
                kycConfig,
                context.chainId,
                context.deployment,
                context.contractAddresses.derivaDEXAddress,
            );
            yield* putResolve(SET_DEPOSIT_USDC_UI_STATE(DepositUsdcUIState.PENDING_TRANSACTION_CONFIRMATIONS));
            yield* putResolve(TOGGLE_DEPOSIT_COLLATERALS_DIALOG());
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    status: UIToastStatus.Deposit,
                    title: i18n.t('transactionSuccessful'),
                    description: i18n.t('waitingForConfirmation'),
                    duration: UIToastDuration.Indefinite,
                    actionID: UIToastActionID.DepositCollateral,
                    txHash: depositResult.txHash,
                    chainID: context.chainId,
                }),
            );
        }
        //toast.close(toastConfirmId);
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
        yield* putResolve(
            data.isDDX
                ? SET_DEPOSIT_DDX_UI_STATE(DepositDdxUIState.NONE)
                : SET_DEPOSIT_USDC_UI_STATE(DepositUsdcUIState.NONE),
        );
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                title: UIToastTitle.Deposit,
                status: UIToastStatus.Failed,
                description:
                    error && error.data && error.data.message
                        ? error.data.message.replace('execution reverted: ', '')
                        : 'Unexpected',
                actionID: toastActionID,
            }),
        );
    }
}

export function* watchForDeposit() {
    yield* takeLatest(SUBMIT_DEPOSIT, handleDeposit);
}

function* onSubmitCheckpointRequest(action: ReturnType<typeof SUBMIT_CHECKPOINT>): Generator {
    const location = `${document.location.protocol}//${document.location.host}/v2/rest`;
    const rest_url = initialRuntimeConfig.REST_API_URL || location;
    const res = yield* call(fetch, `${rest_url}/checkpoints`);
    const data = yield* call([res, res.json]);
    const checkpointSubmission = buildCheckpointSubmission(data.checkpoints);

    yield* putResolve(SET_SUBMIT_CHECKPOINT_UI_STATE(SubmitCheckpointUIState.NONE));
    try {
        if (checkpointSubmission === undefined) {
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.Submit_Checkpoint,
                    status: UIToastStatus.Failed,
                    description: i18n.t('submitCheckpointEpochValidationFailed'),
                    actionID: UIToastActionID.SubmitCheckpoint,
                }),
            );
        }
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                status: UIToastStatus.Pending,
                title: i18n.t('confirmTransaction'),
                description: i18n.t('waitingForWalletConfirmation'),
                actionID: UIToastActionID.SubmitCheckpoint,
            }),
        );
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const [consensusThreshold, quorum] = yield* call(
            getCheckpointInfo,
            getAddress(context.contractAddresses.derivaDEXAddress),
            CheckpointContractAbi,
        );
        const validSignersCount = yield* call(
            getValidSignersCount,
            getAddress(context.contractAddresses.derivaDEXAddress),
            CustodianContractAbi,
        );
        let validSignerEpochCount = 0;
        checkpointSubmission?.signerEpochs.map((epoch) => {
            if (epoch === action.payload.epochId.toNumber()) {
                validSignerEpochCount++;
            }
        });
        const doesSubmissionMeetConsensusThreshold = new BigNumber(validSignerEpochCount)
            .dividedBy(validSignersCount)
            .isGreaterThanOrEqualTo(consensusThreshold.dividedBy(100));
        if (!doesSubmissionMeetConsensusThreshold || validSignerEpochCount < quorum.toNumber()) {
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.Submit_Checkpoint,
                    status: UIToastStatus.Failed,
                    description: i18n.t('submitCheckpointFailureSignerEpochs'),
                    actionID: UIToastActionID.SubmitCheckpoint,
                }),
            );
        }
        const receipt = yield* call(
            submitMajorityCheckpoint,
            getAddress(context.contractAddresses.derivaDEXAddress),
            CheckpointContractAbi,
            checkpointSubmission as CheckpointSubmission,
            action.payload.epochId,
        );
        if (receipt) {
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.Submit_Checkpoint,
                    status: UIToastStatus.Successful,
                    description: i18n.t('submitCheckpointSuccess'),
                    actionID: UIToastActionID.SubmitCheckpoint,
                    txHash: receipt.txHash,
                }),
            );
        } else {
            yield* putResolve(
                ADD_TOAST_MESSAGE({
                    title: UIToastTitle.Submit_Checkpoint,
                    status: UIToastStatus.Failed,
                    description: i18n.t('submitCheckpointRejected'),
                    actionID: UIToastActionID.SubmitCheckpoint,
                }),
            );
        }
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                title: UIToastTitle.Submit_Checkpoint,
                status: UIToastStatus.Failed,
                description: error.message.includes('Epoch ID must monotonically increase.')
                    ? i18n.t('submitCheckpointFailureAnotherUser')
                    : i18n.t('submitCheckpointFailure'),
                actionID: UIToastActionID.SubmitCheckpoint,
            }),
        );
    }
}

export function* watchSubmitCheckpointRequest() {
    yield* takeLatest(SUBMIT_CHECKPOINT, onSubmitCheckpointRequest);
}

export function* handleUpdateWithdrawState() {
    try {
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const traderAddress = yield* call(waitFor<string>, getEthAddress);
        const strategy = yield* call(waitFor<UIStrategy>, getSelectedStrategy);
        const checkpointedStrategy = yield* call(waitFor<CheckpointedStrategy>, getCheckpointedStrategy);
        const strategyId = yield* call(encodeStringIntoBytes32, strategy.strategy);
        const strategyDeposit = yield* call(waitFor<TxStrategyUpdate>, getLatestStrategyDeposit);
        const strategyWithdraw = yield* call(waitFor<TxStrategyUpdate>, getLatestStrategyWithdraw);
        // If there are no checkpoints, no need to proceed
        const latestCheckpoint = yield* select(getLatestContractCheckpoint);
        if (latestCheckpoint === undefined || latestCheckpoint === 0 || strategy.lockedCollateral.isZero()) {
            return;
        }
        const tokenAddress = context.contractAddresses.usdcAddress;
        const maximumWithdrawal = yield* call(
            getMaximumWithdrawal,
            getAddress(context.contractAddresses.derivaDEXAddress),
            CollateralContractAbi,
            getAddress(tokenAddress),
        );
        getFrontendLogger().log('Update Withdraw USDC Maximum', JSON.stringify(maximumWithdrawal));
        const unprocessedWithdrawals = yield* call(
            getUnprocessedWithdrawals,
            getAddress(context.contractAddresses.derivaDEXAddress),
            CollateralContractAbi,
            strategyId,
            getAddress(traderAddress),
            getAddress(tokenAddress),
        );
        getFrontendLogger().log('Update Withdraw USDC Uprocessed', JSON.stringify(unprocessedWithdrawals));
        const processedWithdrawals = yield* call(
            getProcessedWithdrawals,
            getAddress(context.contractAddresses.derivaDEXAddress),
            CollateralContractAbi,
            strategyId,
            getAddress(traderAddress),
            getAddress(tokenAddress),
            BigNumber.max(
                strategyDeposit && strategyDeposit.blockNumber.gt(0) ? strategyDeposit.blockNumber : new BigNumber(0),
                strategyWithdraw && strategyWithdraw.blockNumber.gt(0)
                    ? strategyWithdraw.blockNumber
                    : new BigNumber(0),
            ),
        );
        getFrontendLogger().log('Update Withdraw USDC Processed', JSON.stringify(processedWithdrawals));
        const withdrawState = yield* call(
            calculateWithdrawState,
            strategy.lockedCollateral,
            checkpointedStrategy.lockedCollateral,
            unprocessedWithdrawals,
            processedWithdrawals,
            tokenAmountInUnitsToBigNumber(maximumWithdrawal.maximumWithdrawalAmount, 6),
        );
        getFrontendLogger().log('Update Withdraw USDC Calculations', JSON.stringify(withdrawState));
        yield* putResolve(SET_WITHDRAW_STATE(withdrawState));
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

export function* handleUpdateWithdrawDDXState() {
    try {
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const traderAddress = yield* call(waitFor<string>, getEthAddress);
        const profileDetails = yield* select(getProfileDetails);
        const checkpointedTrader = yield* call(waitFor<CheckpointedTrader>, getCheckpointedTrader);
        const traderDeposit = yield* call(waitFor<TxTraderUpdate>, getLatestTraderDeposit);
        const traderWithdraw = yield* call(waitFor<TxTraderUpdate>, getLatestTraderWithdraw);
        // If there are no checkpoints, no need to proceed
        const latestCheckpoint = yield* select(getLatestContractCheckpoint);
        if (latestCheckpoint === undefined || latestCheckpoint === 0 || profileDetails.lockedDDX.isZero()) {
            return;
        }
        const maximumWithdrawal = yield* call(
            getMaximumWithdrawal,
            getAddress(context.contractAddresses.derivaDEXAddress),
            CollateralContractAbi,
            getAddress(ZERO_ADDRESS),
        );
        getFrontendLogger().log('Update Withdraw DDX Maximum', JSON.stringify(maximumWithdrawal));
        const unprocessedWithdrawals = yield* call(
            getUnprocessedDDXWithdrawals,
            getAddress(context.contractAddresses.derivaDEXAddress),
            StakeContractAbi,
            getAddress(traderAddress),
        );
        getFrontendLogger().log('Update Withdraw DDX Uprocessed', JSON.stringify(unprocessedWithdrawals));
        const processedWithdrawals = yield* call(
            getProcessedDDXWithdrawals,
            getAddress(context.contractAddresses.derivaDEXAddress),
            StakeContractAbi,
            getAddress(traderAddress),
            BigNumber.max(
                traderDeposit && traderDeposit.blockNumber.gt(0) ? traderDeposit.blockNumber : new BigNumber(0),
                traderWithdraw && traderWithdraw.blockNumber.gt(0) ? traderWithdraw.blockNumber : new BigNumber(0),
            ),
        );
        getFrontendLogger().log('Update Withdraw DDX Processed', JSON.stringify(processedWithdrawals));
        const withdrawState = yield* call(
            calculateWithdrawState,
            profileDetails.lockedDDX,
            checkpointedTrader.lockedDDX,
            unprocessedWithdrawals,
            processedWithdrawals,
            tokenAmountInUnitsToBigNumber(maximumWithdrawal.maximumWithdrawalAmount, 18),
        );
        getFrontendLogger().log('Update Withdraw DDX Calculations', JSON.stringify(withdrawState));
        yield* putResolve(SET_WITHDRAW_DDX_STATE(withdrawState));
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

function* getMerkleProofRequest(action: ReturnType<typeof MERKLE_PROOF_API.request>) {
    try {
        const socket = yield* call(waitFor<DerivadexSocket>, getSocket);
        const response = yield* call([socket, socket.requestMerkleProof], {
            t: RequestType.MERKLE,
            c: action.payload,
        });
        getFrontendLogger().log('merkle proof', response);
        if (response.e === ResponseStatus.SUCCESS) {
            yield* putResolve(MERKLE_PROOF_API.success(response.c as MerkleProofAPIResponse));
        } else {
            yield* putResolve(MERKLE_PROOF_API.failure(new Error('unexpected merkle proof response.')));
        }
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
        yield* putResolve(MERKLE_PROOF_API.failure(error));
    }
}

function* processMerkleProofAPIResponse(action: ReturnType<typeof MERKLE_PROOF_API.success>) {
    try {
        const context = yield* call(waitFor<Context>, getWeb3Context);
        getFrontendLogger().log('merkle proof success', context);
        const {
            strategyProof,
            traderProof,
            checkpointedStrategy: { availCollateral, lockedCollateral },
            checkpointedTrader: { availDDX, lockedDDX, payFeesInDDX },
        } = action.payload;
        const collateralAddress = context.contractAddresses.usdcAddress.toLowerCase();
        const marginAmount = availCollateral[collateralAddress]
            ? new BigNumber(availCollateral[collateralAddress])
            : new BigNumber(0);
        const lockedCollateralAmount = lockedCollateral[collateralAddress]
            ? new BigNumber(lockedCollateral[collateralAddress])
            : new BigNumber(0);
        yield* putResolve(SET_PROOF({ strategyProof: strategyProof, traderProof: traderProof }));
        yield* putResolve(
            SET_CHECKPOINTED_STRATEGY({
                availCollateral: marginAmount,
                lockedCollateral: lockedCollateralAmount,
            }),
        );
        yield* putResolve(
            SET_CHECKPOINTED_TRADER({
                availDDX: new BigNumber(availDDX),
                lockedDDX: new BigNumber(lockedDDX),
                payFeesInDDX: payFeesInDDX,
            }),
        );
        yield* putResolve(WITHDRAW_STATE_UPDATE_TRIGGER());
        yield* putResolve(WITHDRAW_DDX_STATE_UPDATE_TRIGGER());
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in merkle proof success action', action.payload);
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

function* processMerkleProofAPIFailure(action: ReturnType<typeof MERKLE_PROOF_API.failure>) {
    try {
        // We need to update the withdraw state in the case that the merkle proof request fails
        // which can happen if the first checkpoint with the strategy hasn't happened yet
        yield* putResolve(WITHDRAW_STATE_UPDATE_TRIGGER());
        yield* putResolve(WITHDRAW_DDX_STATE_UPDATE_TRIGGER());
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in merkle proof failure action', action);
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

function* completeWithdrawAction() {
    yield* putResolve(SET_WITHDRAW_USDC_UI_STATE(WithdrawUsdcUIState.PENDING_CONFIRMATION));
    yield* putResolve(
        ADD_TOAST_MESSAGE({
            status: UIToastStatus.Pending,
            title: i18n.t('confirmTransaction'),
            description: i18n.t('waitingForWalletConfirmation'),
            actionID: UIToastActionID.CompleteWithdraw,
        }),
    );

    try {
        const { allowedAmount } = yield* select(getWithdrawState);
        const merkleProof = yield* call(waitFor<string>, getStrategyProof);
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const currentStrategy = yield* call(waitFor<UIStrategy>, getSelectedStrategy);
        const checkpointedStrategy = yield* call(waitFor<CheckpointedStrategy>, getCheckpointedStrategy);
        const withdrawResult: { txHash: string; blockNumber: number } = yield* call(
            withdraw,
            getAddress(context.contractAddresses.usdcAddress),
            ERC20ContractAbi,
            getAddress(context.contractAddresses.derivaDEXAddress),
            CollateralContractAbi,
            currentStrategy,
            checkpointedStrategy,
            allowedAmount,
            merkleProof,
        );
        yield* putResolve(WITHDRAW_STATE_UPDATE_TRIGGER());
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                title: UIToastTitle.Withdraw,
                status: UIToastStatus.Successful,
                description: i18n.t('withdrawCompleteSuccess'),
                actionID: UIToastActionID.CompleteWithdraw,
                txHash: withdrawResult.txHash,
                chainID: context.chainId,
            }),
        );
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                title: UIToastTitle.Withdraw,
                status: UIToastStatus.Failed,
                description: i18n.t('withdrawCompleteFailure'),
                actionID: UIToastActionID.CompleteWithdraw,
            }),
        );
    } finally {
        yield* call(fetchCollateralTokens);
        yield* putResolve(SET_WITHDRAW_USDC_UI_STATE(WithdrawUsdcUIState.NONE));
    }
}

function* completeWithdrawDDXAction() {
    yield* putResolve(SET_WITHDRAW_DDX_UI_STATE(WithdrawDdxUIState.PENDING_CONFIRMATION));
    yield* putResolve(
        ADD_TOAST_MESSAGE({
            status: UIToastStatus.Pending,
            title: i18n.t('confirmTransaction'),
            description: i18n.t('waitingForWalletConfirmation'),
            actionID: UIToastActionID.CompleteWithdrawDDX,
        }),
    );

    try {
        const { allowedAmount } = yield* select(getWithdrawDDXState);
        const merkleProof = yield* call(waitFor<string>, getTraderProof);
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const checkpointedTrader = yield* call(waitFor<CheckpointedTrader>, getCheckpointedTrader);
        const withdrawResult: { txHash: string; blockNumber: number } = yield* call(
            withdrawDDX,
            getAddress(context.contractAddresses.derivaDEXAddress),
            StakeContractAbi,
            allowedAmount,
            merkleProof,
            checkpointedTrader,
        );
        yield* putResolve(WITHDRAW_DDX_STATE_UPDATE_TRIGGER());

        yield* putResolve(
            ADD_TOAST_MESSAGE({
                title: UIToastTitle.Withdraw_DDX,
                status: UIToastStatus.Successful,
                description: i18n.t('withdrawDDXCompleteSuccess'),
                actionID: UIToastActionID.CompleteWithdrawDDX,
                txHash: withdrawResult.txHash,
                chainID: context.chainId,
            }),
        );

        yield* call(fetchCollateralTokens);
    } catch (error: any) {
        getFrontendLogger().logError(getErrorMessage(error));
        yield* putResolve(
            ADD_TOAST_MESSAGE({
                title: UIToastTitle.Withdraw_DDX,
                status: UIToastStatus.Failed,
                description: i18n.t('withdrawDDXCompleteFailure'),
                actionID: UIToastActionID.CompleteWithdrawDDX,
            }),
        );
    } finally {
        yield* putResolve(SET_WITHDRAW_DDX_UI_STATE(WithdrawDdxUIState.NONE));
    }
}

function* checkpointAction(action: ReturnType<typeof SET_LATEST_CONTRACT_CHECKPOINT>) {
    try {
        const checkpoint = action.payload;
        const traderAddress = yield* call(waitFor<string>, getEthAddress);
        const strategy = yield* call(waitFor<UIStrategy>, getSelectedStrategy);
        // New strategy not in state yet, skipping merkle proof request
        if (strategy.isNew) {
            return;
        }
        const strategyId = encodeStringIntoBytes32(strategy.strategy);
        getFrontendLogger().log(`checkpoint ${checkpoint}`);
        yield* putResolve(
            MERKLE_PROOF_API.request({
                traderAddress: traderAddress,
                strategy: strategyId,
                epochId: checkpoint,
            }),
        );
    } catch (error: any) {
        getFrontendLogger().logError('caught exception in checkpoint action', action.payload);
        getFrontendLogger().logError(getErrorMessage(error));
    }
}

export function* watchUpdateWithdrawState(): Generator {
    yield* takeLatest(WITHDRAW_STATE_UPDATE_TRIGGER, handleUpdateWithdrawState);
}

export function* watchUpdateWithdrawDDXState(): Generator {
    yield* takeLatest(WITHDRAW_DDX_STATE_UPDATE_TRIGGER, handleUpdateWithdrawDDXState);
}

function* watchLatestContractCheckpoint(): Generator {
    yield* takeLatest(SET_LATEST_CONTRACT_CHECKPOINT, checkpointAction);
}

function* watchMerkleProofRequest(): Generator {
    yield* takeLatest(MERKLE_PROOF_API.request, getMerkleProofRequest);
}

export function* watchMerkleProofSuccess(): Generator {
    yield* takeLatest(MERKLE_PROOF_API.success, processMerkleProofAPIResponse);
}

function* watchMerkleProofFailure(): Generator {
    yield* takeLatest(MERKLE_PROOF_API.failure, processMerkleProofAPIFailure);
}

export function* watchCompleteWithdraw(): Generator {
    yield takeLatest(COMPLETE_WITHDRAW, completeWithdrawAction);
}

export function* watchCompleteDDXWithdraw(): Generator {
    yield takeLatest(COMPLETE_DDX_WITHDRAW, completeWithdrawDDXAction);
}

export function* handleContractCheckpoints(channel: EventChannel<number>): Generator {
    try {
        while (true) {
            const value = yield* take(channel);
            getFrontendLogger().log(`Checkpoint value ${value}`);
            yield* putResolve(SET_LATEST_CONTRACT_CHECKPOINT(value));
        }
    } catch (error) {
        getFrontendLogger().logError(error);
        yield* putResolve(
            SET_DOWNTIME({
                message: UIDowntimeState.PROBLEM_OCCURRED,
                code: UIDowntimeCode.HANDLE_CONTRACT_CHECKPOINTS,
            }),
        );
    } finally {
        channel.close();
    }
}

export function getContractCheckpointsEventChannel(
    context: Context,
    emitObj: {
        emit: (input: number | END) => void;
    },
) {
    return eventChannel<number>((emitter) => {
        try {
            // warning -- this breaks function immutability
            emitObj.emit = emitter;
            const unwatch: () => void = subscribeContractCheckpoints(
                getAddress(context.contractAddresses.derivaDEXAddress),
                CheckpointContractAbi,
                (value: number) => emitter(value),
            );
            return () => {
                unwatch && unwatch();
            };
        } catch (error) {
            return () => {
                getFrontendLogger().logError(error);
            };
        }
    });
}

export function* subscribeContractCheckpointsOnceWeb3Connected() {
    const emitObj = {
        emit: (input: number | END) => input,
    };
    try {
        const context = yield* call(waitFor<Context>, getWeb3Context);
        yield* call(waitUntilTrue, isEthAddressConnected);
        const latestCheckpoint = yield* call(
            getLatestCheckpointFromContract,
            getAddress(context.contractAddresses.derivaDEXAddress),
            CheckpointContractAbi,
        );
        yield* putResolve(SET_LATEST_CONTRACT_CHECKPOINT(latestCheckpoint));
        const channel = yield* call(getContractCheckpointsEventChannel, context, emitObj);
        yield* fork(handleContractCheckpoints, channel);
        yield* take(DISCONNECT_WEB3);
        emitObj.emit(END);
    } catch (error) {
        getFrontendLogger().logError(error);
        yield* putResolve(
            SET_DOWNTIME({
                message: UIDowntimeState.PROBLEM_OCCURRED,
                code: UIDowntimeCode.SUBSCRIBE_CONTRACT_CHECKPOINTS,
            }),
        );
    }
}

export function* collateralsBalanceUpdateOnActiveDepositDialog() {
    try {
        const ethAddress = yield* call(waitFor<string>, getEthAddress);
        const context = yield* call(waitFor<Context>, getWeb3Context);
        const usdcTokenBalance = yield* call(waitFor<TokenBalance>, getUSDCCollateral);
        const isWeb3Set = yield* select(isWeb3Connected);
        if (isWeb3Set === true) {
            const usdcBalance: BigNumber = yield* call(
                updateTokenBalance,
                getAddress(ethAddress),
                getAddress(context.contractAddresses.usdcAddress),
                ERC20ContractAbi,
                usdcTokenBalance.token,
            );
            const updatedUsdcTokenBalance = Object.assign({}, usdcTokenBalance, {
                balance: usdcBalance,
            });
            yield* putResolve(SET_USDC_COLLATERAL(updatedUsdcTokenBalance));
            const ddxTokenBalance = yield* call(waitFor<TokenBalance>, getDDXCollateral);
            const ddxBalance: BigNumber = yield* call(
                updateTokenBalance,
                getAddress(ethAddress),
                getAddress(context.contractAddresses.ddxAddress),
                ERC20ContractAbi,
                ddxTokenBalance.token,
            );
            const updatedDdxTokenBalance = Object.assign({}, ddxTokenBalance, {
                balance: ddxBalance,
            });
            yield* putResolve(SET_DDX_COLLATERAL(updatedDdxTokenBalance));
        }
    } catch (error) {
        getFrontendLogger().logError(error);
        yield* putResolve(
            SET_DOWNTIME({
                message: UIDowntimeState.PROBLEM_OCCURRED,
                code: UIDowntimeCode.WEB3_COLLATERALS_FAILED,
                subcode: UIDowntimeSubcode.COLLATERALS_BALANCE_UPDATE_DIALOG,
            }),
        );
    }
}

export function* watchTokenBalanceJob() {
    yield* takeLatest(ActionType.TOKEN_BALANCE_JOB, collateralsBalanceUpdateOnActiveDepositDialog);
}

function* watchBlockNumber() {
    try {
        let isWeb3 = true;
        while (isWeb3) {
            const isDepositUSDCState: DepositUsdcUIState = yield* select(getDepositUsdcUIState);
            const isDepositDDXState: DepositDdxUIState = yield* select(getDepositDdxUIState);
            const context = yield* call(waitFor<Context>, getWeb3Context);
            if (areDepositsInProgress(isDepositUSDCState, isDepositDDXState)) {
                const previousBlockNumber = yield* select(getCurrentBlockNumber);
                const chainId = context.chainId;
                try {
                    const blockNumber = yield* call(fetchBlockNumber);
                    if (previousBlockNumber === blockNumber) {
                        getFrontendLogger().logError(
                            `System malfunction: Blocks are not advancing. Block ${blockNumber}`,
                        );
                    }
                    yield* putResolve(SET_BLOCK_NUMBER(blockNumber));
                } catch (error) {
                    getFrontendLogger().logError(error);
                }
            }
            yield* delay(DEPOSIT_DIALOG_BALANCE_UPDATE_INTERVAL);
            isWeb3 = yield* select(isWeb3Connected);
        }
    } catch (error) {
        getFrontendLogger().logError(error);
    }
}

export function* watchConnectWeb3() {
    yield* takeLatest(CONNECT_WEB3, connectWeb3);
}

export function* web3Saga() {
    yield* all([
        fork(fetchInitialWeb3Data),
        fork(watchConnectWeb3),
        fork(watchForApproveTokenAction),
        fork(watchForDeposit),
        fork(watchSubmitCheckpointRequest),
        fork(watchTokenBalanceJob),
        fork(watchLatestContractCheckpoint),
        fork(watchMerkleProofRequest),
        fork(watchMerkleProofSuccess),
        fork(watchMerkleProofFailure),
        fork(watchUpdateWithdrawState),
        fork(watchUpdateWithdrawDDXState),
        fork(watchCompleteWithdraw),
        fork(watchCompleteDDXWithdraw),
    ]);
}
