import {
    ActionType,
    BookFeedEventParams,
    EstimateFundingRate,
    Fill,
    Market,
    MarkPrice,
    OpenInterest,
    OrderBookSnapshot,
    PriceCheckpoint,
    RecentFillsFeedEventParams,
    Ticker,
} from '@derivadex/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { createAction } from 'typesafe-actions';

// TODO - replace this with the type from @derivadex/types once all fields are added
export type MarketState = {
    markets: { [symbol: string]: Market };
    selectedMarket: string | undefined;
    tickers: { [symbol: string]: Ticker };
    openInterestData: { [symbol: string]: OpenInterest };
    markPrices: { [symbol: string]: MarkPrice };
    fundingRates: { [symbol: string]: EstimateFundingRate };
    book: OrderBookSnapshot | undefined;
    isSubscribedToRecentFills: boolean;
    recentFills: Fill[];
    previousClose: { [symbol: string]: BigNumber };
};

export const initialMarketState: MarketState = {
    markets: {},
    selectedMarket: undefined,
    tickers: {},
    openInterestData: {},
    markPrices: {},
    fundingRates: {},
    book: undefined,
    isSubscribedToRecentFills: false,
    recentFills: [],
    previousClose: {},
};

export const marketSlice = createSlice({
    name: 'market',
    initialState: initialMarketState,
    reducers: {
        SET_MARKETS: (state, action: PayloadAction<Market[]>) => {
            state.markets = action.payload.reduce(
                (prev, curr) => ({ ...prev, [curr.symbol]: curr }),
                {} as { [symbol: string]: Market },
            );
        },
        SET_SELECTED_MARKET: (state, action: PayloadAction<{ symbol: string }>) => {
            state.selectedMarket = action.payload.symbol;
        },
        MARK_PRICE_UPDATE: (state, action: PayloadAction<MarkPrice>) => {
            state.markPrices[action.payload.symbol] = action.payload;
        },
        TICKER_UPDATE: (state, action: PayloadAction<Ticker>) => {
            if (state.tickers[action.payload.symbol]) {
                state.previousClose[action.payload.symbol] = state.tickers[action.payload.symbol].close;
            }
            state.tickers[action.payload.symbol] = action.payload;
        },
        OPEN_INTEREST_UPDATE: (state, action: PayloadAction<OpenInterest>) => {
            state.openInterestData[action.payload.symbol] = action.payload;
        },
        FUNDING_RATE_UPDATE: (state, action: PayloadAction<EstimateFundingRate>) => {
            state.fundingRates[action.payload.symbol] = action.payload;
        },
        BOOK_UPDATE: (state, action: PayloadAction<OrderBookSnapshot>) => {
            state.book = action.payload;
        },
        RECENT_FILLS_SUBSCRIBE: (state, action: PayloadAction<boolean>) => {
            if (state.isSubscribedToRecentFills !== action.payload) {
                state.recentFills = [];
                state.isSubscribedToRecentFills = action.payload;
            }
        },
        RECENT_FILLS_UPDATE: (state, action: PayloadAction<Fill[]>) => {
            state.recentFills = [...action.payload, ...state.recentFills].slice(0, 100);
        },
    },
});

export const {
    SET_MARKETS,
    SET_SELECTED_MARKET,
    MARK_PRICE_UPDATE,
    TICKER_UPDATE,
    OPEN_INTEREST_UPDATE,
    FUNDING_RATE_UPDATE,
    BOOK_UPDATE,
    RECENT_FILLS_SUBSCRIBE,
    RECENT_FILLS_UPDATE,
} = marketSlice.actions;

export const CHANGE_BOOK_SUBSCRIPTION = createAction(ActionType.CHANGE_BOOK_SUBSCRIPTION)<BookFeedEventParams>();
export const CHANGE_RECENT_FILLS_SUBSCRIPTION = createAction(
    ActionType.CHANGE_RECENT_FILLS_SUBSCRIPTION,
)<RecentFillsFeedEventParams>();
