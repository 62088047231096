import {
    ActionType,
    AdlResponseItem,
    FeeResponseItem,
    OrderIntentStatsResponseItem,
    PositionsResponseItem,
    StatsAPIListResponse,
    StatsAPISuccessTimeseriesResponse,
    StrategyUpdateResponseItem,
    TraderUpdateResponseItem,
} from '@derivadex/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createAction } from 'typesafe-actions';

import {
    BalanceResponseItem,
    FetchDataState,
    PortfolioPeriodFilter,
    PortfolioTableType,
    RealizedPnlResponseItem,
    StatsResponseItem,
} from './utils';

export type PortfolioState = {
    lookbackFilter: PortfolioPeriodFilter;
    depositsAndWithdrawals: {
        cache: StatsAPISuccessTimeseriesResponse<StrategyUpdateResponseItem>[];
        currentPage: number;
    };
    fundingHistory: {
        cache: StatsAPISuccessTimeseriesResponse<StrategyUpdateResponseItem>[];
        currentPage: number;
    };
    ddxRewards: {
        cache: StatsAPISuccessTimeseriesResponse<TraderUpdateResponseItem>[];
        currentPage: number;
    };
    positionsHistory: {
        data: StatsAPIListResponse<PositionsResponseItem> | undefined;
        dirtyFlag: boolean;
    };
    ordersHistory: {
        cache: StatsAPISuccessTimeseriesResponse<OrderIntentStatsResponseItem>[];
        currentPage: number;
        filters: { filterByStatus: string[]; symbol: string };
    };
    adlHistory: {
        cache: StatsAPISuccessTimeseriesResponse<AdlResponseItem>[];
        currentPage: number;
        filters: { symbol: string };
    };
    stats: {
        realizedPnl: StatsResponseItem | undefined;
        balance: StatsResponseItem | undefined;
        funding: StatsResponseItem | undefined;
        tradeMining: StatsResponseItem | undefined;
        makerVolume: StatsResponseItem | undefined;
        takerVolume: StatsResponseItem | undefined;
        dirtyFlag: boolean;
    };
    balanceHistory: { data: BalanceResponseItem[] | undefined; currentPage: number; dirtyFlag: boolean };
    realizedPnlHistory: { data: RealizedPnlResponseItem[] | undefined; currentPage: number; dirtyFlag: boolean };
    feesHistory: {
        cache: StatsAPISuccessTimeseriesResponse<FeeResponseItem>[];
        currentPage: number;
        filters: { symbol: string };
    };
};

export const initialPortfolioState: PortfolioState = {
    lookbackFilter: '1h',
    depositsAndWithdrawals: { cache: [], currentPage: 0 },
    fundingHistory: { cache: [], currentPage: 0 },
    ddxRewards: { cache: [], currentPage: 0 },
    feesHistory: { cache: [], currentPage: 0, filters: { symbol: 'all' } },
    positionsHistory: { data: undefined, dirtyFlag: true },
    ordersHistory: { cache: [], currentPage: 0, filters: { filterByStatus: ['1', '2'], symbol: 'all' } },
    adlHistory: { cache: [], currentPage: 0, filters: { symbol: 'all' } },
    stats: {
        realizedPnl: undefined,
        balance: undefined,
        funding: undefined,
        tradeMining: undefined,
        makerVolume: undefined,
        takerVolume: undefined,
        dirtyFlag: true,
    },
    balanceHistory: { data: undefined, currentPage: 1, dirtyFlag: true },
    realizedPnlHistory: { data: undefined, currentPage: 1, dirtyFlag: true },
};

export const portfolioSlice = createSlice({
    name: 'portfolio',
    initialState: initialPortfolioState,
    reducers: {
        SET_DEPOSITS_WITHDRAWALS_HISTORY_DATA: (
            state,
            action: PayloadAction<StatsAPISuccessTimeseriesResponse<StrategyUpdateResponseItem>[]>,
        ) => {
            state.depositsAndWithdrawals.cache = action.payload;
        },
        SET_DEPOSITS_WITHDRAWALS_HISTORY_CURRENT_PAGE: (state, action: PayloadAction<number>) => {
            state.depositsAndWithdrawals.currentPage = action.payload;
        },
        SET_FUNDING_HISTORY_DATA: (
            state,
            action: PayloadAction<StatsAPISuccessTimeseriesResponse<StrategyUpdateResponseItem>[]>,
        ) => {
            state.fundingHistory.cache = action.payload;
        },
        SET_FUNDING_HISTORY_CURRENT_PAGE: (state, action: PayloadAction<number>) => {
            state.fundingHistory.currentPage = action.payload;
        },
        SET_FEES_HISTORY_DATA: (state, action: PayloadAction<StatsAPISuccessTimeseriesResponse<FeeResponseItem>[]>) => {
            state.feesHistory.cache = action.payload;
        },
        SET_FEES_HISTORY_FILTERS: (state, action: PayloadAction<{ symbol: string }>) => {
            state.feesHistory.filters = action.payload;
            state.feesHistory.currentPage = 0;
            state.feesHistory.cache = [];
        },
        SET_FEES_HISTORY_CURRENT_PAGE: (state, action: PayloadAction<number>) => {
            state.feesHistory.currentPage = action.payload;
        },
        SET_POSITIONS_HISTORY_DATA: (state, action: PayloadAction<StatsAPIListResponse<PositionsResponseItem>>) => {
            state.positionsHistory.data = action.payload;
            state.positionsHistory.dirtyFlag = false;
        },
        SET_ORDERS_HISTORY_DATA: (
            state,
            action: PayloadAction<StatsAPISuccessTimeseriesResponse<OrderIntentStatsResponseItem>[]>,
        ) => {
            state.ordersHistory.cache = action.payload;
        },
        SET_ORDERS_HISTORY_FILTERS: (state, action: PayloadAction<{ filterByStatus: string[]; symbol: string }>) => {
            state.ordersHistory.filters = action.payload;
            state.ordersHistory.currentPage = 0;
            state.ordersHistory.cache = [];
        },
        SET_ORDERS_HISTORY_CURRENT_PAGE: (state, action: PayloadAction<number>) => {
            state.ordersHistory.currentPage = action.payload;
        },
        SET_DDX_REWARDS_HISTORY_DATA: (
            state,
            action: PayloadAction<StatsAPISuccessTimeseriesResponse<TraderUpdateResponseItem>[]>,
        ) => {
            state.ddxRewards.cache = action.payload;
        },
        SET_DDX_REWARDS_HISTORY_CURRENT_PAGE: (state, action: PayloadAction<number>) => {
            state.ddxRewards.currentPage = action.payload;
        },
        SET_ADL_HISTORY_DATA: (state, action: PayloadAction<StatsAPISuccessTimeseriesResponse<AdlResponseItem>[]>) => {
            state.adlHistory.cache = action.payload;
        },
        SET_ADL_HISTORY_FILTERS: (state, action: PayloadAction<{ symbol: string }>) => {
            state.adlHistory.filters = action.payload;
            state.adlHistory.currentPage = 0;
            state.adlHistory.cache = [];
        },
        SET_ADL_HISTORY_CURRENT_PAGE: (state, action: PayloadAction<number>) => {
            state.adlHistory.currentPage = action.payload;
        },
        SET_STATS_REALIZED_DATA: (state, action: PayloadAction<StatsResponseItem>) => {
            state.stats.realizedPnl = action.payload;
        },
        SET_STATS_BALANCE_DATA: (state, action: PayloadAction<StatsResponseItem>) => {
            state.stats.balance = action.payload;
        },
        SET_STATS_FUNDING_DATA: (state, action: PayloadAction<StatsResponseItem>) => {
            state.stats.funding = action.payload;
        },
        SET_STATS_TRADE_MINING_DATA: (state, action: PayloadAction<StatsResponseItem>) => {
            state.stats.tradeMining = action.payload;
        },
        SET_STATS_VOLUME_DATA: (
            state,
            action: PayloadAction<{ maker: StatsResponseItem; taker: StatsResponseItem }>,
        ) => {
            state.stats.makerVolume = action.payload.maker;
            state.stats.takerVolume = action.payload.taker;
        },
        SET_STATS_TAKER_VOLUME_DATA: (state, action: PayloadAction<StatsResponseItem>) => {
            state.stats.takerVolume = action.payload;
        },
        SET_BALANCE_DATA: (state, action: PayloadAction<BalanceResponseItem[]>) => {
            state.balanceHistory.data = action.payload;
            state.balanceHistory.dirtyFlag = false;
        },
        SET_REALIZED_PNL_DATA: (state, action: PayloadAction<RealizedPnlResponseItem[]>) => {
            state.realizedPnlHistory.data = action.payload;
            state.realizedPnlHistory.dirtyFlag = false;
        },
        SET_LOOKBACK_FILTER: (state, action: PayloadAction<PortfolioPeriodFilter>) => {
            state.lookbackFilter = action.payload;
            state.stats.dirtyFlag = true;
        },
        SET_STATS_DIRTY_FLAG: (state, action: PayloadAction<boolean>) => {
            state.stats.dirtyFlag = action.payload;
        },
        SET_STATS_DATA: (
            state,
            action: PayloadAction<{
                realizedPnl: StatsResponseItem | undefined;
                balance: StatsResponseItem | undefined;
                funding: StatsResponseItem | undefined;
                tradeMining: StatsResponseItem | undefined;
                makerVolume: StatsResponseItem | undefined;
                takerVolume: StatsResponseItem | undefined;
            }>,
        ) => {
            state.stats.realizedPnl = action.payload.realizedPnl;
            state.stats.balance = action.payload.balance;
            state.stats.funding = action.payload.funding;
            state.stats.tradeMining = action.payload.tradeMining;
            state.stats.makerVolume = action.payload.makerVolume;
            state.stats.takerVolume = action.payload.takerVolume;
        },
    },
});

export const {
    SET_DEPOSITS_WITHDRAWALS_HISTORY_DATA,
    SET_DEPOSITS_WITHDRAWALS_HISTORY_CURRENT_PAGE,
    SET_FUNDING_HISTORY_DATA,
    SET_FUNDING_HISTORY_CURRENT_PAGE,
    SET_POSITIONS_HISTORY_DATA,
    SET_ORDERS_HISTORY_DATA,
    SET_ORDERS_HISTORY_CURRENT_PAGE,
    SET_ORDERS_HISTORY_FILTERS,
    SET_FEES_HISTORY_DATA,
    SET_FEES_HISTORY_CURRENT_PAGE,
    SET_FEES_HISTORY_FILTERS,
    SET_DDX_REWARDS_HISTORY_DATA,
    SET_DDX_REWARDS_HISTORY_CURRENT_PAGE,
    SET_ADL_HISTORY_DATA,
    SET_ADL_HISTORY_CURRENT_PAGE,
    SET_ADL_HISTORY_FILTERS,
    SET_STATS_REALIZED_DATA,
    SET_STATS_BALANCE_DATA,
    SET_STATS_FUNDING_DATA,
    SET_STATS_TRADE_MINING_DATA,
    SET_STATS_VOLUME_DATA,
    SET_BALANCE_DATA,
    SET_LOOKBACK_FILTER,
    SET_STATS_DATA,
    SET_STATS_DIRTY_FLAG,
    SET_REALIZED_PNL_DATA,
} = portfolioSlice.actions;

export const FETCH_DEPOSITS_AND_WITHDRAWALS_HISTORY_DATA = createAction(
    ActionType.FETCH_DEPOSITS_AND_WITHDRAWALS_DATA,
)<FetchDataState>();
export const FETCH_DDX_REWARDS_HISTORY_DATA = createAction(ActionType.FETCH_DDX_REWARDS_DATA)<FetchDataState>();
export const FETCH_POSITIONS_HISTORY_DATA = createAction(ActionType.FETCH_POSITIONS_HISTORY_DATA)();
export const FETCH_ORDERS_HISTORY_DATA = createAction(ActionType.FETCH_ORDERS_HISTORY_DATA)<FetchDataState>();
export const FETCH_FUNDING_HISTORY_DATA = createAction(ActionType.FETCH_FUNDING_HISTORY_DATA)<FetchDataState>();
export const FETCH_ADL_HISTORY_DATA = createAction(ActionType.FETCH_ADL_HISTORY_DATA)<FetchDataState>();
export const FETCH_BALANCE_DATA = createAction(ActionType.FETCH_BALANCE_DATA)();
export const FETCH_REALIZED_PNL_DATA = createAction(ActionType.FETCH_REALIZED_PNL_DATA)();
export const FETCH_FEES_HISTORY_DATA = createAction(ActionType.FETCH_FEE_HISTORY_DATA)<FetchDataState>();
export const FETCH_STATS_DATA = createAction(ActionType.FETCH_STATS_DATA)();
export const UPDATE_LOOKBACK_PERIOD_FILTER = createAction(
    ActionType.UPDATE_LOOKBACK_PERIOD_FILTER,
)<PortfolioPeriodFilter>();
