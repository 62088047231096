import { BigNumber, hexUtils } from '@0x/utils';
import { CheckpointData, CheckpointSubmission } from '@derivadex/types';

export function sortSignerAddresses(signerAddresses: string[]): string[] {
    return signerAddresses.sort((a, b) => {
        const numA = new BigNumber(a);
        const numB = new BigNumber(b);
        return numA.comparedTo(numB);
    });
}

export function prepareSubmission(
    checkpoint: CheckpointData,
    signers: string[],
    signerSignatureMap: { [key: string]: string },
    signerEpochs: number[],
): CheckpointSubmission {
    const signatures = [];
    for (const signer of sortSignerAddresses(signers)) {
        signatures.push(getSignatureForCheckpointSubmission(signerSignatureMap[signer]));
    }
    return {
        checkpointData: checkpoint,
        signatures,
        signerEpochs,
    };
}

function getSignatureForCheckpointSubmission(signature: string): string {
    return hexUtils.concat(hexUtils.slice(signature, 64), hexUtils.slice(signature, 0, 64));
}

export function buildCheckpointSubmission(
    checkpoints: Array<{
        epoch: { epochId: number };
        signed_checkpoints: {
            Ethereum: {
                blockHash: string;
                blockNumber: string;
                stateRoot: string;
                transactionRoot: string;
                signer: string;
                signature: string;
            };
        };
    }>,
): CheckpointSubmission | undefined {
    const checkpointData: CheckpointData = {
        blockNumber: new BigNumber(checkpoints[0].signed_checkpoints.Ethereum.blockNumber).toString(),
        blockHash: checkpoints[0].signed_checkpoints.Ethereum.blockHash,
        stateRoot: checkpoints[0].signed_checkpoints.Ethereum.stateRoot,
        transactionRoot: checkpoints[0].signed_checkpoints.Ethereum.transactionRoot,
    };
    const signerSignatureMap: { [key: string]: string } = {};
    const signerEpochs: number[] = [];
    checkpoints.forEach(
        (checkpointData: {
            epoch: { epochId: number };
            signed_checkpoints: { Ethereum: { signer: string | number; signature: string } };
        }) => {
            signerEpochs.push(checkpointData.epoch.epochId);
            signerSignatureMap[checkpointData.signed_checkpoints.Ethereum.signer] =
                checkpointData.signed_checkpoints.Ethereum.signature;
        },
    );
    return prepareSubmission(checkpointData, Object.keys(signerSignatureMap), signerSignatureMap, signerEpochs);
}
