import { getFrontendLogger } from '@derivadex/utils';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import { initialRuntimeConfig } from 'config/runtimeConfig';
import useEagerConnect from 'hooks/useEagerConnect';
import useLocalStorage from 'hooks/useLocalStorage';
import AccountPortfolio from 'pages/AccountPortfolio';
import { lazy, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { getPortfolioPageEnabled, getRuntimeConfig, isServerLoadComplete } from 'store/config/selectors';
import {
    getCurrentView,
    getDowntime,
    getDowntimeCode,
    getDowntimeMessage,
    getDowntimeSubcode,
} from 'store/ui/selectors';

const Downtime = lazy(() => import('pages/Downtime'));
const Marketplace = lazy(() => import('pages/Marketplace'));
const Splash = lazy(() => import('pages/Splash'));

export default function App() {
    const runtimeConfig = useSelector(getRuntimeConfig);
    const navigate = useNavigate();
    const hasAcceptedConsentDialog = window.localStorage.getItem('hasAcceptedConsentDialog');
    const downtime = useSelector(getDowntime);
    const downtimeMessage = useSelector(getDowntimeMessage);
    const downtimeCode = useSelector(getDowntimeCode);
    const downtimeSubcode = useSelector(getDowntimeSubcode);
    const isLoaded = useSelector(isServerLoadComplete);
    const [cachedMarket] = useLocalStorage<string>('selectedMarket', 'ETHP');
    getFrontendLogger().log('default market from cache', cachedMarket);
    const defaultUrl = '/trade/' + (cachedMarket || 'ETHP');
    const view = useSelector(getCurrentView);
    const portfolioPageEnabled = useSelector(getPortfolioPageEnabled);

    useEffect(() => {
        if (downtime) {
            navigate('/downtime');
        } else if (!isLoaded && initialRuntimeConfig.CONFIG_WAIT_BYPASS !== '1') {
            return;
        } else if (
            runtimeConfig.SPLASH === 'MAINTENANCE' ||
            (runtimeConfig.SPLASH === 'GUARDED_LAUNCH_OPEN' && hasAcceptedConsentDialog !== 'true') ||
            (runtimeConfig.SPLASH === 'GUARDED_LAUNCH_CLOSED' && hasAcceptedConsentDialog !== 'true')
        ) {
            navigate('/welcome');
        }
    }, [navigate, downtime, hasAcceptedConsentDialog, isLoaded, runtimeConfig.SPLASH, view]);
    useEagerConnect();
    return (
        <ErrorBoundary>
            <Routes>
                <Route
                    path="/downtime"
                    element={<Downtime message={downtimeMessage} code={downtimeCode} subcode={downtimeSubcode} />}
                />
                <Route path="/welcome" element={<Splash />} />
                {portfolioPageEnabled ? (
                    <Route path="/portfolio" element={<AccountPortfolio />} />
                ) : (
                    <Route path="/portfolio" element={<Navigate to={defaultUrl} />} />
                )}
                <Route path="/trade/:symbol" element={<Marketplace />} />
                <Route path="/" element={<Navigate to={defaultUrl} />} />
            </Routes>
        </ErrorBoundary>
    );
}
